import { AnimatePresence, motion } from "framer-motion";
import { ReactElement } from "react";
// import { useOutlet } from "react-router-dom";


interface PageWrapperProps {
  children: ReactElement | null;
  key: string;
}


// const slideVariant = {
//   hiddenRight: {x: "110%",},
//   hiddenLeft: {x: "-110%",},
//   visible: {
//     x: "0",
//     opacity: 1,
//     transition: {
//       delay: 0.1,
//       type: "tween"
//     }
//   },
// };

export function PageWrapper({children} : PageWrapperProps){
  return (
    <AnimatePresence>
      <motion.div
        className="h-full"
        // variants={slideVariant}
        // initial={"hiddenRight"}
        // exit={"hiddenLeft"}
        // animate="visible"

        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 50 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}