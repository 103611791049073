import { 
  AnalystPaymentAction, 
  AnalystPaymentConfirm, 
  AnalystPaymentInit, 
  AnalystResult, 
  selectCurrentStage, 
  selectTask
} from "@entities/task";
import { CardWithDate } from "@shared/components/card";
import { useAppSelector } from "@shared/model/hooks";
import { AnimatePresence } from "framer-motion";
import { ProcessWrapperStyle } from "@process/task-process/components";
import { useTranslation } from "react-i18next";
import { AnalystPreview } from "@features/task/analyst-preview-payment";


export function AnalystProcess(){
  const currentStage = useAppSelector(selectCurrentStage);
  const task = useAppSelector(selectTask)
  const { t } = useTranslation();

  return (
    <CardWithDate 
      date={new Date()} 
      title={t("process.task_process.analyst_process.title")}
      className="h-full"
    >
      <AnimatePresence>
        <ProcessWrapperStyle key={currentStage}>
          {currentStage === "ANALYST_PAYMENT_INIT" && 
            <AnalystPaymentInit 
              analystPreviewSlot={ <AnalystPreview task={task} />} 
              task={task} />
          }
          {currentStage === "ANALSYT_PAYMENT_ACTION" && <AnalystPaymentAction />}
          {currentStage === "ANALYST_PAYMENT_CONFIRM" && <AnalystPaymentConfirm />}
          {currentStage === "ANALYST_RESULT" && (
            <AnalystResult 
              task={task} 
              analystPreviewSlot={ 
              <AnalystPreview task={task} />
              }  
            />
          )}
        </ProcessWrapperStyle>
      </AnimatePresence>
    </CardWithDate>
  )
}