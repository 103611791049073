// import { Virus } from "@entities/virus"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { virusGetOrCreateByNameService } from "@services/www.fixransomware.com"

// const dummyVirus : Virus = {
//   id: "howessss",
//   urlBlog: "https://github.com/zidan-p",
//   virusName: "holobis",
//   virusDesc: "ini adladh virus yang berbahaya, intinya jauh-jauh dari sumber dari virus serta kunjungi dokter bila nampak gejalanya"
// }



// export async function dummyFetch(virusName: string){
//   return new Promise<Virus>((resolve,reject) => {
//     setTimeout(() => {
      
//       resolve(dummyVirus)
//     }, 5000)
//   })
// }

export const checkOrCreateIfEmptyByNameVirus = createAsyncThunk(
  "currentVirus/checkOrCreateVirusByName",
  async (virusName: string) => {
    const result = await virusGetOrCreateByNameService(virusName);
    return result.data;
  } 
) 