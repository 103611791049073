import { useAppSelector } from "@shared/model/hooks";
import { useEffect, useRef, useState } from "react";






interface ScanProcessProps {
  onScanComplete: (...args: any[]) => any;
}
export function ScanProcess(props: ScanProcessProps){
  const isTaskLoading = useAppSelector(state => state.currentTask.loading);
  const [progressValue, setProgressvalue] = useState(0);
  const interval = useRef<any>();

  useEffect(()=>{
    interval.current = setInterval(()=>{
      setProgressvalue(prev => prev + 1);
      console.log("interval")
    }, 150);

    return () => clearInterval(interval.current)
  },[])

  // see : https://stackoverflow.com/questions/69771237/react-setinterval-with-useeffect-not-stopping
  useEffect(()=>{
    if(progressValue >= 100){
      clearInterval(interval.current);
      handleOnScanComplete(); // when the percentage complete, go to next step
    }
  }, [progressValue])

  function handleOnScanComplete(){
    props.onScanComplete();
  }

  return (
    <div className="h-full">
      {isTaskLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          {!(progressValue >= 100) ? (
            <progress value={progressValue} max={100} className="w-full rounded-lg p-1 accent-warning" />
          ) : (
            <div className="flex flex-col h-full">
              <p className="grow">
                Indexing file
              </p>
            </div>
          )}
        </>
      )}
    </div>
  )
}