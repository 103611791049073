


export function GenerateToastList(data: string[]){
  return(
    <ul className=" flex flex-col break-words">
      {data.map(dat => (
        <li className="border-b border-b-red-200 py-1">
          {dat}
        </li>
      ))}
    </ul>
  )
}
