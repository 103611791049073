import clsx from "clsx";
import { ComponentPropsWithRef, ForwardedRef, forwardRef } from "react";

 


interface TextAreaInputProps extends ComponentPropsWithRef<"textarea"> {} 


export const  TextAreaInput = forwardRef(({className, placeholder, ...props} : TextAreaInputProps, ref: ForwardedRef<HTMLTextAreaElement>) => {

  return (
    <>
    {/* <div className="relative mt-6">
      <input type="password" className="peer w-full border-b placeholder:text-transparent" placeholder="name" />
    </div> */}
    <div className={clsx(
      "relative",
      className
    )}>
      <textarea
        ref={ref}
        id={props.name}
        name={props.name}
        className={clsx(
          "text-black p-2 pt-4 rounded-sm outline-none border-b-4 border-b-warning", 
          "focus:border-b-primary-light peer placeholder:text-transparent w-full"
          )} 
        placeholder={" "}
        {...props} 
      > 
      
      </textarea>
      <label htmlFor={props.name} className="absolute text-gray-500 left-0 ml-1 px-1 peer-placeholder-shown:text-gray-700 peer-placeholder-shown:translate-y-[40%] text-sm duration-100 ease-linear peer-placeholder-shown:text-base peer-focus:ml-1 peer-focus:text-gray-500 peer-focus:-translate-y-0 peer-focus:px-1 peer-focus:text-sm">{placeholder}</label>
    </div>
    </>
  )
})