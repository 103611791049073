import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { ToastContainer } from "react-toastify";
import {Provider as ReduxProvider} from "react-redux";
import store from "./store";

import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import "./i18n" ;

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://11a44bb6d864cc47b724087b5f64f724@o4506789737267200.ingest.sentry.io/4506789743165440",
  enabled: String(process.env.NODE_ENV).toLowerCase() === 'production',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [window.location.origin, "http://localhost:300/", "https://admin.fixransomware.com"]
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.fixransomware\.com/, /^https:\/\/admin\.fixransomware\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <RouterProvider router={router} />
      <ToastContainer pauseOnFocusLoss={false} />
    </ReduxProvider>
  </React.StrictMode>
);
