import { requestor } from ".";







/**
 * change the current user language. (the user value is provided by token in requestor)
 * @param language 
 * @returns 
 */
export async function changeLanguage(language: 'en' | 'id'){
  const result = await requestor.put("language", {language});
  return result.data;
}