






export function isKeysPresent<T extends object>(requiredKeys: readonly string[], obj: T){
  if(!requiredKeys) return false;
  if(!obj) return false

  // ⚠️ DANGER ⚠️ 
  // dont use array .every to handle this thing, get another way to deal with this case
  return requiredKeys.every(key => key in obj);
}

export function isObjectEmpty(obj: object | null | undefined){
  if(!obj) return true;
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true
}