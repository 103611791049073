import { RouteObject, createBrowserRouter, createHashRouter } from "react-router-dom";
import { AuthLayoutRoute } from "./layout/AuthLayoutRoute";
import { MainLayoutRoute } from "./layout/MainLayoutRoute";

import { Dummy } from "@pages/dummy";
import { Login } from "@pages/login";
import { Register } from "@pages/register";
import { ForgetPassword } from "@pages/forget-password";
import { Home } from "@pages/home";
import { Scan } from "@pages/scan";
import { Donation } from "@pages/donation";
import { Progress } from "@pages/progress";
import { TaskPage } from "@pages/task";
import { SuccessCreateAccountPage } from "@pages/success-create-account";
import { isInElectron } from "@services/electron";
import { AnonymousDonation } from "@pages/anonymous-donation";


const route: RouteObject[] = [

  {
    path: "/",
    element: <Dummy />
  },
  {
    path: "success-create-account",
    element: <SuccessCreateAccountPage />
  },

  // # AUTH GROUP
  {
    element: <AuthLayoutRoute />,
    children : [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/change-password",
        element: <ForgetPassword />,
      },
      {
        path: "/donate-now",
        element: <AnonymousDonation />
      }
    ]
  },

  // # MAIN GROUP
  {
    element: <MainLayoutRoute />,
    children : [
      {
        path: "/home",
        element: <Home />
      },
      {
        path: "/scan",
        element: <Scan />
      }, 
      {
        path: "/donation",
        element: <Donation />
      },
      {
        path: "/progress",
        element: <Progress />
      },
      {
        path: "/progress/:taskId",
        element: <TaskPage />
      }
    ]
  }
]

let router: ReturnType<typeof createBrowserRouter>;
if(isInElectron()) router = createHashRouter(route);
else router = createBrowserRouter(route);

export default router;
