import { useTimer } from "./useTimer"




interface TimerProps {
  deadline: Date | string;

}

export function Timer(props: TimerProps){
  const {days, hours, minutes, seconds, isRunning} = useTimer(props.deadline instanceof Date ? props.deadline.toISOString() : props.deadline);
  return (
    <div className="rounded bg-secondary-base p-2 px-20 gap-5 flex justify-center border border-secondary-light">
    <div className="flex flex-col justify-items-center">
      <span className="text-3xl font-semibold text-center">{days}</span>
      <span>Days</span>
    </div>
    <div className="flex flex-col justify-items-center">
      <span className="text-3xl font-semibold text-center">{hours}</span>
      <span>Hours</span>
    </div>
    <div className="flex flex-col justify-items-center">
      <span className="text-3xl font-semibold text-center">{minutes}</span>
      <span>Minutes</span>
    </div>
    <div className="flex flex-col justify-items-center">
      <span className="text-3xl font-semibold text-center">{seconds}</span>
      <span>Seconds</span>
    </div>
  </div>
  )
}