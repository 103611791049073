import { 
  DecryptEstimation, 
  DecryptPaymentAction, 
  DecryptPaymentConfirm, 
  DecryptPaymentinit, 
  DecryptResult, 
  selectCurrentStage, 
  selectTask 
} from "@entities/task";
import { CardWithDate } from "@shared/components/card";
import { useAppSelector } from "@shared/model/hooks";
import { AnimatePresence } from "framer-motion";
import { ProcessWrapperStyle } from "@process/task-process/components";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { useTranslation } from "react-i18next";

export function DecryptProcess(){
  const currentStage = useAppSelector(selectCurrentStage);
  const task = useAppSelector(selectTask);
  const { t } = useTranslation();

  return (
    <CardWithDate 
      date={new Date()} 
      title={t("process.task_process.decrypt_process.title")}
      className="h-full"
    >
      <AnimatePresence>
        <ProcessWrapperStyle key={currentStage}>
        {currentStage === "DECRYPT_PAYMENT_INIT" &&  <DecryptPaymentinit />}
        {currentStage === "DECRYPT_PAYMENT_ACTION" && <DecryptPaymentAction />}
        {currentStage === "DECRYPT_PAYMENT_CONFIRM" && <DecryptPaymentConfirm />}
        {currentStage === "DECRYPT_ESTIMATION" && <DecryptEstimation task={task} />}
        {currentStage === "DECRYPT_RESULT" && 
          <DecryptResult task={task} bottomButtonSlot={
              <SolidButton disabled variant="base">
                {t("process.task_process.decrypt_process.pay_off")}
            </SolidButton>
          } />
        }
        </ProcessWrapperStyle>
      </AnimatePresence>
    </CardWithDate>
  )
}