import { Virus } from "@entities/virus"
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface VirusSectionProps {
  virusList : Omit<Virus, "virusDesc" | "urlBlog">[];
  setSelectedVirus: (id: string)=>any;
  selectedVirus : string | null | undefined;
  className?: string;
}


export function VirusSection(props: VirusSectionProps){
  const { t } = useTranslation();

  return (
    <div className={clsx("flex flex-wrap w-full gap-2", props.className)}>
      {props.virusList.map(virus => (
        <button 
          key={virus.id}
          className={clsx(
            "bg-secondary-dark/20 px-4 py-2 shadow-sm rounded-full border",
            "hover:bg-secondary-dark/30 active:bg-secondary-dark/50",
            props.selectedVirus === virus.id ? "border-primary text-white" : "border-neutral-800 text-gray-400"
          )}
          onClick={() => props.setSelectedVirus(virus.id)}
        >
          {virus.virusName}
        </button>
      ))}
      {props.virusList.length === 0 && (
        <>
        <p>{t("features.virus.select_virus.virus_section.virus_not_exist")}</p> 
        <p>{t("features.virus.select_virus.virus_section.submit_notice")}</p>
        </>
      )}
    </div>
  )
}