import { Virus } from "@entities/virus";
import { requestor } from "./requestor";
import { FixransomwareResponse } from "@shared/types/http";




export async function virusGetAllService(){
  return (await requestor.get<FixransomwareResponse<Virus[]>>("/virus")).data;
}

export async function virusGetAllRecentService(){
  return (await requestor.get<FixransomwareResponse<Virus[]>>("/virus", {params : {
    orderBy: "updatedAt",
    order: "desc",
    dataPerPage: 5
  }})).data;
}

export async function virusGetOrCreateByNameService(name: string){
  return (await requestor.post<FixransomwareResponse<Virus>>("/virus/get-or-create-by-name/", {name})).data;
}