import clsx from "clsx";
import { ChangeEvent, ComponentPropsWithoutRef, useState } from "react";

 


interface FileInputDarkProps extends Omit<ComponentPropsWithoutRef<"input">, "onChange"> {
  inputClassName?: string
  maxCount: number;
  /** max size for each file in bytes */
  maxSize: number; // in bytes
  onChange?: (result: FileList | null) => any;
  // type: "file"
} 

interface FileInputValidationContainer{
  fileName: string;
  error: string;
}

export function FileInputDark({className, onChange,  ...props} : FileInputDarkProps){

  const [fileInputValidationContainer, setFileInputValidationContainer] = useState<FileInputValidationContainer[]>([]);

  function handleFileChange (e: ChangeEvent<HTMLInputElement>){

    const files = e.target.files;

    const onChangeFile = onChange ? onChange : (result: FileList | null) => {};

    if(!files) return onChangeFile(null);
    if(!files?.length) return onChangeFile(null);

    for(const item of files){
      if(item.size > props.maxSize) setFileInputValidationContainer(prev => (
        [...prev, {error: item.name + " exeed the maz size", fileName: item.name}]
      ))
    }

    if(files.length > props.maxCount) setFileInputValidationContainer(prev => (
      [...prev, {error: "file count exeed the max value", fileName: "all file"}]
    ))

    onChangeFile(files);
    
  }


  return (
    <>

    <div className={clsx(
      "relative",
      className
    )}>
      <input  
        onChange={handleFileChange}
        className={clsx(
          "w-full text-gray-900 rounded-sm cursor-pointer bg-secondary-light focus:outline-none border-b-4 border-b-warning",
          "file:bg-secondary-base file:py-3 file:px-5 file:cursor-pointer file:outline-none file:border-none file:text-gray-500",
          "hover:file:bg-secondary-light"
        )} 
        type="file"
        {...props}
        />
        <ul className="list-disc list-inside">
          {fileInputValidationContainer.map(item => (
            <li className="text-danger">{item.error}</li>
          ))}
        </ul>
    </div>
    </>
  )
}