
import Lottie from "lottie-react";
import congrats from "@assets/animation/congrate.json";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import LockIcon from "@shared/assets/icon/lock.svg?react";
import { DefaultTooltip } from "@shared/components/tooltip";
import { useTranslation } from "react-i18next";



export function DecryptPaymentinit(){

  const { t } = useTranslation();

  return(
    <div className="">
      <Lottie style={{ height: 90 }} animationData={congrats} loop/>
      <p>
        “{t("entities.task.stage.decrypt.decrypt_payment_init.message")}”
      </p>
      <div className="bg-secondary-base rounded-lg p-3 mt-5 flex justify-between items-center">
        <h3 className="text-white font-semibold">Decrypt Result</h3>
        <DefaultTooltip
          offset={12}
          reference={
            <SolidButton className="py-2">Download Result</SolidButton>
          }
        >
          <div className="max-w-md flex gap-2 items-center">
            <LockIcon width={50} height={50} className="shrink-0" />
            <p>
            {t("entities.task.stage.decrypt.decrypt_payment_init.preview_notice")}
            </p>
          </div>
        </DefaultTooltip>
      </div>
    </div>
  )
}