import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Scan } from "./types";



type ScanSliceState = {
  loading: boolean;
  error: any;
  scan : Scan;
}

const initialState : ScanSliceState = {
  error: "",
  loading: false,
  scan: {
    ScanPath : ""
  }
}


// const dummyScan: Scan = {
//   ScanPath : "/home/my-work",
// }

export const ScanSlice = createSlice({
  name: "scan",
  initialState,
  reducers: {
    setScanPath: (state, action : PayloadAction<string>) => {
      state.scan.ScanPath = action.payload;
    }
  }
})


export const selectIsPathDefined = (state: RootState) => {
  if(state.scan.scan.ScanPath)return true;
  return false;
}


export const {setScanPath } = ScanSlice.actions