import { CardWithDate } from "@shared/components/card";
import { useEffect, useState } from "react";
import { VirusView } from "./VirusView";
import { IndexingView } from "./IndexingView";
import { useAppSelector } from "@shared/model/hooks";
import { selectCurrentStageIndexing, selectTask } from "@entities/task";
import { SuccessView } from "./SuccessView";
import { IndexingSuccess } from "@entities/task/stage/indexing";
import { useTranslation } from "react-i18next";





export function ProcessVirusAndIndex(){
  const [localStage, setLocalStage] = useState<"virus-view" | "indexing-view" | "success-view">("virus-view");
  const isAlreadyIndexing = useAppSelector(selectCurrentStageIndexing);
  const virus = useAppSelector(state => state.currentVirus.virus)
  const task = useAppSelector(selectTask);
  
  const { t } = useTranslation();

  useEffect(()=>{
    if(isAlreadyIndexing) setLocalStage("success-view");
  },[isAlreadyIndexing])

  return (
    <>
      <CardWithDate 
        title={t("process.task_process.indexing_virus_process.title")}
        date={new Date()}
        className="w-full h-full"
      >
        <>
          {localStage === "virus-view" && (
            <VirusView moveToIndexingView={()=>setLocalStage("indexing-view")} />
          )}
          
          {localStage === "indexing-view" && (
            <IndexingView />
          )}

          {localStage === "success-view" && (
            <IndexingSuccess task={task} virus={virus} />
          )}
        </>
      </CardWithDate> 
    </>
  )
}