import ReactSelect from "react-select";
import { languageList } from "../data/language-list";
import { useState } from "react";
import i18next from 'i18next'
import { changeLanguage } from "@services/www.fixransomware.com/language";







export function SelectLanguageOption(){
  // const [selectedLanguage, setSelectedLanguage] = useState<typeof languageList[number] | null>(languageList[0]);

  function handleOnChange(e: {image: any, label: string, value: "en" | "id"}){
    i18next.changeLanguage(e.value);
    // setSelectedLanguage(e);

    // change language for this user
    changeLanguage(e.value);
  }

  return (
    <ReactSelect

      // @ts-expect-error should not error
      onChange={handleOnChange}
      options={languageList}
      defaultValue={i18next.resolvedLanguage === "id" ? languageList[1] : languageList[0]}
      // defaultValue={selectedLanguage}
      isSearchable={false}
      // value={i18next.resolvedLanguage === "id" ? languageList[1] : languageList[0]}
      formatOptionLabel={language => (
        <div className="inline-flex gap-2 text-primary">
          <img src={language.image} alt="language-image" />
          <span>{language.label}</span>
        </div>
      )}
    />
  )
}