





export function AgreementText(){
  return (
    <section className="p-4 text-gray-400 max-h-[500px] overflow-auto">


    <h5 className="text-xl text-gray-300 font-semibold mb-2">About Digipedia and Software Deloved by Digipedia</h5>
    <p className="mb-3">
      digipediasolution.com and the Websites or Applications developed
      by Digipedia is owned and operated by PT. Digitalisasi Global
      Inklusif (“Digipedia”, “Fixransomware”, “Us”, “We”, or “Our”).
      This Privacy Policy describes how we collect, use, disclose,
      process and protect personally identifiable information
      (“Personal Data”) that you (the “User”) may provide in
      connection with Digipedia services available through the website
      located at www.digipediasolution.com, www.fixransomware.com, or
      any website or App developed or hosted externally by Digipedia
      (“Site”). Personal Data means data, whether true or not, about
      an individual. References to the Site are deemed to include
      derivatives thereof, including but not necessarily limited to
      mobile websites and applications. By visiting or using the Site,
      you agree and consent to the collection, use, disclosure and
      processing of your Personal Data in accordance with this Privacy
      Policy and/or the Website Terms. If you do not agree to the
      Privacy Policy below, please leave the Site immediately.
    </p>
    <p className="mb-3">
      From time to time, Digipedia may revise this Privacy Policy to
      reflect changes in the law, our Personal Data collection and use
      practices, the features of our Site, or advances in technology.
      If we make revisions that change the way we collect or use your
      Personal Data, those changes will be posted in this Privacy
      Policy and the effective date will be noted at the beginning of
      this Privacy Policy. Therefore, you should review this Privacy
      Policy periodically so that you are up to date on our most
      current policies and practices. Digipedia will also prominently
      post such material changes prior to implementing the change. If
      you do not agree with any changes or modifications to the
      Privacy Policy, please do not continue using the Site. You will
      be deemed to have consented to any modification of the Privacy
      Policy when you use the Site after the effective date of the
      modification.
    </p>

    <h5 className="text-xl mb-1 text-gray-300">1. Types of Personal Data We Collect from You</h5>
    <p className="mb-3">
      We collect the Personal Data that you provide to us when using
      the Site. This includes but is not limited to: your name, user
      identification and login credentials, address, phone number,
      email address. We may request that you provide information on
      your feedback regarding your experiences in the website and
      applications developed by Us. We also collect non-personally
      identifiable information including but not limited to your
      internet protocol (IP) address, geographic location, operating
      system type, nationality, search preferences, as well as other
      generic Internet usage related data.
    </p>

    <h5 className="text-xl mb-1 text-gray-300">2. Why we collect and use personal data</h5>
    <p className="mb-2">
      We may use your Personal Data and other information gathered
      through the Site for the following purposes:
    </p>
    <ul className="list-disc ps-4 mb-3">
      <li>
        managing, operating, administering and providing you the
        services offered on the Site
      </li>
      <li>
        contacting you on matters relating to your use and/or access
        of the Site and services on the Site, and any enquiries and/or
        requests submitted by you through the Site or otherwise
      </li>
      <li>
        measuring and improving customer trust, experience and
        satisfaction
      </li>
      <li>
        We may also use your Personal Data and other information
        collected for Social Media marketing using direct and open
        graph techniques; for digital and conventional marketing
        purposes such as sending you direct mailers about new
        products, and special offers or other information which we
        think you may find interesting. Please note that you may opt
        out of any marketing materials we may send to you at any time.
        Please follow the unsubscribe instructions set out in our
        marketing materials if you choose to opt out of any marketing
        materials and we will respect your wishes.
      </li>
    </ul>

    <h5 className="text-xl mb-1 text-gray-300">
      3. How Digipedia Protects your Data Privacy & Provide Safe
      Transactions
    </h5>
    <p className="mb-3">
      We protect Personal Data by maintaining reasonable security
      arrangements, including physical, technical and organizational
      procedures, to prevent unauthorised access, collection, use,
      disclosure, copying, modification, disposal or similar risks.
    </p>
    <p className="mb-3">
      When you place orders, we use a secure ­server. The secure
      server software (SSL)­ encrypts all information you input
      befo­re it is sent to us. Furthermore, all of­ the sensitive
      customer data we collect ­is protected by several layers of
      encryp­tion and several layers of security to ­ prevent
      unauthorized access.
    </p>
    <p className="mb-3">
      Furthermore, We protect Personal Data by maintaining reasonable
      security arrangements, including physical, technical and
      organizational procedures, to prevent unauthorised access,
      collection, use, disclosure, copying, modification, disposal or
      similar risks.
    </p>
    <p className="mb-3">
      All credit card payments are processed ­securely through the
      Payment Gateway by one of the following: Paypal, Tokopedia,
      Shopee, PT Midtrans, and Bank Negara Indonesia (BNI). We. do not
      st­ore any credit card information on our s­ervers. Our payment
      options presently available­ are limited but, most importantly,
      safe­ and secure. We offer Credit Card paymen­ts for all our
      registered users. Credit ­Card payments are made directly
      through ­a SSL Encrypted software to ens­ure your personal
      details are kept safe­. None of your payment details are stored
      – this ensures you will be happy with the security of your
      transaction.
    </p>

    <h5 className="text-xl mb-1 text-gray-300">4. Sharing and Transferring Your Personal Data</h5>
    <p className="mb-3">
      Your Personal Data may be shared by Digipedia to our affiliate
      companies. Digipedia (and/or its affiliate companies) may also
      disclose your Personal Data to third party service providers,
      suppliers or agents for one or more of the above purposes..
      Third party services include but are not limited to: website
      hosting, data analysis, marketing, and service provisions.
    </p>
    <p className="mb-3">
      Please note that Digipedia may disclose your Personal Data in
      the following situations:
    </p>
    <ul className="list-disc ps-4 mb-3">
      <li>to bring or defend against any claims or suits</li>
      <li>
        to comply with court orders, judicial processes, legitimate
        requests, warrants or equivalent by law enforcement officials
        or the authorities
      </li>
      <li>
        to investigate fraud or other wrongdoing or as otherwise
        required or necessary in order to comply with any applicable
        law, or to protect our legitimate interests;
      </li>
      <li>
        to the purchasers in connection with any sale, assignment, or
        other transfer of all or part of our business or company
      </li>
      <li>
        to enforce or apply the terms and conditions applicable to our
        products and services
      </li>
      <li>for any other situations which is allowed under law</li>
    </ul>

    <h5 className="text-xl mb-1 text-gray-300">5. Consent & Withdrawing Consent</h5>
    <p className="mb-3">
      As stated above, by browsing and using the Site, ordering a
      product from the Site, registering for or using any service on
      the Site, or by clicking the “Confirm” button or equivalent when
      you create a new account on the Site, you:
    </p>
    <ul className="list-disc ps-4 mb-3">
      <li>
        <p>
          consent to Digipedia and/or Digipedia’ affiliate companies
          collecting, using, disclosing and/or processing your above
          mentioned personal data for the purposes as described above;
          and
        </p>
      </li>
    </ul>
    <p className="mb-3">
      You may withdraw your consent to our collection, use or
      disclosure of your personal data at any time, by giving us
      reasonable notice. If you wish to withdraw your consent, please
      inform us at our contact details listed below. We will cease to
      collect, use or disclose your personal data upon notification,
      unless required by law or if we have legitimate business or
      legal purposes for retaining it. Note that by withdrawing your
      consent to our collection, use or disclosure of your personal
      data, we may not be able to continue providing you with our
      services and you agree that we will not be liable to you for any
      losses or damages arising out of or in relation to such
      termination of services.
    </p>

    <h5 className="text-xl mb-1 text-gray-300">6. Cookie Policiy</h5>
    <p className="mb-3">
      A “cookie” is an alphanumeric identifier which we transfer to
      your hard drive or memory card through your web browser when you
      visit our Site. It enables our own system to recognise you when
      you visit our Site again and improve our service to you. The
      information is used to track visitor use of the Site and to
      compile statistical reports on Site activity. For further
      information about cookies visit www.aboutcookies.org or
      www.allaboutcookies.org.
    </p>
    <p className="mb-3">
      Cookies may also be used to compile aggregate information about
      areas of our Site that are visited most frequently. This traffic
      information can be used to enhance the content of our Site and
      make your use of it easier. If you wish to reject our cookie,
      you can configure your browser to do so. However, some of our
      Site features may not function if you remove cookies from your
      browser. If you do not enable cookies, you may only be able to
      use the Site on a limited basis.
    </p>

    <h5 className="text-xl mb-1 text-gray-300">7. Approval of Privacy Policy Changes</h5>
    <p className="mb-3">
      By using the Site or the services we provide, you agree to the
      collection, use, disclosure and processing of your Personal Data
      as set forth in this Privacy Policy. Furthermore, if you use our
      services, we reserve the right to collect, use, disclose and
      process your Personal Data in accordance to this Privacy Policy.
      From time to time, Digipedia may change this Privacy Policy. We
      will display the changes on this Site prior to making the
      changes.
    </p>

    <h5 className="text-xl mb-1 text-gray-300">8. Contact Us</h5>
    <p className="mb-3">
      If you have any questions or requests relating to this Privacy
      Policy, please contact our Data Protection Officer at the
      following details, or such person as Digipeida may designate by
      informing you through any letter, invoice, email or the Site:
      pt.dgi.business.com or Whatsapp/Telegram on (62) 85157787595.
    </p>
    </section>
  )
}