import confirmedSrc from "@assets/ilustration/confirmed.png";
import { useTranslation } from "react-i18next";




export function AnalystPaymentConfirm(){

  const { t } = useTranslation();

  return(
    <div className="p-16">
      <img src={confirmedSrc as string} width={80} height={80} className="mx-auto" />
      <p className="text-center">
      {t("entities.task.stage.analyst.analyst_payment_confirm")}
      </p>
      {/* <p className="text-center">Your payment Confirmed, please waiting for analyst file from us</p> */}
      {/* <div className="bg-secondary-base rounded-lg p-3 mt-5 flex justify-between items-center">
        <h3 className="text-white font-semibold">Analisis Report and invoice</h3>
        <SolidButton>Download Invoice</SolidButton>
      </div> */}
    </div>
  )
}