import fixransomwareLogo from "@assets/img/logo.svg";
// import enFlag from "@assets/icon/en-flag.svg";
// import idFlag from "@assets/icon/id-flag.svg";
import { SelectLanguageOption } from "@entities/language";



export function LogoAndLanguage(){
  
  
  return (
    <div className="flex justify-between items-center">
      <img src={fixransomwareLogo} />
      <SelectLanguageOption />
    </div>
  )
}