import { TextAreaInput } from "@shared/components/form";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { textToPlain } from "@shared/utils/file-generator";
import { MouseEvent, useRef, useState } from "react";




interface TextInputToFileProps{
  onSubmit: (...args:any) => any;

  // store generated file
  onFileGenerated: (file: File) => any;

  placeholder: string;

  value?: string;

  name: string;
}


export function TextInputToFile(props: TextInputToFileProps){

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textValue, setTextValue] = useState(props.value);

  function generateFile(){

    // get the string in text area
    const text = textareaRef.current?.value ?? "";
    const textBlob = textToPlain(text);
    return new File([textBlob], props.name + "-" + Date.now() + ".txt");
  }

  function handleOnSubmit(e: MouseEvent<HTMLButtonElement>){
    e.preventDefault();

    props.onFileGenerated(generateFile());
    props.onSubmit();
  }

  return (
    <>
      <TextAreaInput
        ref={textareaRef}
        placeholder={props.placeholder}
        name={props.name}
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
      />
      <SolidButton onClick={handleOnSubmit} variant="success" className="mt-3 block ms-auto">
        Submit
      </SolidButton>
    </>
  )
}