import { InstituionOptionType } from "..";

// "pt", "cv", "school", "rs", "other"
export const INSTITUIION_TYPE = [
  {
    label: "PT",
    value: "PT",
  },
  {
    label: "CV",
    value: "CV"
  },
  {
    label: "Sekolah",
    value: "SCHOOL"
  },
  {
    label: "Rumah Sakit",
    value: "RS",
  },
  {
    label: "Lainya..",
    value: "OTHER"
  }

] as const satisfies InstituionOptionType[]

