import { isObjectEmpty } from "@shared/utils";


class ElectronRequestor {
  constructor(){}

  isInElectron(desktopClient: electronProcess | undefined): desktopClient is electronProcess{
    return !isObjectEmpty(desktopClient)
  }

  getElectronProcess<T extends keyof electronProcess>(processName: T){
    if(!this.isInElectron(window.desktopClient)) return null;
    return window.desktopClient[processName];
  }
}

export function isInElectron(){
  return !isObjectEmpty(window.desktopClient)
} 

/**
 * more abstraction when accessing electron process.
 * automatically use empty method when there are no electron service available.
 * @example
 * ```
 * const electronRequestor = new ElectronRequestor();
 * const scanService = new ElectronProcessService("scanAction", electronRequestor);
 * const scan = scanService.getService("scan");
 * // the scan method can be null, so always check it first before use it
 * scan(); //run electron process 
 * ```
 * 
 */
export class ElectronProcessService <T extends keyof electronProcess>{

  private service: electronProcess[T] | null;

  constructor(
    private serviceName: T,
    private readonly requestor: ElectronRequestor
  ){
    this.service = requestor.getElectronProcess(serviceName);
  }

  getOnService<
    K extends electronProcess[T] extends infer 
      R extends {on: Record<string, any>} 
        ? keyof R["on"] 
        : never 
  >(name: K): 
  (electronProcess[T] extends {on: Record<string, any>} 
    ? electronProcess[T]["on"][K]
    : null)
  | null
  {
    if(!this.service) return null;
    if(!('on' in this.service)) return null
    if(isObjectEmpty(this.service.on)) return null;
    if(!(name in this.service.on)) return null;

    // @ts-ignore
    return this.service.on[name as keyof typeof this.service.on];
  }

  getSendService<
    K extends electronProcess[T] extends infer 
      R extends {send: Record<string, any>} 
        ? keyof R["send"] 
        : never  
  >(name: K):
  (electronProcess[T] extends {send: Record<string, any>} 
    ? electronProcess[T]["send"][K]
    : null)
  | null
  {
    if(!this.service) return null;
    if(!('send' in this.service)) return null
    if(isObjectEmpty(this.service.send)) return null;
    if(!(name in this.service.send)) return null;

    // @ts-ignore
    return this.service.send[name as keyof typeof this.service.send];
  }


  getInvokeService<
    K extends electronProcess[T] extends {invoke: Record<string, any>} 
      ? keyof electronProcess[T]["invoke"] 
      : never 
  >(name: K): 
    (electronProcess[T] extends {invoke: Record<string, any>} 
      ? electronProcess[T]["invoke"][K]
      : null)
    | null
  {
    if(!this.service) return null;
    if(!('invoke' in this.service)) return null
    if(isObjectEmpty(this.service.invoke)) return null;
    if(!(name in this.service.invoke)) return null;
    
    
    return this.service.invoke[name as keyof typeof this.service.invoke]!;
  }
  
}


export const electronRequestor = new ElectronRequestor();