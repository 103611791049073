import stampSrc from "@assets/ilustration/stamp.png";
import { useTranslation } from "react-i18next";


export function AnalystPaymentAction(){

  const { t } = useTranslation();

  return (
    <div className="p-16">
      <img src={stampSrc as string} width={80} height={80} className="mx-auto mb-9" />
      <p className="">
        {t("entities.task.stage.analyst.analyst_payment_action")}
      </p>
    </div>
  )
}