import { MainLayout } from "@shared/layouts/MainLyaout";
import { MainFooter } from "@widgets/main-footer/MainFooter";
import { MainNavbar } from "@widgets/main-navbar";
import { PageWrapperRoute } from "./PageWrapperRoute";
import { useGuardUserAuth } from "@features/auth/guard-user";
import { useSetupUser } from "@features/auth/setup-user";
import { WindowFrame } from "@widgets/frame";
import { isInElectron } from "@services/electron";




export function MainLayoutRoute(){
  useSetupUser();
  useGuardUserAuth();
  return(
    <MainLayout
      frameSlot={ isInElectron() ? <WindowFrame /> : null}
      navbarSlot={<MainNavbar />}
      contentSlot={<PageWrapperRoute />}
      footerSlot={<MainFooter />}
    />
  )
}