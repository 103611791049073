import { useGuardGuest } from "@features/auth/guard-user";
import { useSetupUser } from "@features/auth/setup-user";
import { isInElectron } from "@services/electron";
import { AuthLayout } from "@shared/layouts/AuthLayout";
import { CompanyPhotoCarousel } from "@widgets/company-photo-carousel";
import { WindowFrame } from "@widgets/frame";
import { LogoAndLanguage } from "@widgets/logo-and-language";
import { Outlet } from "react-router-dom";






export function AuthLayoutRoute(){
  useSetupUser();
  useGuardGuest();
  return (
    <AuthLayout
      frameSlot={isInElectron() ? <WindowFrame /> : null}
      leftSlot={<Outlet />}
      navbarSLot={<LogoAndLanguage />}
      rightSlot={<CompanyPhotoCarousel />}
    />
  )
}