import stampSrc from "@assets/ilustration/stamp.png";
import { useTranslation } from "react-i18next";

export function DecryptPaymentAction(){
  const { t } = useTranslation();

  return (
    <div className="p-10">
      <img src={stampSrc as string} width={80} height={80} className="mx-auto" />
      <p className="">
        {t("entities.task.stage.decrypt.decrypt_payment_action")}
      </p>
    </div>
  )
}