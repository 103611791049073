import { useInterval } from "@shared/hooks";
import { useState, useEffect, useRef } from "react";

const SECOND = 1_000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

/**
 * @description
 * NOTE: this hooks will always renrender every 1 second
 * so make sure when used inside a component it's correctly wrapped
 * @param {string} deadline UTC deadline isoString
 * @param {number} interval interval in milisecond
 * @returns 
 */
export const useTimer = (
  deadline : string = (new Date()).toISOString(), 
  interval : number = SECOND
) => {

  const [timespan, setTimespan] = useState( new Date(deadline).valueOf() - Date.now().valueOf());
  const [isRunning, setIsRunning] = useState(true);


  useInterval(()=>{
    setTimespan((_timespan) => _timespan - interval);

    // when milisecond less than 1000 that's mean the time already 0 second
    if(timespan <= 1000){
      setTimespan(0);
      setIsRunning(false);
      return;
    }
    // console.log("timespan from interval : " + timespan)
  },isRunning ? interval : null)

  /* If the initial deadline value changes */
  useEffect(() => {
    if(new Date(deadline).valueOf() - Date.now() < 0 ){
      setTimespan(0);
      setIsRunning(false);
    }else{
      setTimespan(new Date(deadline).valueOf() - Date.now());
      setIsRunning(true);
    }
  }, [deadline]);

  return {
    days: Math.floor(timespan / DAY).toString().padStart(2, "0"),
    hours: Math.floor((timespan / HOUR) % 24).toString().padStart(2, "0"),
    minutes: Math.floor((timespan / MINUTE) % 60).toString().padStart(2, "0"),
    seconds: Math.floor((timespan / SECOND) % 60).toString().padStart(2, "0"),
    isRunning
  };
}