




export function MainFooter(){
  return (
    <div className="p-4 flex flex-row-reverse text-gray-500">
      <p>
        © Copyright 2024
        <a className="ml-2 underline underline-offset-2 hover:text-white" href="http://fixransomware.com" target="_blank" rel="noopener noreferrer">
           FixRansomwareTeam
        </a>
        . All rights reserved.
      </p>
    </div>
  )
}