import { Task } from ".";





export const dummyIndexingTask : Task = {
  status: "Indexing with AI",
  id : "hello semuanya",
  fileIndexing: "inifile.txt",
  percentage: "3",
  createdAt: "2023-10-18",
  updatedAt: "2023-10-31",
  virusVarian: "iniv",
  userId: "iniuseryangbanyak",
}


export const dummySampleAndHackerTask : Task = {
  status: "Static Analysis Sample",
  id : "hello semuanya",
  fileIndexing: "inifile.txt",
  percentage: "7",
  createdAt: "2023-10-18",
  updatedAt: "2023-10-31",
  virusVarian: "iniv",
  userId: "iniuseryangbanyak",
  hackerNote: ["hacknerss.tsx"],
  sampleFile: ["ini adalah sample file"],
}



export const dummyAnalystPaymentInit : Task = {
  status: "Static Analysis Payment",
  id : "hello semuanya",
  fileIndexing: "inifile.txt",
  percentage: "10",
  createdAt: "2023-10-18",
  updatedAt: "2023-10-31",
  virusVarian: "iniv",
  userId: "iniuseryangbanyak",
  hackerNote: ["hacknerss.tsx"],
  sampleFile: ["ini adalah sample file"],
  analystNominal: "10000",
  analystDiscount: "10",
  analystPaymentDeadline: "2023-10-19",
}


export const dummyAnalystPaymentAction : Task = {
  status: "Static Analysis Payment",
  id : "hello semuanya",
  fileIndexing: "inifile.txt",
  percentage: "17",
  createdAt: "2023-10-18",
  updatedAt: "2023-10-31",
  virusVarian: "iniv",
  userId: "iniuseryangbanyak",
  hackerNote: ["hacknerss.tsx"],
  sampleFile: ["ini adalah sample file"],
  analystNominal: "10000",
  analystDiscount: "10",
  analystPaymentDeadline: "2023-10-19",

  // action
  analystPaymentMethod: "bni",
  analystProof: "inifile.jpg",
  analystStatus: "pending",
  
}

export const dummyAnalystPaymentConfirm : Task = {
  status: "Static Analysis Payment",
  id : "hello semuanya",
  fileIndexing: "inifile.txt",
  percentage: "20",
  createdAt: "2023-10-18",
  updatedAt: "2023-10-31",
  virusVarian: "iniv",
  userId: "iniuseryangbanyak",
  hackerNote: ["hacknerss.tsx"],
  sampleFile: ["ini adalah sample file"],
  analystNominal: "10000",
  analystDiscount: "10",
  analystPaymentDeadline: "2023-10-19",
  currency: "idr", // NOTE: rename this field in the database, it should analyst_currency


  // action
  analystPaymentMethod: "bni",
  analystProof: "inifile.jpg",
  analystStatus: "Success",
  
}

export const dummyAnalystResult : Task = {
  status: "Static Analysis Payment",
  id : "hello semuanya",
  fileIndexing: "inifile.txt",
  percentage: "25",
  createdAt: "2023-10-18",
  updatedAt: "2023-10-31",
  virusVarian: "iniv",
  userId: "iniuseryangbanyak",
  hackerNote: ["hacknerss.tsx"],
  sampleFile: ["ini adalah sample file"],
  analystNominal: "10000",
  analystDiscount: "10",
  analystPaymentDeadline: "2023-10-19",
  currency: "idr", // NOTE: rename this field in the database, it should analyst_currency


  // action
  analystPaymentMethod: "bni",
  analystProof: "inifile.jpg",
  analystStatus: "Success",

  // result
  downloadAnalysis: "htttps://github.com/zidan-p",
  
}



// export const dummyConfirmAnalystPayment : Task = {
//   status: "Static Analysis Payment",
//   id : "hello semuanya",
//   fileIndexing: "inifile.txt",
//   percentage: "3",
//   createdAt: "2023-10-18",
//   updatedAt: "2023-10-31",
//   virusVarian: "iniv",
//   userId: "iniuseryangbanyak",
//   hackerNote: ["hacknerss.tsx"],
//   sampleFile: ["ini adalah sample file"],
//   analystNominal: "10000",
//   analystDiscount: "10",
//   analystPaymentDeadline: "2023-10-19",
//   currency: "idr", // NOTE: rename this field in the database, it should analyst_currency

//   // action
//   analystPaymentMethod: "bni",
//   analystProof: "inifile.jpg",
//   analystStatus: "Success", // <--

//   // result
//   downloadAnalysis: "htttps://github.com/zidan-p",
// }


export const dummyDecryptPaymentInit : Task = {
  status: "Decryption Payment",
  id : "hello semuanya",
  fileIndexing: "inifile.txt",
  percentage: "30",
  createdAt: "2023-10-18",
  updatedAt: "2023-10-31",
  virusVarian: "iniv",
  userId: "iniuseryangbanyak",
  hackerNote: ["hacknerss.tsx"],
  sampleFile: ["ini adalah sample file"],
  analystNominal: "10000",
  analystDiscount: "10",
  analystPaymentDeadline: "2023-10-19",
  currency: "idr",
  analystPaymentMethod: "bni",
  analystProof: "inifile.jpg",
  analystStatus: "Success",
  downloadAnalysis: "htttps://github.com/zidan-p",

  // init decrypt payment
  decryptNominal: "100000",
  decryptDiscount: "10",
  decryptPaymentDeadline: "2023-10-20",

}


export const dummyDecryptPaymentAction : Task = {
  status: "Decryption Payment",
  id : "hello semuanya",
  fileIndexing: "inifile.txt",
  percentage: "31",
  createdAt: "2023-10-18",
  updatedAt: "2023-10-31",
  virusVarian: "iniv",
  userId: "iniuseryangbanyak",
  hackerNote: ["hacknerss.tsx"],
  sampleFile: ["ini adalah sample file"],
  analystNominal: "10000",
  analystDiscount: "10",
  analystPaymentDeadline: "2023-10-19",
  currency: "idr",
  analystPaymentMethod: "bni",
  analystProof: "inifile.jpg",
  analystStatus: "Success",
  downloadAnalysis: "htttps://github.com/zidan-p",

  // init decrypt payment
  decryptNominal: "100000",
  decryptDiscount: "10",
  decryptPaymentDeadline: "2023-10-20",
  decryptCurrency: "idr",

  // payment
  decryptPaymentMethod: "bni",
  decryptProof: "ini file yang besar",
  decryptPaymentStatus: "pending",

}

export const dummyDecryptPaymentConfirm : Task = {
  status: "Decryption Payment",
  id : "hello semuanya",
  fileIndexing: "inifile.txt",
  percentage: "39",
  createdAt: "2023-10-18",
  updatedAt: "2023-10-31",
  virusVarian: "iniv",
  userId: "iniuseryangbanyak",
  hackerNote: ["hacknerss.tsx"],
  sampleFile: ["ini adalah sample file"],
  analystNominal: "10000",
  analystDiscount: "10",
  analystPaymentDeadline: "2023-10-19",
  currency: "idr",
  analystPaymentMethod: "bni",
  analystProof: "inifile.jpg",
  analystStatus: "Success",
  downloadAnalysis: "htttps://github.com/zidan-p",

  // init decrypt payment
  decryptNominal: "100000",
  decryptDiscount: "10",
  decryptPaymentDeadline: "2023-10-20",
  decryptCurrency: "idr",

  // payment
  decryptPaymentMethod: "bni",
  decryptProof: "ini file yang besar",
  decryptPaymentStatus: "Success", // <<<

}


export const dummyDecryptEstimation : Task = {
  status: "Decryption Payment", // err, refactor backend latter
  id : "hello semuanya",
  fileIndexing: "inifile.txt",
  percentage: "40",
  createdAt: "2023-10-18",
  updatedAt: "2023-10-31",
  virusVarian: "iniv",
  userId: "iniuseryangbanyak",
  hackerNote: ["hacknerss.tsx"],
  sampleFile: ["ini adalah sample file"],
  analystNominal: "10000",
  analystDiscount: "10",
  analystPaymentDeadline: "2023-10-19",
  currency: "idr",
  analystPaymentMethod: "bni",
  analystProof: "inifile.jpg",
  analystStatus: "Success",
  downloadAnalysis: "htttps://github.com/zidan-p",

  // init decrypt payment
  decryptNominal: "100000",
  decryptDiscount: "10",
  decryptPaymentDeadline: "2023-10-20",
  decryptCurrency: "idr",

  // payment
  decryptPaymentMethod: "bni",
  decryptProof: "ini file yang besar",
  decryptPaymentStatus: "pending",

  estimatedDecrypt: "3", // days

}



export const dummyDecrypFinish : Task = {
  status: "Finish Decryption", // err, refactor backend latter
  id : "hello semuanya",
  fileIndexing: "inifile.txt",
  percentage: "100",
  createdAt: "2023-10-18",
  updatedAt: "2023-10-31",
  virusVarian: "iniv",
  userId: "iniuseryangbanyak",
  hackerNote: ["hacknerss.tsx"],
  sampleFile: ["ini adalah sample file"],
  analystNominal: "10000",
  analystDiscount: "10",
  analystPaymentDeadline: "2023-10-19",
  currency: "idr",
  analystPaymentMethod: "bni",
  analystProof: "inifile.jpg",
  analystStatus: "Success",
  downloadAnalysis: "htttps://github.com/zidan-p",

  // init decrypt payment
  decryptNominal: "100000",
  decryptDiscount: "10",
  decryptPaymentDeadline: "2023-10-20",
  decryptCurrency: "idr",

  // payment
  decryptPaymentMethod: "bni",
  decryptProof: "ini file yang besar",
  decryptPaymentStatus: "pending",

  estimatedDecrypt: "3", // days

  decryptFile: "https://github.com"

}