import medalImage from "@assets/icon/medal.png";
import { useState } from "react";
import { ModalPremium } from "./ModalPremium";
import { Donation } from "@entities/donation";


interface PremiumBadgeProps {
  getNewestDonation: () => Promise<Donation>
}

export function PremiumBagde(props: PremiumBadgeProps){

  const [isModalOpen, setIsOpenModalOpen] = useState(false); 
  const [licenceKey, setLicenceKey] = useState("Loading.....")

  async function handleButtonOnClick(){
    setIsOpenModalOpen(!isModalOpen);
    
    const donation = await props.getNewestDonation(); 
    console.log(donation);
    setLicenceKey(donation.licenceKey);
  }

  return (
    <>
    <button 
      onClick={handleButtonOnClick}
      className="border border-warning hover:bg-warning/90 active:bg-warning/80 inline-flex items-center gap-3 px-5 py-1.5 rounded-full text-white transition-all">
      <img src={medalImage as string} alt="" />
      Premium
    </button>

    <ModalPremium 
      licenceKey={licenceKey}
      isModalOpen={isModalOpen}
      setIsModalOpen={() => setIsOpenModalOpen(!isModalOpen)}
     />
    </>
  )
}