import { ForgotPasswordForm } from "@features/auth/forgot-password";
import { GoToLoginEmailLink } from "@features/auth/login-by-email";





export function ForgetPassword(){
  return (
    <>
    <h1 className="text-4xl mb-3">Forgot Password</h1>
    <GoToLoginEmailLink className="mb-3" />
    <ForgotPasswordForm />
    </>
  )
}