import { motion } from "framer-motion";
import { ReactNode } from "react";

interface ProcessWrapperProps {
  children: ReactNode;
}

const processvariant = {
  initial:{
    opacity: 0
  },
  animate:{
    opacity: 1,
    transition: {
      delay: 0.5
    }
  },
  exit:{
    opacity: 0
  }
}

export function ProcessWrapperStyle(props: ProcessWrapperProps){
  return (
    <motion.div variants={processvariant}
      initial="initial"
      animate="animate"
      exit="exit"
      className="h-full"
    >
      <div className="p-2 flex flex-col h-full">
        <div className="grow h-full">
        {props.children}
        </div>
      </div>
    </motion.div>
  )
}