import { useEffect, useRef } from "react";
import { checkOrCreateIfEmptyByNameVirus } from "..";
import { useAppDispatch, useAppSelector } from "@shared/model/hooks";
import { selectIfOnlyVirusNameThatPresent } from "@entities/virus";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";







interface CheckOrCreateVirusIfEmptyProps {
  onCheckComplete : (...args:any[]) => any;
}

export function CheckOrCreateVirusIfEmpty(props: CheckOrCreateVirusIfEmptyProps){
  const currentVirus = useAppSelector(state => state.currentVirus);
  const isVirusOnlyName = useAppSelector(selectIfOnlyVirusNameThatPresent);
  const isVirusFetchLoading = useAppSelector(state => state.currentVirus.loading);
  const isVirusFetchError = useAppSelector(state => state.currentVirus.error);
  const wasCalled = useRef(false); // to make sure on once called
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(()=>{
    if(wasCalled.current) return;
    wasCalled.current = true;
    
    async function run(){
      try {
        /* const originalResult = */ await dispatch(checkOrCreateIfEmptyByNameVirus(currentVirus.virus.virusName)).unwrap();
        // console.log(originalResult);
      } catch (error : unknown) {
        toast.error((error as Error).message);
      }
    }

    // when virus is already present (virus data complete in virus tab)
    if(!isVirusOnlyName) props.onCheckComplete();
    else run();

  },[])

  useEffect(()=>{
    if(isVirusFetchLoading) return;

    if(isVirusFetchError) {
      toast.error(currentVirus.error);
      return;
    }

    if(isVirusOnlyName) return;

    // when virus fetch completed and doesn't have error
    props.onCheckComplete();
  },[isVirusFetchLoading])

  return (
    <>
    {isVirusFetchLoading ? (
      <div className="flex flex-col h-full">
        <div className="max-h-full overflow-auto grow p-2 text-gray-400">
          "{t("features.virus.check_or_create_if_empty.checking")}"
        </div>
      </div>
    ) : (
      <div className="flex flex-col h-full">
        <div className="max-h-full overflow-auto grow p-2 text-gray-400">
        "{t("features.virus.check_or_create_if_empty.check_complete")}"
        </div>
      </div>
    )}
  </>
  )
}