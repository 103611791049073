import { ReactElement, ReactNode, useMemo } from "react";
import { Timer } from "../timer";
import qrisImg from "@assets/img/qris.png";
import paypalImg from "@assets/img/paypal.png";
import { useTranslation } from "react-i18next";
import { CommonPaymentMethod } from "./types";
import clsx from "clsx";
import { FormatIDR, FormatUSD } from "@shared/utils";



interface CommonPaymentProps {
  onPaymentMethodChange: (paymentMethod: CommonPaymentMethod ) => void;
  paymentMethod: string;
  invoice: number | string;
  status: string;
  amount: string | number;
  currency?: "USD" | "IDR"

  /**in percentage*/
  discount: string | number;

  total: string | number;
  withDeadline?: boolean;
  deadline: Date;

  buttonSlot: ReactElement[] | ReactElement;

}


/**
 * @todo refactor use memo value
 * @param param0 
 * @returns 
 */
export function CommonPayment({withDeadline = false ,...props}: CommonPaymentProps){

  const {t} = useTranslation();

  const totalView = useMemo(() => {
    console.log("totoalnya adalah")
    console.log(props.total);
    if(props.currency === "USD"){
      const usdValue = FormatUSD(props.total); 
      console.log(usdValue);
      return usdValue;
    }

    return FormatIDR(props.total);
  }, [props.total]);

  const amountView = useMemo(() => {
    console.log("totoalnya adalah")
    console.log(props.amount);
    console.log(props.currency);
    if(props.currency === "USD"){
      const usdValue = FormatUSD(props.amount); 
      console.log(usdValue);
      return usdValue;
    }

    return FormatIDR(props.amount);
  }, [props.amount]);

  console.log(totalView);
  

  return (
  <div className="p-2">
    <div className="flex justify-between mb-2">
      <span className="font-semibold">{t("common.common_payment.proceed_payment")}</span>
      <span className="text-success font-light">INV {props.invoice}</span>
    </div>
    <hr className="border-secondary-light mb-2" />
    <div className="mb-2">
      <h4 className="font-semibold mb-1">{t("common.common_payment.payment_detail")}</h4>
      <div className="flex justify-between">
        <span className="text-gray-500">Status</span>
        <span className="font-semibold text-warning">{props.status}</span>
      </div>
      <div className="flex justify-between">
        <span className="text-gray-500">{t("common.common_payment.amount")}</span>
        <span className="font-medium">{amountView}</span>
        {/* <span className="font-medium">{props.amount}</span> */}
      </div>
      <div className="flex justify-between">
        <span className="text-gray-500">{t("common.common_payment.discount")}</span>
        <span className="font-medium">{props.discount} %</span>
      </div>
      <div className="flex justify-between">
        <span className="text-gray-500">{t("common.common_payment.total")}</span>
        <span className="font-medium">{totalView}</span>
        {/* <span className="font-medium">{props.total}</span> */}
      </div>
    </div>
    <hr className="border-secondary-light mb-2" />
    <div className="mb-3">
      { withDeadline && <Timer deadline={props.deadline} />}
    </div>
    <div className="mb-3">
      <div className="flex justify-between items-center">
        <span className="font-semibold">{t("common.common_payment.choose_payment_method")}</span>
        <select 
          onClick={e=>props.onPaymentMethodChange(e.currentTarget.value as "BNI" | "QRIS")}
          name="method" 
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
        >
          <option value="BNI">BNI</option>
          <option value="QRIS">QRIS</option>
          <option value="PAYPAL">PAYPAL</option>
        </select>
      </div>
    </div>
    <div className="mb-5">
      <div className={clsx(!(props.paymentMethod == "BNI") && "hidden")}>
        <div className="flex justify-between">
          <span className="text-gray-500">Account Number</span>
          <span>1122649143</span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500">Account Owner</span>
          <span>PT DIGITALISASI GLOBAL INKLUSIF</span>
        </div>
      </div>

      <img className={clsx("mx-auto", !(props.paymentMethod === "QRIS") && "hidden")} width={320} src={qrisImg as string} />

      <img className={clsx("mx-auto", !(props.paymentMethod === "PAYPAL") && "hidden")}width={320} src={paypalImg as string} />
    </div>
    <div className="mb-2 text-gray-500">
    {t("common.common_payment.notice")}
    </div>
    <div className="flex gap-2">
      {props.buttonSlot}
    </div>
  </div>
  )
}