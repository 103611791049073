import { ScreenWithLimiter } from "@widgets/screen-width-limiter";
import { ReactElement } from "react"



type MainLayoutProps = {
  navbarSlot: ReactElement;
  contentSlot: ReactElement;
  footerSlot: ReactElement;
  frameSlot?: ReactElement | null;
}



export function MainLayout(props: MainLayoutProps){
  return (
    <div className="flex flex-col bg-secondary-dark h-screen max-h-screen">
      {props.frameSlot}
      <div className="bg-secondary-base rounded-t-md max-h-full overflow-hidden h-full flex flex-col">
        <nav>{props.navbarSlot}</nav>
        <main className="text-white h-full overflow-x-hidden">
          {props.contentSlot}
        </main>
        <footer>{props.footerSlot}</footer>
      </div>
      <ScreenWithLimiter/>
    </div>
  )
}