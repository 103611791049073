import { ElectronProcessService, electronRequestor } from ".";

const windowActionElectronService = new ElectronProcessService("windowAction", electronRequestor);

const maximize = windowActionElectronService.getSendService("maximize");
export function maximizeService(){
  if(!maximize) return;
  maximize();
}

const isMaximized = windowActionElectronService.getInvokeService("isMaximized");
export function isMaximizedService(){
  if(!isMaximized) return null;
  return isMaximized();
}

const minimize = windowActionElectronService.getSendService("minimize");
export function minimizeService(){
  console.log(minimize)
  console.log(window.desktopClient);
  if(!minimize) return;
  minimize();
}

const unMaximize = windowActionElectronService.getSendService("unmaximize");
export function unMaximizeService(){
  if(!unMaximize) return;
  unMaximize();
}

const close = windowActionElectronService.getSendService("close");
export function closeService(){
  if(!close) return;
  close();
}

const selectDirectory = windowActionElectronService.getInvokeService("selectDirectory")
export async function selectDirectoryService(){
  if(!selectDirectory) return {
    status: false,
    path: ""
  }
  try {
    const result = await selectDirectory();
    return result;
  } catch (error) {
    console.error("error");
    return {
      status: false,
      path: ""
    }
  }
}