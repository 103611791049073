import { DialogWithHeader } from "@shared/components/dialog";
import { useTranslation } from "react-i18next";
import medalImage from "@assets/icon/medal.png";

interface ModalPremiumProps {
  licenceKey: string;
  isModalOpen: boolean;
  setIsModalOpen: (...args: any[]) => any;
}

function TitleComponent (){
  return (
    <h1 className="inline-flex items-center gap-3">
      <img src={medalImage as string} alt="medal image" />
      Anda Telah Memiliki Status Premium
    </h1>
  )
}


export function ModalPremium(props: ModalPremiumProps) {

  const {t} = useTranslation();

  return (
    <DialogWithHeader 
      isOpen={props.isModalOpen}
      modalAccess={props.setIsModalOpen}
      title={<TitleComponent />}
      className="max-w-4xl p-3"
    >
      <div className="mb-5">
        <h1 className="text-center text-warning font-semibold text-xl"> 
          {props.licenceKey} 
        </h1>
        <p className="text-center text-sm">Lisesensi Premium Anda</p>
      </div>

      <div className="flex gap-3 items-start">
        <div className="basis-1/2 p-5">
          <ul className="flex flex-col">
            <li className="py-1.5 border-b border-secondary-light">
              {t("pages.donation.modal.section1")}
            </li>
            <li className="py-1.5 border-b border-secondary-light">
              {t("pages.donation.modal.section2")}
            </li>
            <li className="py-1.5 border-b border-secondary-light">
              {t("pages.donation.modal.section3")}
            </li>
            <li className="py-1.5 border-b border-secondary-light">
              {t("pages.donation.modal.section4")}
            </li>
            <li className="py-1.5 border-b border-secondary-light">
              {t("pages.donation.modal.section5")}
            </li>
          </ul>
        </div>
        <div className="basis-1/2 rounded bg-amber-800 p-5">
          <ul className="flex flex-col">
          <li className="py-1.5 border-b border-amber-700 text-center">
              {t("pages.donation.modal.section1_premium")}
            </li>
            <li className="py-1.5 border-b border-amber-700 text-center">
              {t("pages.donation.modal.section2_premium")}
            </li>
            <li className="py-1.5 border-b border-amber-700 text-center">
              {t("pages.donation.modal.section3_premium")}
            </li>
            <li className="py-1.5 border-b border-amber-700 text-center">
              {t("pages.donation.modal.section4_premium")}
            </li>
            <li className="py-1.5 border-b border-amber-700 text-center">
              {t("pages.donation.modal.section5_premium")}
            </li>
          </ul>
        </div>
      </div>
    </DialogWithHeader>
  )
}