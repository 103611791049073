import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";



const requestor = applyCaseMiddleware(axios.create({
  // baseURL: "http://localhost:3006/api/v1"
  baseURL: import.meta.env.VITE_API_HOST + "/api/v1"
}), {
  caseOptions: { stripRegexp: /[^A-Z0-9[\]$]+/gi }
})

let axiosInterceptor: number;

/** 
 * set axios bearer token  
 * @todo there is a bug that occur randomly when user try to login.
 * the token that set in this configuration randomly changes the last signature.
 * so even tough user can login and see the app view, it will not able to do 
 * service interaction.
 * 
 * temporary solution is to try login as much until it can be logged in wihtout error.
 * 
 * 
 * @update actually that bug happened becuase the last token from previous user session still 
 * present in Authorization header when second user session just login.
 * */
export function setRequestToken(token : string){
  axiosInterceptor = requestor.interceptors.request.use(config => {
    const newConfig = {...config}
    newConfig.headers["Authorization"] = `Bearer ${token}`;
    newConfig.headers["ngrok-skip-browser-warning"] = true; // usefull when developing in ngrok or another port forwarding service.

    // i don't know, but apparently i must run console.log to make sure this auth is set.
    // console.log(`Bearer ${token}`);
    return newConfig
  })
}

/**
 * clear token,, solution from bug above
 * use it when your user logout
 */
export function clearReuestToken(){
  requestor.interceptors.request.clear();
  // requestor.interceptors.request.eject(axiosInterceptor);
}

export {requestor}