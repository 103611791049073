import { DialogWithHeader } from "@shared/components/dialog";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { useState } from "react";
import { SelectNoteAndSample } from "../..";
import { useTranslation } from "react-i18next";


/**
 * modal variant for select note and sample
 */
export function SelectNoteAndSampleModal(){
  const [isModalopen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  return(
    <>
      <SolidButton 
        variant="warning"
        className="py-2"
        onClick={()=>setIsModalOpen(true)}
      >
        {t("features.task.select_note_and_sample.select_note_and_sample_modal.title")}
      </SolidButton>
      <DialogWithHeader
        isOpen={isModalopen}
        modalAccess={setIsModalOpen}
        title={
          (<p className="text-center w-full">
            {t("features.task.select_note_and_sample.select_note_and_sample_modal.title_dialog")}
          </p>) as unknown as string // dirty work to make it run
        }
        className="px-1 me-3"
      >
        <div className="p-20 py-28">
          <SelectNoteAndSample />
        </div>
      </DialogWithHeader>
    </>
  )
}