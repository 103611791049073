
// # Here all config about this feature
export const LIMIT_HACKER_NOTE_FILE_SIZE  = 1000_000  as const;   // 1 mb
export const LIMIT_HACKER_NOTE_FILE_COUNT = 5         as const;       // 5 item
export const LIMIT_SAMPLE_FILE_SIZE       = 1000_000  as const;   // 1 mb
export const LIMIT_SAMPLE_FILE_COUNT      = 5         as const;       // 5 item  
export const LIMIT_TOPOLOGY_FILE_SIZE     = 1000_000  as const;   // 1 mb
export const LIMIT_TOPOLOGY_FILE_COUNT    = 5         as const;       // 5 item  

// # Here all config about this feature
export const LIMIT_HACKER_NOTE_FILE_SIZE_PREMIUM  = 4000_000  as const;   // 4 mb
export const LIMIT_HACKER_NOTE_FILE_COUNT_PREMIUM = 10         as const;       // 10 item
export const LIMIT_SAMPLE_FILE_SIZE_PREMIUM       = 4000_000  as const;   // 4 mb
export const LIMIT_SAMPLE_FILE_COUNT_PREMIUM      = 10         as const;       // 10 item  
