import { useSetupUser } from "@features/auth/setup-user";
import { getToken } from "@services/localStorage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";




export function Dummy(){
  const naviagte = useNavigate();
  useSetupUser();
  useEffect(()=>{
    const token = getToken(); 
    if(!token) naviagte("/login");
    else naviagte("/home")
  },[])
  return (
    <div className="h-full w-full bg-secondary-light absolute inset-0"></div>
    // <>
    //   {/* <p className="text-lg">Ini adalah halaman dummy</p>
    //   <CompanyPhotoCarousel /> */}
    // </>
  )
} 