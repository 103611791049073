import clsx from "clsx";




export interface ContentCarouselData {
  url: string | unknown, 
  title: string, 
  description : string
}

interface ContentCarouselProps {
  data : ContentCarouselData;
}

export function ContentCarousel(props: ContentCarouselProps){
  return(
    <div
      className="w-full relative "
      // style={{ backgroundImage: `url(${props.data.url as string})` }}
    >
      <img src={props.data.url as string} width="100%"draggable="false" />
      <div className={clsx("bg-black/75 rounded-b-2xl inset-x-0 bottom-0 p-3 absolute")}>
        <h3 className="text-xl font-semibold text-warning">{props.data.title}</h3>
        <p>{props.data.description}</p>
      </div>
    </div>
  )
}