import MinimizeIcon from "@assets/icon/min.svg?react";
import MaximizeIcon from "@assets/icon/maximize.svg?react";
import UnMaximizeIcon from "@assets/icon/unmaximize.svg?react";
import XIcon from "@assets/icon/x.svg?react";
import logoImg from "@assets/img/logo.png";
import { closeService, isMaximizedService, maximizeService, minimizeService, unMaximizeService } from "@services/electron";
import { useEffect, useState } from "react";



export function WindowFrame(){

  const [isMaximizedFrame, setIsMaximizedFrame] = useState(false);

  useEffect(() => {listenMaximizeState()},[]);
  
  async function listenMaximizeState(){
    const result = isMaximizedService()
    if(result !== null) setIsMaximizedFrame(result);
  }

  async function handleMaximization(){
    if(isMaximizedFrame) {
      unMaximizeService();
      setIsMaximizedFrame(false);
      return;
    }
    
    maximizeService();
    setIsMaximizedFrame(true);
  }

  return (
    <div className="bg-secondary-base/70 text-white relative z-50 flex justify-between items-center ">
      <div className="flex px-2 gap-2">
        <img className="shrink-0" src={logoImg as string} alt="" width={23} height={20} />
        <p className="text-gray-500">Fixransomware</p>
      </div>
      <p className="grow draggable-frame text-gray-500 text-center">v 0.2.0</p>
      <div className="inline-flex">
        <button onClick={minimizeService} className="p-4 hover:bg-secondary-base active:bg-secondary-light ">
          <MinimizeIcon width={15} height={15} />
        </button>
        <button onClick={handleMaximization} className="p-4 hover:bg-secondary-base active:bg-secondary-light">
          {isMaximizedFrame ? (
              <UnMaximizeIcon width={15} height={15} />
          ):(
              <MaximizeIcon width={15} height={15} />
          )}
        </button>
        <button onClick={closeService} className="p-4 bg-red-700 hover:bg-red-600 active:bg-red-500">
          <XIcon width={15} height={15} />
        </button>
      </div>
    </div>
  )
}