


type ListPromiseQueue = {
  promise: () => Promise<unknown>;
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
}[]

/**
 * Simple queue for promise, make serializer for promise.
 * 
 * @example
 * ```
 * let exampleNumber = 0;
 * const exampleQueue = new PromiseQueue();
 * const examplePromise = new Promise<void>((resolve) => {
 *  setTimeout(()=>{
 *    console.log("resolved in " + (exampleNumber++) * 500);
 *    resolve();
 * }, 500);
 * })
 * exampleQueue.enqueue(() => examplePromise);
 * exampleQueue.enqueue(() => examplePromise);
 * ```
 * 
 * @see 
 * https://medium.com/@karenmarkosyan/how-to-manage-promises-into-dynamic-queue-with-vanilla-javascript-9d0d1f8d4df5
 * https://stackoverflow.com/questions/71378776/javascript-async-function-in-order
 */
export class PromiseQueue {
  private queue: ListPromiseQueue = [];
  private pendingPromise: boolean = false;
  private workingOnPromise: boolean = false;
  private stop: boolean = false;

  enqueue(promise: () => Promise<unknown>) {
    return new Promise((resolve, reject) => {
      this.queue.push({promise, resolve,reject,});
      this.dequeue();
    });
  }

  stopQueue(val: boolean){ this.stop = val}

  dequeue() {
    if (this.workingOnPromise)return false;
    if (this.stop) {
      this.queue = [];
      this.stop = false;
      return false;
    }
    const item = this.queue.shift();
    if (!item) return false;
    try {
      this.workingOnPromise = true;
      item.promise()
        .then((value) => {
          this.workingOnPromise = false;
          item.resolve(value);
          this.dequeue();
        })
        .catch(err => {
          this.workingOnPromise = false;
          item.reject(err);
          this.dequeue();
        })
    } catch (err) {
      this.workingOnPromise = false;
      item.reject(err);
      this.dequeue();
    }
    return true;
  }
}


