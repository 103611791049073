import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { selectCurrentStage } from "@entities/task";
import { selectIfOnlyVirusNameThatPresent } from "@entities/virus";
import { PaymentAnalystButton } from "@features/task/analyst-payment";
import { PaymentDecryptButton } from "@features/task/decrypt-payment";
import { CardPreview } from "@shared/components/card";
import { CircleProgress } from "@shared/components/progress";
import { useAppSelector } from "@shared/model/hooks";
import { AnalystProcess } from "@process/task-process/stage/analyst-process";
import { DecryptProcess } from "@process/task-process/stage/decrypt-process/DecryptProcess";
import { activeLocalStagebyStage } from "../lib/utils";
import { LocalCardWrapper } from "../components/LocalCardWrapper";
import { SampleAndHackerNoteProcess } from "@process/task-process/stage/sample-and-hacker-note-process";
import { SelectNoteAndSampleModal } from "@features/task/select-note-and-sample";
import { ProcessVirusAndIndex } from "../stage/indexing-and-virus-process";
import { useTranslation } from "react-i18next";



const status = [
  "indexing_and_virus_upload",
  "hacker_note_and_sample_upload",
  "analyst",
  "decrypt"
] as const;

/**
 * this component is only used for Task page in proigress.
 * the difference is only about sample uploading
 */
export function TaskProcessProgress(){
  const currentStage = useAppSelector(selectCurrentStage);
  const isVirusOnlyName = useAppSelector(selectIfOnlyVirusNameThatPresent);
  const currentPercent = useAppSelector(state => Number(state.currentTask.task.percentage ?? 0));
  const [activeStatus, setActiveStatus] = useState< typeof status[number] | null>(null);

  const {t} = useTranslation();

  // const isAlreadyIndexing = useAppSelector(selectCurrentStageIndexing);
  // const navigate = useNavigate();

  useEffect(()=>{
    const current = activeLocalStagebyStage(currentStage);
    if(!current) return;
    setActiveStatus(current)
  },[currentStage])

  useEffect(() => {
    if(isVirusOnlyName) setActiveStatus("indexing_and_virus_upload");
  }, [isVirusOnlyName])

  return (
    <section className="px-28 py-10">
    <p className="mb-10 text-gray-500">
      {t("process.task_process.task_process.header")}
    </p>
    <div className="flex justify-center items-center">
      <div className="sticky self-start">
        <div className="p-20 xl:p-32 flex flex-col">
          <CircleProgress percentage={currentPercent} />
          {currentStage === "ANALYST_PAYMENT_INIT" && <PaymentAnalystButton />}
          {currentStage === "DECRYPT_PAYMENT_INIT" && <PaymentDecryptButton />}
          {currentStage === "INDEXING" && <SelectNoteAndSampleModal />}
        </div>
      </div>
      {!activeStatus && (
        <div className="flex flex-col gap-2 justify-center w-full">
          <motion.div layoutId="indexing-layout">
            <CardPreview
              onClick={() => setActiveStatus("indexing_and_virus_upload")}
              className="cursor-pointer"
              title={t("process.task_process.task_process.process.indexing_virus_upload.title")}
              status="done"
              statusDescription={t("process.task_process.task_process.process.indexing_virus_upload.description")}
            />
          </motion.div>
          <motion.div layoutId="sample-hacker-layout">
            <CardPreview
              onClick={() => setActiveStatus("hacker_note_and_sample_upload")}
              className="cursor-pointer"
              title={t("process.task_process.task_process.process.sample_and_hacker_note.title")}
              status="done"
              statusDescription={t("process.task_process.task_process.process.sample_and_hacker_note.description")}
            />
          </motion.div>
          <motion.div layoutId="analyst-layout">
            <CardPreview
              onClick={() => setActiveStatus("analyst")}
              className="cursor-pointer"
              title={t("process.task_process.task_process.process.analyst_result.title")}
              status="active"
              statusDescription={t("process.task_process.task_process.process.analyst_result.description")}
            />
          </motion.div>
          <motion.div layoutId="decrypt-layout">
            <CardPreview
              onClick={() => setActiveStatus("decrypt")}
              className="cursor-pointer"
              title={t("process.task_process.task_process.process.decryption.title")}
              status="pending"
              statusDescription={t("process.task_process.task_process.process.decryption.description")}
            />
          </motion.div>
        </div>
      )}
      <AnimatePresence>
      {activeStatus === "indexing_and_virus_upload" && (
        <LocalCardWrapper layoutId="indexing-layout">
          <ProcessVirusAndIndex />
        </LocalCardWrapper>
      )}
      {activeStatus === "hacker_note_and_sample_upload" && (
        <LocalCardWrapper layoutId="sample-hacker-layout">
          <SampleAndHackerNoteProcess />
        </LocalCardWrapper>
      )}
      {activeStatus === "analyst" && (
        <LocalCardWrapper layoutId="analyst-layout">
          <AnalystProcess />
        </LocalCardWrapper>
      )}
      {activeStatus === "decrypt" && (
        <LocalCardWrapper layoutId="decrypt-layout">
          <DecryptProcess />
        </LocalCardWrapper>
      )}
      </AnimatePresence>
    </div>
  </section>
  )
}