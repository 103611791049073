import confirmedSrc from "@assets/ilustration/confirmed.png";
import { useTranslation } from "react-i18next";




export function DecryptPaymentConfirm(){

  const { t } = useTranslation();

  return(
    <div className="p-10">
      <img src={confirmedSrc as string} width={80} height={80} className="mx-auto" />
      <p className="" 
        // there are some html tag in translation
        dangerouslySetInnerHTML={{__html : t("entities.task.stage.decrypt.decrypt_payment_confirm")}}
      >
        {/* Kami mengucapkan terima kasih atas pembayaran Anda untuk layanan decryption/recovery.
        Mohon sampaikan akses AnyDesk Anda beserta kredensial login PC/Server atau Unattended 
        Access AnyDesk melalui chatroom aplikasi kami atau langsung ke WhatsApp kami 
        di <a className="text-primary hover:underline" href="https://wa.me/6285157787595" target="_blank" rel="noopener noreferrer">Link ini</a>. Setelah itu, laporan harian Anda akan 
        dibuat secara otomatis dan siap untuk diakses. Kami siap membantu Anda dengan setiap langkahnya. */}
      </p>
    </div>
  )
}