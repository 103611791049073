import { BulletIndicator } from "@shared/components/indicator";
import clsx from "clsx";
import { ComponentPropsWithoutRef, ReactElement } from "react";


interface CardPreviewProps extends ComponentPropsWithoutRef<"div">{
  statusDescription?: ReactElement | string;
  title: string;
  status?: "active" | "done" | "pending";
}

export function CardPreview({status = "pending", title, statusDescription = "", className,...props} : CardPreviewProps){
  return (
    <div 
      className={clsx(
        className,
        status === "active" && "bg-secondary-dark",
        "p-3 rounded-md gap-5 hover:bg-secondary-dark flex items-center"
      )}
      {...props}
    >
      <div className="grow-0">
        <BulletIndicator variant={status === "active" ? "primary" : status == "done" ? "success" : "base"} />
      </div>
      <div className="grow font-semibold">
        {title}
      </div>
      <div className="text-gray-500">
        {statusDescription}
      </div>
    </div>
  )
}