import { DefaultDropdown } from "@shared/components/dropdown";
import PersonIcon from "@shared/assets/icon/person.svg?react"
import LogoutIcon from "@shared/assets/icon/logout.svg?react";
import { LogoutButtonWrapper } from "@features/auth/logout";


export function AccountAndAuth(){
  return (
    <DefaultDropdown
    referenceSlot={
      <PersonIcon className="text-gray-200 group-hover:text-gray-300 group-active:text-gray-400" width={30} height={30} />
    }
  >
    <ul className="w-44 text-gray-400">
      <li>
        <button className="w-full px-2 py-1 gap-3 inline-flex items-center hover:bg-secondary-base hover:text-white rounded">
          <PersonIcon width={20} height={20} className="text-warning" />
          Account
        </button>
      </li>
      <li>
        <LogoutButtonWrapper>
          <button className="w-full px-2 py-1 gap-3 inline-flex items-center hover:bg-secondary-base hover:text-white rounded">
            <LogoutIcon width={20} height={20} className="text-warning" />
            Log out
          </button>
        </LogoutButtonWrapper>
      </li>
    </ul>
  </DefaultDropdown>
  )
}