import { GoToForgotPasswordLink } from "@features/auth/forgot-password";
import { LoginWithEmailForm } from "@features/auth/login-by-email";
import { LoginByGoogleButton } from "@features/auth/login-by-google";
import { GoToRegisterLink } from "@features/auth/register";
import { ToAnonymousDonationLink } from "@features/donation/anonymous-make-donation";
import { useTranslation } from "react-i18next";


export function Login(){
  const { t } = useTranslation();

  return (
    <>

      <h1 className="text-4xl mb-3">{t("pages.login.title")}</h1>
      <GoToRegisterLink className="mb-3" />
      <LoginWithEmailForm />
      <div className="flex flex-row-reverse mt-4">
        <GoToForgotPasswordLink />
      </div>
      <hr className="my-5 border-white/30" />
      <div className="flex justify-between">
        <LoginByGoogleButton />
        {/* <ToDonationNavigation shape="round" /> */}
        <ToAnonymousDonationLink shape="round" />
      </div>
    </>
  )
}