import { GenerateToastList } from ".";





export function GenerateToasrContent(data: string | string[]){
  if(typeof data === "string") return data;
  if(Array.isArray(data)) return GenerateToastList(data);
  
  return "invalid value";
}