import { captureException } from "@sentry/react";
import { onScanFoundFileService, scanService, selectDirectoryService } from "@services/electron";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { textToPlainFile } from "@shared/utils/file-generator";
import { PromiseQueue } from "@shared/utils/queue";
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";


interface ScanDesktopProps{
  onScanComplete: (file: File) => any;
  virusName: string;
}

const foundPathPromise = new PromiseQueue();

export function ScanDesktop(props: ScanDesktopProps){

  const [selectedDirectory, setSelectedDirectory] = useState("hello semuanya");
  const [foundedPath, setFoundedPath] = useState([""]);
  const wasCalled = useRef(false);
  const { t } = useTranslation();

  async function handleSelectDirectory(){
    try {
      const result = await selectDirectoryService();
      setSelectedDirectory(result?.path);
      // toast.success("berhasil medapatykan path")
      toast.success(t("features.scan.scan_directory.scan_desktop.success_get_path_message"))
      return result.path;
    } catch (error) {
      console.error(error);
      toast.error(t("features.scan.scan_directory.scan_desktop.fail_get_path_message"));
      // toast.error("gagal melakukan scan");
    }
  }

  /**
   * 
   * @param str 
   * @returns 
   * 
   * @see https://unix.stackexchange.com/questions/678439/zsh-case-insensitive-globbing
   */
  function convertToGlobCaseInsensitive(str: string){
    let result = "";

    for(let i = 0; i < str.length; i++){
      result += "[" + str[i].toLowerCase() + str[i].toUpperCase() + "]";
    }

    return result;
  }

  // use when separating start scan and select directory
  // async function handleStartScan(){
  //   startScan(selectedDirectory, props.virusName);
  // }

  async function startScan(directory: string, virusName: string){
    try {

      const globPattern = convertToGlobCaseInsensitive(virusName);
      console.log(globPattern);

      // scan for .txt file in selected directory
      const result = await scanService(directory, globPattern);
      console.log(result);
      const resultFile = textToPlainFile(result.path, "indexing-" + Date.now());
      console.log(resultFile);
      props.onScanComplete(resultFile); // go for next action after complete scanning
    } catch (error) {
      captureException(error);
      console.error(error);
      toast.error("ada maslah dalam melakukan scan");
    }
  }

  async function makeSureOnlyRunOnceEvenOnDevelopment(){

    // guard to make sure below code only run once in mounted component
    if(wasCalled.current) return;
    wasCalled.current = true;

    handleOnPathFound()
    const dir = await handleSelectDirectory();
    if(!selectedDirectory) return;
    console.log("seletced directory : " + selectedDirectory)
    await startScan(dir!, props.virusName);
  }

  useEffect(()=>{ makeSureOnlyRunOnceEvenOnDevelopment() },[]);

  async function scanAndStart(){
    const dir = await handleSelectDirectory();
    if(!selectedDirectory) return;
    console.log("seletced directory : " + selectedDirectory)
    await startScan(dir!, props.virusName);
  }

  function processOnPathFound(pathFound: string){
    foundPathPromise.enqueue(async () => {
      setFoundedPath((prevValue) => ([pathFound,...prevValue,]));
    })
  }

  function handleOnPathFound(){
    onScanFoundFileService((pathFound) => processOnPathFound(pathFound));
  }

  return (
    <div className="flex flex-col h-80 max-h-80">
      <div className="flex flex-col items-center gap-2 ">
        <div className="inline-flex gap-1">
          <SolidButton onClick={scanAndStart}>
            Scan Direktori
          </SolidButton>
          <SolidButton
            variant="warning"
            onClick={()=> {
              setFoundedPath([]);
              setSelectedDirectory("")
            }}
          >
            Clear
          </SolidButton>
        </div>
        <p className="text-gray-500">{selectedDirectory}</p>
      </div>
      <div className="h-full max-h-full overflow-auto">
        <ul className="flex flex-col">
          {foundedPath.map((str, index)=> (
            <li key={index}>{str}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}