import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTaskByIdService } from "@services/www.fixransomware.com";






export const changeCurrentTask = createAsyncThunk(
  "currentTask/changeCurrent",
  async (payload : string, ) => {

    const result = await getTaskByIdService({id: payload});

    return result.data;
  }
)