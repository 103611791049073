import { requestor } from "./requestor";





/**
 * important, this action will only optimal when diownloading small file,
 * if the file is too large, it is recommended to to use header 'Content-Disposition: attachment;' 
 * so the browser will treat it as a native download (aforementioned download progress + direct download to disk). 
 */
export async function getAnalystInvoiceService(payload: {id: string}): Promise<Blob>{
  const result = await requestor.get("task/" + payload.id + "/analyst-invoice", {responseType: "blob"});
  return result.data;
}

// return fule url in browser
/**
 * important, this action will only optimal when diownloading small file,
 * if the file is large, it is recommended to to use header 'Content-Disposition: attachment;' 
 * so the browser will treat it as a native download (aforementioned download progress + direct download to disk). 
 */
export async function getDecryptInvoiceService(payload: {id: string}): Promise<Blob>{
  const result = await requestor.get("task/" + payload.id + "/decrypt-invoice",{responseType: "blob"});
  return result.data
}

