import { OutlineButton, TextInput } from "@shared/components/form";
import { useLoginByEmailHook } from "../model/hook";
import { useTranslation } from "react-i18next";



export function LoginWithEmailForm(){
  const {handleOnSubmit, handleFormChange, loginForm} = useLoginByEmailHook();
  const { t } = useTranslation();

  return (
    <>
      <form onSubmit={handleOnSubmit}>
        <TextInput 
          value={loginForm.email}
          onChange={handleFormChange("email")}
          placeholder={t("features.auth.login_by_email.form.email")} 
          name="email" 
          className="mb-2" 
        />
        <TextInput 
          value={loginForm.password}
          onChange={handleFormChange("password")}
          placeholder={t("features.auth.login_by_email.form.password")}  
          type="password" 
          name="password" 
          className="mb-2" 
        />
        <div className="flex justify-between">
          <div className="inline-flex gap-3 items-center">
            <input id="remember-me" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="remember-me">Remember me</label>
          </div>
          <OutlineButton>{t("features.auth.login_by_email.form.submit_button")} </OutlineButton>
        </div>
      </form>
    </>
  )
}