import { Task } from "@entities/task/model";
import { DialogWithHeader } from "@shared/components/dialog";
import { ComponentPropsWithoutRef, ReactElement, useState } from "react";
import qrisImg from "@assets/img/qris.png";
import { SolidButton } from "@shared/components/form/button/SolidButton";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OutlineButton } from "@shared/components/form";
import { Timer } from "@shared/components/timer";
import { PaymentDecryptTransferData } from "../model";
import { getDecryptInvoiceService } from "@services/www.fixransomware.com";
import { toast } from "react-toastify";
import clsx from "clsx";
import { FormatIDR, calculateGrandTotal } from "@shared/utils";
import { CommonPayment } from "@shared/components/payment";

interface PaymentDecryptModalProps extends Omit<ComponentPropsWithoutRef<typeof DialogWithHeader>, "title"> {
  task: Task;
  children?: ReactElement;
  getFileModalOpen: () => any;
  setTransferData: (arg: PaymentDecryptTransferData) => any;
}



export function PaymentDecryptModal({task, getFileModalOpen, /* children, */ setTransferData, ...props}: PaymentDecryptModalProps){
  const [paymentMethod, setPaymentMethod] = useState<"BNI" | "QRIS" | "PAYPAL">("BNI");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  function hadleClickUploadFile(){
    setTransferData({paymentMethod});
    getFileModalOpen();
  }

  // get-invoice-decrypt
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function hadleDownloadInvoice(){
    setIsLoading(true);
    try {
      // create file link in browser's memory
      const blob = await getDecryptInvoiceService({ id: task.id ?? ""});
      const href = URL.createObjectURL(blob);
      console.log(href);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'Invoice Decrypt file Fixransomware - Tanpa PPN.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.log(error);
      toast.error("ada masalah dalam mengunduh data");
    
    }finally{
      setIsLoading(false);
    }
  }

  return (
  <>
    <DialogWithHeader
      className="basis-1/3"
      title="Pembayaran Analisis"
      {...props}
    >
      <CommonPayment 
        amount={task.decryptNominal!}
        deadline={new Date(task.decryptPaymentDeadline!)}
        discount={task.decryptDiscount!}
        currency={task.decryptCurrency as "IDR" | "USD"}
        invoice={task.id!}
        paymentMethod={paymentMethod}
        status={task.status!}
        onPaymentMethodChange={(commonPayment) => setPaymentMethod(commonPayment)}
        total={calculateGrandTotal(task.decryptNominal!, task.decryptDiscount!)}
        buttonSlot={
          <>
          <SolidButton onClick={hadleClickUploadFile} className="basis-1/2">
            Upload Proof
          </SolidButton>
          <a
            className={clsx(
              "hover:bg-white hover:text-primary",
              "px-12 py-1 rounded border-2 border-white transition",
              "basis-1/2"
            )} 
            href="https://wa.me/6285157787595"
            >
            Download Invoice
          </a>
          </>
        }
      />
    </DialogWithHeader>
  </>
  )
}
