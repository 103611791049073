import clsx from "clsx";
import styleClass from "./ScanButton.module.css"
import { ComponentPropsWithoutRef } from "react";



interface ScanButtonProps extends ComponentPropsWithoutRef<"button">{
  isDisable? : boolean;
}

export function ScanButton({className, /* children,  */isDisable, onClick, ...props} : ScanButtonProps){
  return (
    <div 
      className={clsx(
        "relative p-1 group",
        !isDisable && styleClass["parent-border"],
        className
      )}
    >
      <div className={clsx(
        "rounded-full absolute inset-0",
        !isDisable && styleClass["fake-border-gradient"],
        )} 
      > 
      </div>

      <button 
        className={clsx(
          "p-28 xl:p-36 shadow-md flex flex-col  rounded-full text-5xl font-semibold  transition relative",
          !isDisable ? "bg-primary text-white/50" : "bg-gray-600/70 text-gray-200 cursor-not-allowed",
          !isDisable && "hover:shadow-none hover:bg-warning hover:text-white",
          !isDisable && "active:bg-yellow-600"
        )}
        disabled={isDisable}
        onClick={isDisable ? ()=>{} : onClick}
        {...props}
      >
        <span className="absolute top-0 left-0 text-2xl text-warning">
          1
        </span>
        <div className="absolute flex flex-col -translate-x-1/2 -translate-y-1/2">
          <span>
            Scan
          </span>
          <span>
          Now
          </span>
        </div>
      </button>
      
    </div>
  )
}