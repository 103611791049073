import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";





export function GoToForgotPasswordLink(){
  const { t } = useTranslation();

  return (
    <p>
      {t("features.auth.forgot_password.forgot_passowrd_navigation.notice")}
      <Link to={"/change-password"} className="font-semibold ml-1 hover:underline" >
        {t("features.auth.forgot_password.forgot_passowrd_navigation.link_name")}
      </Link>
    </p>
  )
}