
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import * as idLang from "./lang-importer/id";
import * as enLang from "./lang-importer/en";

// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init


// load translation
// TODO: creat appropriate loader
function loadTranslation(locale : "en" | "id"){

  const { 
    entitiesTaskStage,
    featureAuthForgotPassword,
    featureAuthLoginByEmail,
    featureAuthRegister,
    featureDonationMakeDonation,
    featureAnonymousDonationMakeDonation,
    featureScanScanDirectory,
    featureTaskAnalystPayment,
    featureTaskDecryptPayment,
    featureTaskSelectNoteAndSample,
    featureVirusCheckOrCreateIfEMpty,
    featureVirusSelectVirus,

    pageDonation,
    pageAnonymousDonation,
    pageProgress,
    pageLogin,
    pageRegister,
    pageSuccessCreateAccount,

    taskProcess,

    widgetCompanyPhotoProfile,
    widgetFreshCreateTask,
    widgetLoginContent,
    widgetRegisterContent,
    widgetScreenWidthLimiter,
    commonPayment
  } = locale === "id" 
    ? idLang
    : enLang

  const translation = {
    common: {
      common_payment: commonPayment
    },
    entities : {
      task : {
        stage : entitiesTaskStage
      }
    },

    features : {
      auth: {
        forgot_password : featureAuthForgotPassword,
        login_by_email : featureAuthLoginByEmail,
        // logout : featureAuthlogout,
        register : featureAuthRegister
      },

      donation : {
        make_donation : featureDonationMakeDonation,
        make_anonymous_donation: featureAnonymousDonationMakeDonation,
      },

      scan : {
        scan_directory : featureScanScanDirectory,
      },

      task : {
        analyst_payment : featureTaskAnalystPayment,
        decrypt_payment : featureTaskDecryptPayment,
        // new_task : featureTaskNewTask,
        // process_virus_index : featureTaskProcessVirusIndex,
        select_note_and_sample : featureTaskSelectNoteAndSample
      },

      virus : {
        check_or_create_if_empty : featureVirusCheckOrCreateIfEMpty,
        select_virus : featureVirusSelectVirus
      },
    },

    process: {
      task_process: taskProcess
    },

    pages: {
      donation : pageDonation,
      anonymous_donation : pageAnonymousDonation,
      progress: pageProgress,
      login: pageLogin,
      register: pageRegister,
      success_create_account : pageSuccessCreateAccount
    },

    widgets : {
      company_photo_carousel : widgetCompanyPhotoProfile,
      fresh_create_task : widgetFreshCreateTask,
      login_content : widgetLoginContent,
      register_content : widgetRegisterContent,
      screen_width_limiter : widgetScreenWidthLimiter
    }
  } as const;

  return translation;
}


function i18nWrapper(){
  return i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      supportedLngs: ['id', 'en'],
      fallbackLng: 'id',
      lng: "id",
      debug: true,
      detection : {
        order: ['htmlTag', 'cookie', 'localStorage', 'path', 'subdomain'],
        caches: ['cookie']
      },
      react : {useSuspense : false},
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources : {
        en : {translation : loadTranslation("en")},
        id : {translation : loadTranslation("id")}
      }
    });
}


export default i18nWrapper();