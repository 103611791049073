import { useEffect, useRef, /* useState */ } from "react";
import circleProgressStyle from "./CircleProgress.module.css";
import clsx from "clsx";

interface CircleProgressProps {
  percentage: number;
}



export function CircleProgress(props: CircleProgressProps){

  const progressElement = useRef<SVGCircleElement>(null);
  // const [currentCircumference, setCurrentCircumference] = useState(0);

  function setupCircleProgress(){
    if(!progressElement.current) return;

    const progressCircle = progressElement.current!;
    const radius = progressCircle.r.baseVal.value;
    //circumference of a circle = 2πr;
    const circumference = radius * 2 * Math.PI;
    progressCircle.style.strokeDasharray = String(circumference);
    // setCurrentCircumference(circumference);

    progressCircle.style.strokeDashoffset = String(circumference - (props.percentage / 100) * circumference);
  }

  useEffect(()=>{setupCircleProgress()},[props.percentage]);

  return (
    <div className={("relative p-1 group")}>

      <div className={("p-28 shadow-md inline-flex flex-col bg-secondary-light/50 rounded-full text-5xl font-semibold text-white transition relative")}
      >
        <div className="absolute -translate-x-1/2 -translate-y-1/2">
          <p className="whitespace-nowrap">{props.percentage} %</p>
        </div>
      </div>
      <div className={(" absolute inset-0 flex justify-center")} > 
        <svg width="230" height="230">
          <circle r="100" cx={"50%"} cy={"50%"} className={circleProgressStyle["track"]}></circle>
          <circle 
            r="100" cx={"50%"} cy={"50%"} ref={progressElement}
            className={clsx(circleProgressStyle["progress"], "animate-pulse transition-all")}
          />
        </svg>
      </div>
    </div>
  )
}