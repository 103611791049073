import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DonationBenefitModal } from "./DonationBenefitModal";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { MakeAnonymousDonationWrapper } from "@features/donation/anonymous-make-donation/ui/MakeAnonymousDonationWrapper";
import { GoToLoginEmailLink } from "@features/auth/login-by-email";



export function AnonymousDonation(){
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {t} = useTranslation();

  return (
    <>
      <div className="mt-20">
        {/* title and description */}
        <div className="mb-10">
          <h1 className="text-2xl mb-2 font-bold">
            {t("pages.donation.main_title")}
          </h1>
          <p className="mb-2">
            {t("pages.donation.p_section_1")}
          </p>
          <p className="mb-2">
            {t("pages.donation.p_section_2")}
          </p>
        </div>

        <GoToLoginEmailLink className="mb-5" />

        <div className="flex justify-between gap-2">
          {/* donate button, it will show upload file input */}
          <MakeAnonymousDonationWrapper>
            <SolidButton variant="warning">
              {t("pages.donation.donate_button")}
            </SolidButton>
          </MakeAnonymousDonationWrapper>


          {/* show donation benefit */}
          <SolidButton
              variant="success"
              onClick={()=> setIsModalOpen(true)}
            >
            {t("pages.donation.open_modal_button")}
          </SolidButton>
        </div>
      </div>
      <DonationBenefitModal isOpen={isModalOpen} modalAccess={setIsModalOpen} />
    </>
  )
}