import { ComponentProps } from "react";
import ReactSelect from "react-select";


interface BaseGroupSelect {
  label: string;
  value: string;
  
}

interface SelectDarkProps1<TOption, TData extends TOption[] = TOption[]> {
  options: TData;
  defaultValue : TOption;
  onChange : (...args:any[]) => any;
}

interface SelectDarkProps extends ComponentProps<typeof ReactSelect>{

}


export function SelectDark({formatOptionLabel, isSearchable=false, styles ,...props} : SelectDarkProps){

  return (
    <ReactSelect
    {...props}
    // -- style scope
    styles={{
      control: base => ({
        ...base,
        display: "flex",
        height: "100%",
        borderBottom: "4px solid #FFA50D",
        // backgroundColor: "#3a3939",
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        color: "white",
      })
    }}
    isSearchable={false}
    formatOptionLabel={item => (
      <div className="h-full text-black ">
        <span>{(item as {label : string}).label}</span>
      </div>
    )}
  />
  )
}