import { useEffect, useRef } from 'react';
 
/**
 * a better way to do interval in react.
 * see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param callback call back to call every delay
 * @param delay delay value in milisecond
 */
export function useInterval(callback: (...args: any[]) => any, delay: number | null) {
  const savedCallback = useRef<(...args: any[]) => any>();
 
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
 
  // Set up the interval.
  useEffect(() => {
    function tick() {
      if(!savedCallback.current) return;

      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}