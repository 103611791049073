
import { useAppDispatch, useAppSelector } from "@shared/model/hooks";
import { ScanDesktop, ScanProcess } from "@features/scan/scan-directory";
import { processVirusAndIndex } from "@features/task/process-virus-and-index";
import { isInElectron } from "@services/electron";
import { selectCurrentVirus } from "@entities/virus";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { captureException } from "@sentry/react";


/**
 * see: https://stackoverflow.com/questions/70602604/how-to-stop-setinterval-inside-useeffect-hook-when-certain-value-is-reached-re
 * for how interval cleared
 * 
 */
export function IndexingView(){
  const virusName = useAppSelector(selectCurrentVirus).virusName
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  async function handleUploadVirus(){
    try {
      /* const result = */ await dispatch(processVirusAndIndex({})).unwrap();
    } catch (error) {
      // prevent percentage error <-----
      captureException(error);
      console.error(error);
      toast.error(t("process.task_process.indexing_virus_process.failed_create_task"))
    }
  }

  async function handleUploadVirusAndIndexingFile(indexingResultFile: File){
    try {
      console.log(indexingResultFile);
      console.log(indexingResultFile.text())
      const result = await dispatch(processVirusAndIndex({indexingFile: indexingResultFile})).unwrap();
      console.log(result);
    } catch (error) {
      captureException(error);
      console.error(error);
      toast.error(t("process.task_process.indexing_virus_process.failed_create_task"))
    }
  }

  if(isInElectron()) return (
    <ScanDesktop 
      onScanComplete={handleUploadVirusAndIndexingFile} 
      virusName={virusName}  
    />);
  
  return (
    <ScanProcess 
      onScanComplete={handleUploadVirus} 
    />
  );
}