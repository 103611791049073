import clsx from "clsx";
import UploadFileIcon from "./assets/upload-file.svg?react";
import { useDropzone, FileWithPath, FileRejection } from "react-dropzone";
import { FileListElement } from "./FileListElement";
import { ComponentPropsWithoutRef, ReactElement, useCallback, useState } from "react";
import { removeFileByIndex } from "./utils/fileUtils";


interface DefaultDropZoneProps extends ComponentPropsWithoutRef<"div">{
  filesValue: FileWithPath[];
  setFilesValue: React.Dispatch<React.SetStateAction<FileWithPath[]>>;
  whenIdleText: ReactElement | string;
  whenDragText: ReactElement | string;
  maxSize: number;
  maxCount: number;
}

function generateExceeedFile(files: File[]){
  const rejectedFiles: FileRejection[] = files.map(item => ({
    file: item,
    errors: [{code: "too-much", message: "toomuch File"}]
  }));

  return rejectedFiles;
}


export function DefaultDropzone({filesValue, setFilesValue, whenIdleText, whenDragText, ...props}: DefaultDropZoneProps){

  const [rejectedFilesContainer, setRejectedFilesContainer] = useState<FileRejection[]>([]);

  const onDrop = useCallback((acceptedFiles: FileWithPath[], rejectedFiles: FileRejection[])=> {

    // when file is more than the max size (including the the count of already accepted file before)
    if(acceptedFiles?.length + filesValue?.length > props.maxCount){
      setRejectedFilesContainer([...rejectedFiles, ...rejectedFilesContainer, ...generateExceeedFile(acceptedFiles)]);
    }else{
      setFilesValue([...filesValue, ...acceptedFiles]);
      setRejectedFilesContainer([...rejectedFilesContainer,...rejectedFiles ]);
    }

  }, [filesValue])

  // # here the react dropzone thing
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop, 
    maxFiles: props.maxCount,
    maxSize: props.maxSize
  });


  function removeFile(index: number){
    console.log("menghapus file index : " + index)
    setFilesValue(removeFileByIndex(index, filesValue));
  }

  function removeFileRejected(index: number){
    console.log("menghapus rejected file index : " + index)
    setRejectedFilesContainer(removeFileByIndex(index, rejectedFilesContainer));
  }

  return (
    <div className="max-h-[500px] overflow-auto">
      <div 
        {...getRootProps()}
        className={clsx(
          "bg-secondary-base border cursor-pointer border-secondary-light/60 border-dashed rounded mb-3 p-8 text-gray-400",
          "hover:border-gray-600"
        )}
      >
        <input {...getInputProps()} />
        <UploadFileIcon width={40} widths={40} className="mx-auto mb-2 text-secondary-light" />
        <p className="text-gray-500 text-center font-light">
          {!isDragActive ? whenIdleText : whenDragText}
        </p>
        {props.children}
      </div>

      {/* accepted file list */}
      <ul className="flex flex-col gap-2 mb-2">
        {filesValue.map((file : FileWithPath, index) => (
          <FileListElement key={file.name} file={file} onDelete={() => removeFile(index)} />
        ))}
      </ul>

      {/* rejected file list */}
      <ul className="flex flex-col gap-2">
        {rejectedFilesContainer.map((file : FileRejection, index) => (
          <FileListElement 
            key={file.file.name}
            status="rejected"
            file={file.file} 
            errorMessage={file.errors[0].message} 
            onDelete={() => removeFileRejected(index)} 
          />
        ))}
      </ul>

    </div>
  )
}

