import IlImageError from "@assets/ilustration/il-img-error.png"
import MonitorIllustration from "@assets/ilustration/il-computer.png"
import MicrosoftIcon from "@assets/icon/microsoft.png"
import { useTranslation } from "react-i18next";
const baseUrl = import.meta.env.VITE_API_HOST;



export function ScreenWithLimiter(){

  const { t } = useTranslation();

  return (
    <div className="warning">
      <div className="flex flex-col text-white bg-secondary-base absolute z-30 h-full inset-0 lg:hidden items-center justify-center">
        <img src={IlImageError as string} width="170" alt="" />
        {/* <a className="underline text-blue-400 mb-6" href={baseUrl + "/download-client-app"} target="_blank">Download Windows Client</a> */}
        <div className="container mx-auto px-10 sm:px-20 lg:px-32 mb-4">
          <h3 className="text-gray-500">{t("widgets.screen_width_limiter.head_message")}</h3>
          <p className="max-w-2xl  text-gray-500 mb-4">
            {t("widgets.screen_width_limiter.message")}
          </p>
          <div className="flex flex-col gap-2 justify-items-start">
            <button className="inline-flex gap-2 p-2 bg-secondary-dark hover:bg-secondary-base rounded items-center">
              <img className="shrink-0 ms-2" src={MicrosoftIcon as string} width={30} height={30} />
              <div className="text-start me-10">
                <p className="text-sm font-normal leading-none">Mengunduh aplikasi windows</p>
                <h5 className="font-medium">Windows Client</h5>
              </div>
            </ button>
            <button className="inline-flex gap-2 p-2 bg-secondary-dark hover:bg-secondary-base rounded items-center">
              <img className="shrink-0 ms-2" src={MonitorIllustration as string} width={30} height={30} />
              <div className="text-start me-10">
                <p className="text-sm font-normal leading-none">Akses web dengan full-screen browser atau layar minimal 995 px</p>
                <h5 className="font-medium">Full Screen</h5>
              </div>
            </ button>

          </div>
          {/* <ul className=" list-disc text-sm">
            <li>
              {t("widgets.screen_width_limiter.option1")}
            </li>
            <li>
              {t("widgets.screen_width_limiter.option2")}
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  )
}