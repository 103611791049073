import { useAppDispatch } from "@shared/model/hooks";
import { DUMMY_ACTION_LIST } from "../model";




export function ChangeStateDropdown() {

  const dispatch = useAppDispatch();

    // uncomment this when debugging
  function handleOptionChange(e: React.ChangeEvent<HTMLSelectElement>){
    dispatch(DUMMY_ACTION_LIST[e.currentTarget.value as keyof typeof DUMMY_ACTION_LIST]());
  }

  return (
    <select onChange={handleOptionChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" id="">
    {Object.keys(DUMMY_ACTION_LIST).map(action => (
      <option key={action} value={action}>{action}</option>
    ))}
  </select>
  )
}