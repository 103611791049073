import { OutlineButton, TextInput } from "@shared/components/form";
import { useForgotPasswordHook } from "..";
import { useTranslation } from "react-i18next";




export function ForgotPasswordForm(){
  const {handleFormChange, handleOnSubmit, forgotPasswordForm} = useForgotPasswordHook();
  const { t } = useTranslation();

  return (
    <>
      <form onSubmit={handleOnSubmit}>
        <TextInput 
          value={forgotPasswordForm.email}
          onChange={handleFormChange("email")}
          name="forgot-password" 
          placeholder={t("features.auth.forgot_password.form.email")} 
          className="mb-2" 
        />
        <div className="flex flex-row-reverse">
          <OutlineButton>{t("features.auth.forgot_password.form.submit_button")}</OutlineButton>
        </div>
      </form>
    </>
  )
}