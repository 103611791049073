
interface Payload {
  email: string;
  password: string;
}

export function storeClient(payload: Payload){
  sessionStorage.setItem("newlyRegisteredClient", JSON.stringify(payload));
} 

export function removeClient(){
  sessionStorage.removeItem("newlyRegisteredClient");
}


export function getStoredClient(): Payload | null {
  const resultString = sessionStorage.getItem("newlyRegisteredClient");
  if(!resultString) return null;

  const result : Payload = JSON.parse(resultString);
  return result;
}