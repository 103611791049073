




export function getToken(){
  const token = localStorage.getItem("jwtToken");
  return token;
}
export function removeToken(){
  localStorage.removeItem("jwtToken");
}
export function setToken(token: string){
  localStorage.setItem("jwtToken", token);
}