import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FulfilledAction, PendingAction, RejectedAction, User, UserSliceState } from ".";







const initialState : UserSliceState = {
  error: "",
  loading: false,
  currentUser: null,
  checkUp: false
}


export const currentUserSlice = createSlice({
  initialState,
  name: "currentUser",
  reducers: {
    clearUser: state => {state.currentUser = null},
    setUser: (state, action :PayloadAction<User>)=>{
      state.currentUser = action.payload;
    },
    setOnCheckUp: state => {state.checkUp = true},
    setOffCheckUp: state => {state.checkUp = false}
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is RejectedAction => action.type.endsWith("/rejected") && action.type.startsWith("currentUser"),
      (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.error;
      }
    );
    builder.addMatcher(
      (action): action is PendingAction => action.type.endsWith("/pending") && action.type.startsWith("currentUser"),
      (state) => {
        state.loading = true;
      }
    )
    builder.addMatcher(
      (action): action is FulfilledAction => action.type.endsWith("/fulfilled") && action.type.startsWith("currentUser"),
      (state, action) => {
        state.loading = false;
        state.currentUser = action.payload
      }
    )
  }
})

export const selectUserDefined = (state: RootState) => state.currentUser.currentUser == null;
export const selectUser = (state: RootState) => state.currentUser.currentUser;
export const selectUserCheckUp = (state: RootState) => state.currentUser.checkUp;

export const {clearUser, setUser, setOffCheckUp, setOnCheckUp} = currentUserSlice.actions