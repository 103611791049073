import { GoToLoginEmailLink } from "@features/auth/login-by-email";
import { LoginByGoogleButton } from "@features/auth/login-by-google";
import { RegisterForm } from "@features/auth/register";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";




export function Register(){
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  return (
    <>
      <h1 className="text-4xl mb-3">{t("pages.register.title")}</h1>
      <GoToLoginEmailLink className="mb-5" />
      <RegisterForm  referralCode={searchParams.get("referral_code") ?? undefined} />
      <hr className="my-5 border-white/30" />
      <LoginByGoogleButton />
    </>
  )
}