import { createAsyncThunk } from "@reduxjs/toolkit";
import { DonationMakePayload } from "./type";
import { User } from "@entities/user";
import { donationMakeService } from "@services/www.fixransomware.com";






export const makeDonation = createAsyncThunk(
  "donation/make",
  async (payload: DonationMakePayload, {getState}) => {
    const {currentUser} = getState() as {currentUser: {currentUser: User}};

    if(currentUser?.currentUser === null) throw "User tidak Dikenali";

    console.log(currentUser);
    
    const result = await donationMakeService({...payload, userId : currentUser.currentUser.id});

    return result
  }
)