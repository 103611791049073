import { FileRejection, FileWithPath } from "react-dropzone";





export function removeFileByIndex<T extends FileWithPath[] | FileRejection[]>(indexFile: number, files: T){
  const newFile = [...files];
  newFile.splice(indexFile, 1);
  console.log(newFile)
  return newFile;
}



// export function setMaxSizeValidation(maxSize: number){

// }