import { createSelector } from "@reduxjs/toolkit";
import { TASK_STAGE } from "./stage";


export const selectTask = (state: RootState) => state.currentTask.task;

export const selectIfTaskEMpty = 
  createSelector(selectTask, task => TASK_STAGE.empty.selector(task));

export const selectCurrentStageIndexing = 
  createSelector(selectTask, task => TASK_STAGE.indexing.selector(task));

export const selectCurrentStageSampleAndHacker = 
  createSelector(selectTask, task => TASK_STAGE.sampleAndHacker.selector(task))

export const selectCurrentStageAnalystPaymentInit = 
  createSelector(selectTask, task => TASK_STAGE.analystPaymentInit.selector(task))


export const selectCurrentStageAnalystPaymentAction = 
  createSelector(selectTask, task => TASK_STAGE.analystPaymetAction.selector(task));

export const selectCurrentStageAnalystPaymentConfirm = 
  createSelector(selectTask, task => TASK_STAGE.analystPaymentConfirm.selector(task));

export const selectCurrentStageAnalystPResult = 
  createSelector(selectTask, task => TASK_STAGE.analystResult.selector(task));

export const selectCurrentStageDecryptPaymentInit = 
  createSelector(selectTask, task => TASK_STAGE.decryptPaymentInit.selector(task));


export const selectCurrentStageDecryptPaymentAction = 
  createSelector( selectTask, task => TASK_STAGE.decryptPaymentAction.selector(task))


export const selectCurrentStageDecryptPaymentConfirm = 
  createSelector(selectTask, task => TASK_STAGE.decryptPaymentConfirm.selector(task))


export const selectCurrentStageDecryptEstimation = 
  createSelector(selectTask, task => TASK_STAGE.decryptEstimation.selector(task))


export const selectCurrentStageDecryptResult = 
  createSelector(selectTask, task => TASK_STAGE.decryptResult.selector(task));

// export function selectTaskCheckup(state: RootState){
//   return state.currentTask.checkUp;
// }
export const selectTaskCheckup = (state: RootState) => state.currentTask.checkUp;

export const selectCurrentStage = createSelector(selectTask, task => {
  // console.log(task);
  if(!task) return TASK_STAGE.empty.name;
  if(TASK_STAGE.decryptResult.selector(task))               return TASK_STAGE.decryptResult.name;
  else if(TASK_STAGE.decryptEstimation.selector(task))      return TASK_STAGE.decryptEstimation.name;
  else if(TASK_STAGE.decryptPaymentConfirm.selector(task))  return TASK_STAGE.decryptPaymentConfirm.name;
  else if(TASK_STAGE.decryptPaymentAction.selector(task))   return TASK_STAGE.decryptPaymentAction.name;
  else if(TASK_STAGE.decryptPaymentInit.selector(task))     return TASK_STAGE.decryptPaymentInit.name;
  else if(TASK_STAGE.analystResult.selector(task))          return TASK_STAGE.analystResult.name;
  else if(TASK_STAGE.analystPaymentConfirm.selector(task))  return TASK_STAGE.analystPaymentConfirm.name;
  else if(TASK_STAGE.analystPaymetAction.selector(task))    return TASK_STAGE.analystPaymetAction.name;
  else if(TASK_STAGE.analystPaymentInit.selector(task))     return TASK_STAGE.analystPaymentInit.name;
  else if(TASK_STAGE.sampleAndHacker.selector(task))        return TASK_STAGE.sampleAndHacker.name;
  else if(TASK_STAGE.indexing.selector(task))               return TASK_STAGE.indexing.name;
  else if(TASK_STAGE.empty.selector(task))                  return TASK_STAGE.empty.name;
  else return "NAME_NOT_FOUND"
})