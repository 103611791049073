import { PageWrapper } from "@shared/components/wrapper";
import { useLocation } from "react-router-dom";
import { AnimatedOutlet } from "./AnimatedOutlet";

/**
 * 
 * @see https://stackoverflow.com/questions/74190609/exit-animations-with-animatepresence-framer-motion-and-createbrowserrouter-r
 * this wild hack only work when the route element didn't have many nesting element.
 * if this hack still used in that case, the parent will also rerender when in corect way only children that should be affected
 * with roiute changes
 */
export function PageWrapperRoute(){
  const location = useLocation();

  return (
    <PageWrapper key={location.pathname}>
      <AnimatedOutlet />
    </PageWrapper>
  )
}