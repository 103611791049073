import { Donation } from "@entities/donation/model/type";
import { requestor } from "./requestor";



type DonationInitField = {
  userId          : string
  paymentMethod   : string
  nominal         : string
  donationProof   : File 
  note?           : string
}
type DonationMakePayload = DonationInitField; // how to make type check for data?
export async function donationMakeService(payload: DonationMakePayload): Promise<Donation>{
  const formData = new FormData();
  formData.append("userId", payload.userId);
  formData.append("paymentMethod", payload.paymentMethod);
  formData.append("nominal", payload.nominal);
  formData.append("donationProof", payload.donationProof);
  
  return (await requestor.post("/donation/", formData)).data;
}


type AnonymousDonationInitField = {
  paymentMethod   : string
  nominal         : string
  donationProof   : File 
  note?           : string
  email           : string;
}
type AnonymousDonationMakePayload = AnonymousDonationInitField; // how to make type check for data?
export async function anonymousDonationMakeService(payload: AnonymousDonationMakePayload): Promise<Donation>{
  const formData = new FormData();
  formData.append("email", payload.email);
  formData.append("paymentMethod", payload.paymentMethod);
  formData.append("nominal", payload.nominal);
  formData.append("donationProof", payload.donationProof);
  
  return (await requestor.post("/donation/anonymous", formData)).data;
}





export async function findOneNewestAcceptedDonation(){
  const result = (await requestor.get("/donation/me/accepted?")).data;
  return result.data
}