import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { FocusEvent, MouseEvent, ReactElement, useState } from "react";
import { usePopper } from "react-popper";


interface DefaultDropdownProps {
  referenceSlot : ReactElement;
  children: ReactElement;
  className?: string;
}


const contentVariant = {
  hide: {
    opacity: 0,
    scale: 0.8,
    x: 10,
    y: -10,
    transition : {
      duration: 0.05
    }
  },
  visible: {
    opacity: 1,
    scale: 1,
    x: 0,
    y: 0,
    transition : {
      duration: 0.05
    }
  }
}

export function DefaultDropdown(props: DefaultDropdownProps){
  const [isOpen, setOpen] = useState<boolean>(false);
  const toggleHoverMenu = (e: MouseEvent) => {
    e.preventDefault();
    // e.stopPropagation()
    setOpen(!isOpen);
  };

  // function closeDropdown(event: MouseEvent){
  //   if(!event.currentTarget) return;
  //   if (!event?.currentTarget.contains(event.relatedTarget)) {
  //     setState('hidden');  
  //   }
  // }

  // # WARNING :: popper setup #
  // popper intentionaly access dom directly to make sure the element is up to date (yet, i'am not sure what i mean)
  // see : https://popper.js.org/react-popper/v2/
  // example how it fail : https://react.dev/learn/manipulating-the-dom-with-refs#best-practices-for-dom-manipulation-with-refs
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement : "bottom-end",
    strategy: 'absolute',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 7],
        },
      },
    ]
  });

  /**
   * 
   * @see https://stackoverflow.com/questions/12092261/prevent-firing-the-blur-event-if-any-one-of-its-children-receives-focus
   */
  function handleOnBlur(event: FocusEvent){
    if (!event.currentTarget.contains(event.relatedTarget as Node)){
      setOpen(false)
    }

  }

  return(
  <div onBlur={handleOnBlur}>
    <button 
      ref={setReferenceElement}
      onClick={toggleHoverMenu}
    >
      {props.referenceSlot}
    </button>
      <AnimatePresence>
        {isOpen && (
          <div 
            ref={setPopperElement} 
            style={styles.popper} 
            {...attributes.popper}
          >
            <motion.div
              className={clsx(
                "p-1 bg-secondary-light shadow rounded",
                props.className
              )}
              variants={contentVariant}
              initial="hide"
              animate="visible"
              exit="hide"
            >
              {props.children}
            </motion.div>
          </div>
        )}
      </AnimatePresence>
  </div>
  )
}