
import Lottie from "lottie-react";
import congrats from "@assets/animation/congrate.json";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { DefaultTooltip } from "@shared/components/tooltip";
import LockIcon from "@shared/assets/icon/lock.svg?react";
import { Task } from "@entities/task";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";

const baseUrl = import.meta.env.VITE_API_HOST;

interface AnalystPaymentInit {
  task: Task;
  analystPreviewSlot: ReactNode;
}
export function AnalystPaymentInit( {task, analystPreviewSlot} : AnalystPaymentInit ){

  const { t } = useTranslation();

  return(
    <div className="flex flex-col h-full">
      <Lottie style={{ height: 90 }} animationData={congrats} loop/>
      <p className="grow">
        “{t("entities.task.stage.analyst.analyst_payment_init.message")}”
      </p>
      <div className="bg-secondary-base rounded-lg px-3 py-2 mt-5">
        <div className="flex justify-between items-center">
          {/* <h3 className="text-white font-semibold">Hasil Analisis & Panduan Decrypt Data 100% Kembali</h3> */}
          <h3 className="text-white font-semibold">{t("entities.task.stage.analyst.analyst_payment_init.analyst_result_text")}</h3>

          <DefaultTooltip 
            offset={12}
            reference={
              <SolidButton className="py-2" disabled>Download Analisis</SolidButton>
            }
          >
            <div className="max-w-md flex gap-2 items-center">
              <LockIcon width={50} height={50} className="shrink-0" />
              <p>
              {t("entities.task.stage.analyst.analyst_payment_init.preview_notice")}
              </p>
            </div>
          </DefaultTooltip>
        </div>
      </div>
      {analystPreviewSlot}
    </div>
  )
}