import { motion } from "framer-motion"
import { ReactNode } from "react"




const cardViewVariant = {
  initial:{
    opacity: 0
  },
  animate:{
    opacity: 1,
    transition: {
      delay: 0.5
    }
  },
  exit:{
    opacity: 0
  }
}

interface LocalCardWrapperProps {
  children: ReactNode;
  layoutId : string
}

export  function LocalCardWrapper({children} : LocalCardWrapperProps){
  return (
    <motion.div layoutId="sample-hacker-layout" className="w-full bg-black rounded">
      <motion.div
        className="w-full"
        variants={cardViewVariant}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        {children}
      </motion.div>
    </motion.div>
  )
}