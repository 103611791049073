import { Task } from "@entities/task";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AnalystPreviewPaymentArgs } from "./types";
import { taskAnalystPreviewPaymentService } from "@services/www.fixransomware.com";


export const paymentAnalystPreviewTask = createAsyncThunk(
  "currentTask/PaymentAnalystPreviewTask",
  async (payload : AnalystPreviewPaymentArgs, {getState}) => {
    try {
      const {currentTask} = getState() as {currentTask : {task: Task}};
  
      if(!currentTask.task.id) return;
      const result = await taskAnalystPreviewPaymentService({
        analystPreviewPaymentMethod: payload.analystPreviewPaymentMethod,
        id: currentTask.task.id,
        analystPreviewProof: payload.analystProof
      })
  
      return result.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
)