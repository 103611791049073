import { Task } from "@entities/task/model";
import { DialogWithHeader } from "@shared/components/dialog";
import { ComponentPropsWithoutRef, ReactElement, useState } from "react";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { OutlineButton } from "@shared/components/form";
import { getAnalystInvoiceService } from "@services/www.fixransomware.com";
import { toast } from "react-toastify";
import { FormatIDR, calculateGrandTotal } from "@shared/utils";
import { useTranslation } from "react-i18next";
import { CommonPayment } from "@shared/components/payment";
import { PaymentAnalystPreviewTransferData } from "../model";

interface PaymentAnalystPreviewModalProps extends Omit<ComponentPropsWithoutRef<typeof DialogWithHeader>, "title"> {
  task: Task;
  children?: ReactElement;
  getFileModalOpen: () => any;
  setTransferData: (arg: PaymentAnalystPreviewTransferData) => any;
}



export function PaymentAnalystPreviewModal({task, getFileModalOpen, /* children,  */setTransferData, ...props}: PaymentAnalystPreviewModalProps){
  const [paymentMethod, setPaymentMethod] = useState<"BNI" | "QRIS" | "PAYPAL">("BNI");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  function hadleClickUploadFile(){
    setTransferData({paymentMethod});
    getFileModalOpen();
  }

  const previewData = task?.analystPreviewData ?? {};

  return (
  <>
    <DialogWithHeader
      className="basis-1/3"
      title="Pembayaran Analisis Preview"
      {...props}
    >
      <CommonPayment 
        amount={ previewData?.analystPreviewPaymentNominal ? FormatIDR(previewData.analystPreviewPaymentNominal!) : "Belum ditentukan"}

        // # There is no deadline for preview
        deadline={(() => {
          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() + 1);
          return currentDate;
        })()}
        discount={previewData?.analystPreviewPaymentDiscount ?? "Belum Ditentukan"}
        invoice={task.id!}
        paymentMethod={paymentMethod}
        status={previewData.analystPreviewPaymentStatus!}
        onPaymentMethodChange={(commonPaymentMethod) => setPaymentMethod(commonPaymentMethod)}
        total={previewData?.analystPreviewPaymentNominal 
          ? FormatIDR(calculateGrandTotal(
              String(previewData.analystPreviewPaymentNominal!), 
              String(previewData.analystPreviewPaymentDiscount!)
            ))
          : "Belum DItentukan"
        }
        buttonSlot={
          <>
            <SolidButton onClick={hadleClickUploadFile} className="basis-1/2">
              Upload Proof
            </SolidButton>
          </>
        }
      />
    </DialogWithHeader>
  </>
  )
}