import { QuestionmarkTooltip } from "@shared/components/tooltip";
// import exampleFileGifSrc from "./../../assets/exampleFile.gif";
import exampleFileMp4Src from "./../../assets/exampleFile.mp4";
import exampleNoteSrc from "./../../assets/exampleNote.png";
import { useTranslation } from "react-i18next";




export function SampleFileTooltip(){

  const { t } = useTranslation();
  return (
    <div className="absolute top-0 right-3" >
      <QuestionmarkTooltip>
        <div className="max-w-lg w-72">
          <div className="bg-secondary-light whitespace- nowrap px-3 py-1 mb-2 rounded">Example of Encrypted File</div>
          {/* <img src={exampleFileGifSrc} className="mb-3" /> */}
          <video className='mb-3' autoPlay loop muted>
            <source src={exampleFileMp4Src} type='video/mp4'/>
          </video>  
          <p className="text-sm text-gray-500">
            {t("features.task.select_note_and_sample.select_note_and_sample.infected_sample_tooltip")}          
          </p>
        </div>
      </QuestionmarkTooltip>
    </div>
  )
}

export function HackerNoteTooltip(){
  const { t } = useTranslation();
  
  return (
    <div className="absolute top-0 right-3" >
      <QuestionmarkTooltip>
        <div className="max-w-lg w-72">
          <div className="bg-secondary-light whitespace- nowrap px-3 py-1 mb-2 rounded">Example of Ransom Note</div>
          <img src={exampleNoteSrc as string} className="mb-3" />
          <p className="text-sm text-gray-500">
          {t("features.task.select_note_and_sample.select_note_and_sample.ransomware_note_tooltip")}          
          </p>
        </div>
      </QuestionmarkTooltip>
    </div>
  )
}