import { User } from "@entities/user";
import { requestor } from "./requestor";
import { FixransomwareResponse } from "@shared/types/http";



interface SignUpPayload extends Omit<User, "id" | "role" | "type" | "confirm" >{
  referralCode ?: string;
  institutionType: string
  phoneCountryCode: string;
  province?: string;
  city?: string;
}
export async function signUpService(payload: SignUpPayload): Promise<Omit<User, "password">>{
  const result = await requestor.post("/auth/signup", payload);

  // should i set my token here?

  return result.data;
}

interface SignInPayload {
  email: string;
  password: string;
}
interface SigninResult {
  token: string;
}
export async function signInService(payload: SignInPayload){
  return (await requestor.post<FixransomwareResponse<SigninResult>>("/auth/signin",payload)).data;
}

interface ForgotPasswordPayload {email: string}
export async function forgotPasswordService(payload: ForgotPasswordPayload){
  return (await requestor.post<FixransomwareResponse<never>>("/auth/forgot-pass", payload)).data;
}

export async function signOut(){
  console.log("seharusnya logout");
}


interface ResendVerificationPayload {
  email: string;
  password: string;
}
export async function resendVerificationService(payload: ResendVerificationPayload){
  const result = await requestor.post("/auth/resend-confirm-message", payload);

  return result.data;
}



interface CheckAuthPayload {
  token: string;
}
export async function checkAuthService(payload: CheckAuthPayload): Promise<FixransomwareResponse<{client: User}>>{
  const result = await requestor.get("/auth/check-auth-client", {headers: {Authorization: `Bearer ${payload.token}`}});
  return result.data;
}