import { ReactNode, useEffect, useState } from "react";
import { FileWithPath } from "react-dropzone";
import { useAppDispatch } from "@shared/model/hooks";
import { anonymousMakeDonation } from "../model/action";
import { captureException } from "@sentry/react";
import { toast } from "react-toastify";
import { MakeAnonymousDonationModal } from "./MakeAnonymousDonationModal";
import { MakeAnonymousDonationSelectModal } from "./MakeAnonymousSelectFileModal";



interface MakeAnonymousDonationModalProps {
  children: ReactNode;
}

export type AnonymousDonationTranferDataType = {
  paymentMethod : "BNI" | "QRIS";
  nominal : string;
  email: string
} 

export function MakeAnonymousDonationWrapper({children} : MakeAnonymousDonationModalProps){
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProofFile, setSelectedProofFile] = useState<FileWithPath[]>([]);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [transferData, setTransferData] = useState<AnonymousDonationTranferDataType>({
    paymentMethod: "BNI",
    nominal: "0",
    email: ""
  });

  const dispatch = useAppDispatch();

  // disini tempat API dengan server
  async function handleSendProof(){
    try {

      if(!selectedProofFile[0]) {
        toast.error("Please provide the payment proof");
        return;
      }
      // return;
      await dispatch(anonymousMakeDonation({
        donationProof: selectedProofFile[0],
        nominal: transferData.nominal,
        paymentMethod: transferData.paymentMethod,
        email: transferData.email
      })).unwrap();

      toast.success("Sukses mengirim donasi, Terima Kasih atas Bantuan Anda");
      setIsModalOpen(false);
      setIsSelectModalOpen(false);
    } catch (error) {
      captureException(error);
      console.log(error);
      toast.error((error as any)?.message ?? "Ada masalah dengan server");
    }
  }

  // MAGIC: this useEffect will prevent bug selectedProofFile empty.
  useEffect(() => {
    console.log("selected proof file...");
    console.log(selectedProofFile);
  }, [selectedProofFile])

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>
        {children}
      </div>
      <MakeAnonymousDonationModal
        getFileModalOpen={() => setIsSelectModalOpen(true)}
        setTransferData={setTransferData}
        isOpen={isModalOpen}
        modalAccess={setIsModalOpen}
      />
      <MakeAnonymousDonationSelectModal
        filesValue={selectedProofFile}
        setFilesValue={setSelectedProofFile}
        isOpen={isSelectModalOpen}
        modalAccess={setIsSelectModalOpen}

        // # when click upload file in modal
        getSendFileModalOpen={() => handleSendProof()}
      />
    </>
  )
}