
import { Link } from "react-router-dom";
import googleIcon from "./../assets/ic-google.svg";




export function LoginByGoogleButton(){
  return (
    <div className="flex justify-center">
      <Link to={"/register"}>
        <button className="bg-white rounded-full px-4 py-2">
          <div className="flex justify-between text-secondary-base gap-3">
            <img src={googleIcon} alt="" />
            Continue with Google
          </div>
        </button>
      </Link>
    </div>
  )
}