import { createAsyncThunk } from "@reduxjs/toolkit";
import { signUpService } from "@services/www.fixransomware.com";
import { RegisterPayload } from ".";
import { AxiosError } from "axios";





export const registerAccountAuth = createAsyncThunk(
  "auth/registerByEmail",
  async (payload: RegisterPayload)=>{
    try {
      const result = await signUpService(payload);
      // @ts-ignore
      return result.data
    } catch (error) {
      console.log(error)
      if(error instanceof AxiosError)
        throw (JSON.stringify(error.response?.data));

      throw error;
    }
  }
)