import { useState } from "react";
import { toast } from "react-toastify";
import { FileWithPath } from "react-dropzone";
import { PaymentAnalystPreviewModal } from "./PaymentAnalystPreviewModal";
import { PaymentAnalystPreviewSelectModal } from "./PaymentAnalystPreviewSelectFileModal";
import {  } from "../model";
import { PaymentAnalystPreviewTransferData } from "../model";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { useAppDispatch, useAppSelector } from "@shared/model/hooks";
import { selectTask } from "@entities/task";
import { useTranslation } from "react-i18next";
import { taskAnalystPreviewPaymentService } from "@services/www.fixransomware.com";
import { paymentAnalystPreviewTask } from "../model/action";


export function PaymentAnalystPreviewButton(){
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [selectedProofFile, setSelectedProofFile] = useState<FileWithPath[]>([]);
  const [transferData, setTransferData] = useState<PaymentAnalystPreviewTransferData>({
    paymentMethod: "BNI",
  })

  const { t } = useTranslation();

  const task = useAppSelector(selectTask);

  const dispatch = useAppDispatch();

  // tempat dispatch serta hit API
  // jangan lupa transfer data juga di gunakan disini
  async function handleSendProof(){
    console.log("seharusny sudah di dispatch")
    try {
      if(selectedProofFile.length === 0){
        toast.warn(t("features.task.analyst_payment.payment_analyst_button.should_choose_file"));
        // toast.warn("harap pilih file terlebih dahulu");
        return;
      }
      setIsModalOpen(false);

      /* const result =  */await dispatch(paymentAnalystPreviewTask({
        analystPreviewPaymentMethod: transferData.paymentMethod,
        analystProof: selectedProofFile[0]
      })).unwrap()

    } catch (error) {
      console.log(error);
      toast.error(t("features.task.analyst_payment.payment_analyst_button.error_when_send_file"));
      // toast.error("Ada masalah dalam mengirim file");
    }
  }


  return(
    <>
      <SolidButton 
        variant="warning"
        className="py-2"
        onClick={() => setIsModalOpen(true)}
      >
        Pembayaran sample analyst
      </SolidButton>
      <PaymentAnalystPreviewModal
        task={task}
        setTransferData={setTransferData}
        isOpen={isModalOpen}
        modalAccess={setIsModalOpen}
        getFileModalOpen={() => setIsSelectModalOpen(true)}
      />
      <PaymentAnalystPreviewSelectModal 
        filesValue={selectedProofFile}
        setFilesValue={setSelectedProofFile}

        isOpen={isSelectModalOpen}
        modalAccess={setIsSelectModalOpen}
        getSendFileModalOpen={() => handleSendProof()}
      />
    </>
  )
}