import { ComponentPropsWithoutRef, ReactNode, useState } from "react";
import clsx from "clsx";


interface DefaultTooltipProps extends ComponentPropsWithoutRef<"div">{
  children: ReactNode;
  reference: ReactNode;
  offset?: number;
}

export function DefaultTooltip({children, className, reference, offset = 0} : DefaultTooltipProps){
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div 
      className={clsx(className, "relative")}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {reference}

      <div style={{
        display: (isVisible ? "block" : "none"), 
        position: "absolute", 
        marginTop: offset,
        top: "100%", 
        left: "-100%", 
        zIndex: 10
        }}
      >
        <div className="bg-secondary-dark/70 backdrop-blur-md p-2 rounded">
          {children}
        </div>
      </div>
    </div>
  )
}