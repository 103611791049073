import { ReactNode } from "react";
import { Phase } from ".."


interface TaskColumnProps {
  status: typeof Phase[keyof typeof Phase];
  updatedAt: string;
  actionSlot: ReactNode;
}



export function TaskColumn(props: TaskColumnProps){

  return (
    <div className="flex gap-2 w-full p-2 bg-secondary-dark items-center rounded">
    <div
      className="px-4 border-s-[3px] py-1 border-transparent "
    >
      <input type="checkbox" value="" />
    </div>
    <div className="col-2 basis-2/12 border-s-[3px] px-3 py-1 border-transparent">
      Scan & Decrypt
    </div>
    <div className="col-3 basis-4/12 border-s-[3px] px-3 py-1 border-transparent">
      {props.updatedAt}
    </div>
    <div className="col-3 basis-2/12 border-s-[3px] px-3 py-1 border-transparent">
      {props.status}
    </div>
    <div className="col-2 basis-2/12 border-s-[3px] px-3 py-1 border-transparent grow flex flex-row-reverse">
      {props.actionSlot}
    </div>
  </div> 
  )
}