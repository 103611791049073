





export function textToPlain(textSource: string){
  return new Blob([textSource], { type: "text/plain;charset=utf-8", }); 
}

export function textToPlainFile(textSource: string, fileName: string = "unnamed file"){
  return new File([textToPlain(textSource)], fileName, {type: "text/plain"});
}