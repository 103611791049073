import { FileWithPath } from "react-dropzone";
import XIcon from "@assets/icon/x.svg?react";
import DocumentIcon from "./assets/document.svg?react";
import clsx from "clsx";

interface FileListElementProps {
  file : FileWithPath;
  status?: "rejected" | "accepted" | "pending";
  errorMessage?: string;
  onDelete: (...args: any) => any;
}

// # TODO: handle how file types (mime) should contained value
// # apparently it's only return "" when i try to upload .txt file
export function FileListElement({file, status = "accepted", errorMessage = "", onDelete}: FileListElementProps){
  return (
    <div className="">
      <div className="flex w-full text-gray-500 gap-2 border border-secondary-light rounded items-stretch">
        <div 
          className={clsx(
            "p-3",
            status === "accepted" && "bg-success-dark",
            status === "rejected" && "bg-danger",
            status === "pending" && "bg-warning"
          )} >
          {status === "rejected" ? <XIcon width={18} height={18} className="text-white" /> : <DocumentIcon width={18} height={18} className="text-white" />}
          
        </div>
        <div className="basis-1/2 grow-0 shrink-0 font-light p-2">
          {file.name}
        </div>
        <div className="text-sm grow p-2">
          {Number(file.size / 1_000_000).toFixed(3)} mb
        </div>

        {/* remove file from list when clicked... */}
        <button 
          onClick={onDelete} 
          className="p-2 text-gray-600 hover:text-gray-500 active:text-gray-400"
        >
          <XIcon width={18} height={18} />
        </button>
      </div>
      <div className="text-white font-light text-sm">
        {errorMessage}
      </div>
    </div>
  )
}