import { useEffect, useState } from "react";
import { taskGetAnalystPreview } from "@services/www.fixransomware.com";
import { Task } from "@entities/task";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { PaymentAnalystPreviewButton } from "./PaymentAnalystPreviewButton";
import clsx from "clsx";


interface AnalystPreview {
  task: Task
}

export function AnalystPreview({task} : AnalystPreview){

  const [imgSrc, setImgSrc] = useState<string | null>(null);

  useEffect(()=>{
    if(!task.id) return;

    if(!task?.analystPreviewData) return;

    taskGetAnalystPreview(task.id)
      .then(result => {
        console.log(result);
        setImgSrc(result)
      });

    
  },[task.id])

  if(!task.analystPreviewData) return "";

  return(
    <>
      <div className="bg-secondary-base rounded-lg px-3 py-2 mt-1">
        <div className="flex justify-between items-center">
          <h3 className="text-white font-semibold">Sampel Mewakili 100% Data yang Terselamatkan</h3>
          {buttonPay(task?.analystPreviewData?.analystPreviewPaymentStatus)}
        </div>
        {imgSrc && (
          <img 
            className={clsx(
              "transition rounded-lg mt-3 mx-auto",

              // blur when status is not success
              !(task?.analystPreviewData?.analystPreviewPaymentStatus === "Success") && "blur-xl hover:blur-lg"
            )} 
            src={imgSrc} 
          />
        )}
      </div>
    </>
  )
}

function buttonPay(status: "Success" | "Pending" | "Init" | undefined){
  switch (status) {
    case "Init":
      return <PaymentAnalystPreviewButton />
  
    case "Pending":
      return <SolidButton variant="primary" disabled>Menunggu Konfirmasi</SolidButton>
    
    case "Success":
      return ""

    default:
      return ""
  }
}