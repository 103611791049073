import { createAsyncThunk } from "@reduxjs/toolkit";






/**
 * @todo make this thunk to process service from electron
 * from now it just dummy thunk to be placed later with server
 */
export const ScanDirectory = createAsyncThunk(
  "scan/scanDirectory",
  ()=>{}
)