import { selectCurrentStage } from "@entities/task";




export function activeLocalStagebyStage(currentStage: ReturnType<typeof selectCurrentStage>){
  switch(currentStage){
    case "INDEXING":
      return ("indexing_and_virus_upload");
      break;
    
    case "UPLOADED_HACKER_NOTE_AND_SAMPLE_FILE":
      return ("hacker_note_and_sample_upload");
      break;
    
    case "ANALSYT_PAYMENT_ACTION" :
    case "ANALYST_PAYMENT_CONFIRM":
    case "ANALYST_PAYMENT_INIT"   :
    case "ANALYST_RESULT"         :
      return ("analyst");
      break;
    
    case "DECRYPT_PAYMENT_INIT"    :
    case "DECRYPT_PAYMENT_ACTION"  :
    case "DECRYPT_PAYMENT_CONFIRM" :
    case "DECRYPT_ESTIMATION"      :
    case "DECRYPT_RESULT"          :
      return ("decrypt");
      break;
    
    default:
      return null
      break;
  }
}