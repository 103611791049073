import { setOffCheckUpTask } from "@entities/task";
import { changeCurrentTask } from "@features/task/change-current";
import { TaskProcessProgress } from "@process/task-process";
import { useAppDispatch } from "@shared/model/hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { toast } from "react-toastify";






export function TaskPage(){
  const { taskId } = useParams();
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(true);
  

  async function setUpCurrentTaskState(){
    try {
      await dispatch(changeCurrentTask(taskId!)).unwrap();
      dispatch(setOffCheckUpTask()); // refresh task in home;
      setIsLoading(false);
    } catch (error) {
      toast.error("gagal mengatur task : " + taskId);
    }
  }

  useEffect(()=>{
    if(!taskId) return;
    console.log("halloooooo")
    setUpCurrentTaskState();
  },[taskId])

  if(isLoading) return (
    <p>
      loading
      <p>ini adalah task page {taskId}</p>
    </p>
  )
  return (
    <TaskProcessProgress/>
  )
}