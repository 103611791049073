import clsx from "clsx";
import { ReactNode } from "react";



export interface TabProps {
  label: string | ReactNode;
  children: string | ReactNode;
  className?: string;
}

export function Tab(props: TabProps){

  return (
    <div className={clsx(
      props.className
    )}>
      {props.children}
    </div>
  );
}