import { DefaultDropdown } from "@shared/components/dropdown";
import SettingIcon from "@shared/assets/icon/settings.svg?react"



export function SettingDropdown(){
  return (
    <DefaultDropdown
    referenceSlot={
      <SettingIcon className="text-gray-200 group-hover:text-gray-300 group-active:text-gray-400" width={30} height={30} />
    }
  >
    <ul className="w-44 text-gray-400">
      <li className="'w-full px-4 py-1 hover:bg-secondary-base hover:text-white rounded">Not Implemented yet</li>
    </ul>
  </DefaultDropdown>
  )
}