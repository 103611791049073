import { useState } from "react";
import { toast } from "react-toastify";
import { FileWithPath } from "react-dropzone";
import { PaymentAnalystModal } from "./PaymentAnalystModal";
import { PaymentAnalystSelectModal } from "./PaymentAnalystSelectFileModal";
import { paymentAnalystTask } from "../model";
import { PaymentAnalystTransferData } from "../model";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { useAppDispatch, useAppSelector } from "@shared/model/hooks";
import { selectTask } from "@entities/task";
import { useTranslation } from "react-i18next";
import { ErrorValidation } from "@features/auth/login-by-email/model/types";


function toListElement(data: string[]){
  return(
    <ul className=" flex flex-col break-words">
      {data.map(dat => (
        <li className="border-b border-b-red-200 py-1">
          {dat}
        </li>
      ))}
    </ul>
  )
}

export function PaymentAnalystButton(){
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [selectedProofFile, setSelectedProofFile] = useState<FileWithPath[]>([]);
  const [transferData, setTransferData] = useState<PaymentAnalystTransferData>({
    paymentMethod: "BNI",
  })

  const { t } = useTranslation();

  const task = useAppSelector(selectTask);

  const dispatch = useAppDispatch();

  // tempat dispatch serta hit API
  // jangan lupa transfer data juga di gunakan disini
  async function handleSendProof(){
    console.log("seharusny sudah di dispatch")
    try {
      if(selectedProofFile.length === 0){
        toast.warn(t("features.task.analyst_payment.payment_analyst_button.should_choose_file"));
        // toast.warn("harap pilih file terlebih dahulu");
        return;
      }
      setIsModalOpen(false)

      /* const result =  */await dispatch(paymentAnalystTask({
        analystPaymentMethod: transferData.paymentMethod,
        analystProof: selectedProofFile[0]
      })).unwrap()

    } catch (error) {
      console.log(error);
      try {
        const errorValue = error as ErrorValidation;
        toast.error(
          Array.isArray(errorValue.message) 
            ? toListElement(errorValue.message)
            : errorValue.message
        )
      } catch (error) {
        console.log(error);
        toast.error(t("features.task.analyst_payment.payment_analyst_button.error_when_send_file"));
        toast.error("Ada masalah dengan server")
      }
      // toast.error("Ada masalah dalam mengirim file");
    }
  }

  return(
    <>
      <SolidButton 
        variant="warning"
        className="py-2"
        onClick={() => setIsModalOpen(true)}
      >
        {t("features.task.analyst_payment.payment_analyst_button.pay_now")}
      </SolidButton>
      <PaymentAnalystModal
        task={task}
        setTransferData={setTransferData}
        isOpen={isModalOpen}
        modalAccess={setIsModalOpen}
        getFileModalOpen={() => setIsSelectModalOpen(true)}
      />
      <PaymentAnalystSelectModal 
        filesValue={selectedProofFile}
        setFilesValue={setSelectedProofFile}

        isOpen={isSelectModalOpen}
        modalAccess={setIsSelectModalOpen}
        getSendFileModalOpen={() => handleSendProof()}
      />
    </>
  )
}