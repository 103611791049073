export function FormatUSD(num: string | number) {
  return Intl.NumberFormat("us-UD", {
    style: "currency",
    currency: "USD",
  }).format(Number(num));
}
export function FormatIDR(num: string | number) {
  return Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(Number(num));
}

// export function Formattime(waktuAwal) {
//   var waktu = new Date(waktuAwal);
//   var options = {
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//     hour: "2-digit",
//     minute: "2-digit",
//     hour12: true,
//   };
//   var waktuAkhir = waktu.toLocaleDateString("en-US", options);

//   return waktuAkhir;
// }

// export function reverseFormatCurrencyNumber(val, formatter) {
//   let sanitizedVal = val.match(/(\d|,|\.)/g).join('');

//   let [group, decimal] = formatter(1111)
//     .replace(/(1|0)/g, '')
//     .match(/(\d|,|\.)/g)
//     .join('')
//     .split('');

//   let [integerString, decimalString] = sanitizedVal.split(decimal);

//   integerString = integerString.split(group).join('');
//   let numberString = integerString + '.' + decimalString;

//   const integerNumber = parseFloat(numberString);
//   return Number.isNaN(integerNumber) ? 0 : integerNumber;
// }

export {}