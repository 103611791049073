import InfoIcon from "@assets/icon/info.svg?react";
import { Task } from "@entities/task";
import { TaskColumn } from "@entities/task/ui";
// import { selectUser } from "@entities/user";
import { getTaskListByClient } from "@services/www.fixransomware.com";
import { AxiosError } from "axios";
// import { useAppSelector } from "@shared/model/hooks";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";




function ListAction ({task}:{task: Task}) {
  
  return (
    <TaskColumn
      status={task.status as NonNullable<typeof task.status>}
      updatedAt={task.updatedAt as NonNullable<typeof task.updatedAt>}
      actionSlot={(
        <Link to={"/progress/"+ task.id}>
          <button className="text-white bg-success-dark hover:bg-success-dark/90 active:bg-success-dark/80 p-2 rounded block">
            <InfoIcon width={20} height={20} />
          </button>
        </Link>
      )}
    />
  )
}


export function Progress(){
  const [taskList, setTaskList] = useState<Task[]>([])
  // const user = useAppSelector(selectUser);
  const {t} = useTranslation();


  async function getTaskList(){
    try {
      const tasks = await getTaskListByClient();
      setTaskList(tasks.data);
    } catch (error) {
      console.log(error)
      if(error instanceof AxiosError){
        const message = error.response?.data?.message;
        toast.error(message);
        return;
      }
      toast.error("gagal mengambil daftar task....");
    }
  }
  useEffect(()=>{ getTaskList() },[])

  return (
    <div className="flex justify-center">
      <div className="w-[60%] mt-5 bg">
        <h1 className="text-3xl font-bold">Action</h1>
        <section>
          <div className="flex gap-2 w-full p-2">
            <div
              className="px-4 border-s-[3px] border-secondary-light "
            >
              <input
                className="form-check-input"
                type="checkbox"
                value=""
              />
            </div>
            <div className="col-2 basis-2/12 border-s-[3px] px-3 border-secondary-light">
              Activity Name
            </div>
            <div className="col-3 basis-4/12 border-s-[3px] px-3 border-secondary-light">
              Date and Time
            </div>
            <div className="col-3 basis-2/12 border-s-[3px] px-3 border-secondary-light">
              Status
            </div>
            <div className="col-2 basis-2/12 border-s-[3px] px-3 border-secondary-light">
              Action
            </div>
          </div>
          <div className="w-full flex flex-col gap-2">
            {taskList.map(task => (
              <ListAction task={task} key={task.id ?? ""} />
            ))}
          </div>
        </section>
      </div>
    </div>
  )
}