import { AxiosError } from "axios";
import * as _ from "lodash-es";


interface FixransomwareResponseSchema<T>{
  status: boolean,
  path: string,
  statusCode : number,
  data: Record<string, any>,
}

export function extractFixransomwareDataFromAxiosError<T>(error: AxiosError<FixransomwareResponseSchema<T>>){
  if(!error) return null;

  if(!error.response?.data.data?.response) return null;
  
  return error.response?.data.data?.response

}



const checkKeys = [
  "status",
  "path",
  "statusCode",
  "data",
]
export function isFixransomwareData(data: Record<string, any>){
  if(!data) return false;
  const isValid = checkKeys.every(prop => prop in data);
  return isValid;
}