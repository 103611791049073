import { clearTask, setOffCheckUpTask } from "@entities/task";
import { clearUser } from "@entities/user";
import { removeToken } from "@services/localStorage";
import { clearReuestToken } from "@services/www.fixransomware.com";
import { useAppDispatch } from "@shared/model/hooks";
import clsx from "clsx";
import { MouseEvent, ReactNode } from "react";
// import { toast } from "react-toastify";


interface LogoutButtonWrapperProps {
  children: ReactNode | string;
  className?: string;
}



export function LogoutButtonWrapper(props: LogoutButtonWrapperProps){

  const dispatch = useAppDispatch();

  /** fire when login button clicked */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleOnClick(e: MouseEvent<HTMLDivElement>){
    // reset current user state
    dispatch(clearUser());

    // reset current task state
    dispatch(clearTask());

    // reset task checkup flag
    dispatch(setOffCheckUpTask());

    // reset stored token
    removeToken();

    // clear token from requestor
    clearReuestToken();
  }
  return (
    <div 
      onClick={handleOnClick}
      className={clsx(props.className)}
    >
      {props.children}
    </div>
  )
}