import { createSlice } from "@reduxjs/toolkit";
import { CurrentTaskSliceState, FulfilledAction, PendingAction, RejectedAction } from ".";


const initialState : CurrentTaskSliceState = {
  error: "",
  loading: false,
  // task : {
  //   id: ""
  // }
  task: {},
  checkUp: false
}



export const currentTaskSlice = createSlice({
  name: "currentTask",
  initialState,
  reducers: {
    clearTask: state => {state.task = {}},
    setOnCheckUpTask: state => {state.checkUp = true},
    setOffCheckUpTask: state => {state.checkUp = false},
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is RejectedAction => action.type.endsWith("/rejected") && action.type.startsWith("currentTask"),
      (state, action) => {
        state.loading = false;
        state.error = action.error;
      }
    );
    builder.addMatcher(
      (action): action is PendingAction => action.type.endsWith("/pending") && action.type.startsWith("currentTask"),
      (state) => {
        state.loading = true;
      }
    )
    builder.addMatcher(
      (action): action is FulfilledAction => action.type.endsWith("/fulfilled") && action.type.startsWith("currentTask"),
      (state, action) => {
        state.loading = false;
        state.task = action.payload
      }
    )
  }
})

export const {clearTask, setOffCheckUpTask, setOnCheckUpTask} = currentTaskSlice.actions
/**
 * There are some stage for the task operation in this app
 * - indexing and virus sample
 * - initialise analyst payment
 * - action analyst payment
 * - confirm analyst payment
 * - send analyst result
 * - initialise decrypt payment
 * - action decrypt payment
 * - confirm decrypt payment
 * - estimated (waiting) decrypt finish // i also iclude this because it will need some process in server
 * - send finished decrypt
 */