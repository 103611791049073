import { ComponentPropsWithoutRef, useState } from "react";
import { DialogWithHeader } from "@shared/components/dialog";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { AgreementText } from "./AgreementText";
import { useTranslation } from "react-i18next";




interface AgreementModalProps extends Omit<ComponentPropsWithoutRef<typeof DialogWithHeader>, "title">{
  onSubmitSample: (...args: any) => any;
}


export function AgreementModal({...props}: AgreementModalProps){

  const [policyCheck, setPolicyCheck] = useState(false);
  const [paymentCheck, setPaymentCheck] = useState(false);
  const { t } = useTranslation();


  async function handleProcess(){
    props.onSubmitSample();
  }

  return (
    <DialogWithHeader 
      isOpen={props.isOpen}
      modalAccess={props.modalAccess}
      title={t("features.task.select_note_and_sample.agreement_modal.title")}
    >
      <div className="">
        <hr className="mb-5 border-secondary-light" />

        <AgreementText />

        <div className="flex flex-col p-3">
          <div className="inline-flex gap-3 items-center">
            <input 
              type="checkbox" 
              name="agree-policy" 
              id="agree-policy" 
              checked={policyCheck}
              onChange={e => setPolicyCheck(e.currentTarget.checked)}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="agree-policy">
              {t("features.task.select_note_and_sample.agreement_modal.agree_policy")}
            </label>
          </div>
          <div className="inline-flex gap-3 items-center">
            <input 
              type="checkbox" 
              checked={paymentCheck}
              onChange={e => setPaymentCheck(e.currentTarget.checked)}
              name="agree-payment" 
              id="agree-payment" 
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="agree-payment">{t("features.task.select_note_and_sample.agreement_modal.agree_payment")}</label>
          </div>
          <SolidButton
            variant="primary"
            className="ms-auto"
            disabled={!(policyCheck && paymentCheck)}
            onClick={(policyCheck && paymentCheck) ? handleProcess : ()=>{}}
          >
            {t("features.task.select_note_and_sample.agreement_modal.submit_button")}
          </SolidButton>
        </div>
      </div>
      
    </DialogWithHeader>
  )
}