import { selectUserCheckUp } from "@entities/user";
import { useAppSelector } from "@shared/model/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";




// build a better way to handle guard,
// i belive it's better if the guard is defined in react router loader.
/**
 * guard this route / mounted element to only be shown when user is set
 * in global state
 */
export function useGuardUserAuth(){
  const navigate = useNavigate();
  const user = useAppSelector(state => state.currentUser.currentUser);
  const checkupState = useAppSelector(selectUserCheckUp);
  useEffect(() => {
    if(!checkupState) return;
    if(!user){
      navigate("/login");
    }
    
  },[user, checkupState]);
}


/**
 * guard this route / mounted element to only be shown when user is not set
 * in global state
 */
export function useGuardGuest(){
  const navigate = useNavigate();
  const checkupState = useAppSelector(selectUserCheckUp);
  const user = useAppSelector(state => state.currentUser.currentUser);
  useEffect(() => {
    if(!checkupState) return;
    if(user){
      navigate("/home");
    }
  },[user]);
}