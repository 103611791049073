import { SelectLanguageOption } from "@entities/language";
import { useAppSelector } from "@shared/model/hooks";
import { CreateNewTaskButton, DownloadButton, HomeButton, ProgressButton } from "./components/LeftSide";
import { SettingDropdown } from "@widgets/setting";
import { ChangeStateDropdown } from "@features/dev/change-state";
import { AccountAndAuth } from "./components/RightSide";
import { isUserAdmin, isUserPremium } from "@entities/user/model/selector";
import { PremiumBagde } from "@entities/user/ui/premium-badge";
import { findOneNewestAcceptedDonation } from "@services/www.fixransomware.com";
import { ToDonationNavigation } from "@features/donation/make-donation";




export function MainNavbar(){

  const isCurrentUserAdmin = useAppSelector(isUserAdmin);
  const isCurrentUserPremium = useAppSelector(isUserPremium);

  return (
    <div className="flex w-full justify-between py-3 px-9 text-white items-center">
      <div className="flex gap-3">
        <HomeButton />
        <ProgressButton />
        <CreateNewTaskButton />
        <DownloadButton />
      </div>
      
      <div className="flex gap-10 items-center">

        {isCurrentUserAdmin && <ChangeStateDropdown />}

        {isCurrentUserPremium ? <PremiumBagde getNewestDonation={findOneNewestAcceptedDonation} /> : <ToDonationNavigation />}

        <SelectLanguageOption />
        <SettingDropdown />
        <AccountAndAuth />

      </div>
    </div>
  )
}