import { useAppDispatch } from "@shared/model/hooks";
import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RegisterPayload, registerAccountAuth } from ".";
import { toast } from "react-toastify";
import { handleFixransomwareError } from "@shared/utils";
import { storeClient } from "@services/sessionStorage";
import i18next from "i18next";


function toListElement(data: string[]){
  return(
    <ul className=" flex flex-col break-words">
      {data.map(dat => (
        <li className="border-b border-b-red-200 py-1">
          {dat}
        </li>
      ))}
    </ul>
  )
}

export function useRegisterHook(){
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [registerForm, setRegisterForm] = useState<Omit<RegisterPayload, 'language'>>({
    address : "",
    email: "",
    fullname : "",
    institution : "",
    password: "",
    phoneNumber : "",
    institutionType: "PT",
    phoneCountryCode: "ID",

    // use id
    province: "",
    city: ""
  })

  const handleFormChange = (field: keyof RegisterPayload) => (e: ChangeEvent<HTMLInputElement>) => {
    let value: string;

    if(field === "email")
      value = e.target.value.toLowerCase();
    else
      value = e.target.value;

    setRegisterForm(prev => ({...prev, [field] : value}))
  }

  const setReferralCode = (value?: string) => {
    if(value){
      setRegisterForm(prev => ({...prev, "referralCode" : value}));
    }else{

      setRegisterForm(prev => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {referralCode, ...another} = prev;
        return another
      })
    }
  }

  const handleDropdownCityChange = (value: string = "") => {
    setRegisterForm(prev => ({...prev, "city" : value}))
  }

  const handleDropdownProvinceChange = (value: string = "") => {
    setRegisterForm(prev => ({...prev, "province" : value}))
  }

  const handleDropdownInstitutionChange = (value: string = "") => {
    setRegisterForm(prev => ({...prev, "institutionType" : value}))
  }

  const handleDropdownPhoneCodeChange = (value: string = "") => {
    setRegisterForm(prev => ({...prev, "phoneCountryCode" : value}))
  }

  async function handleOnSubmit(e: FormEvent){
    e.preventDefault();

    // toast.success(JSON.stringify(registerForm, null, 2));
    // return;
    try {
      const currentLang = i18next.resolvedLanguage;
      await dispatch(registerAccountAuth({...registerForm, language: currentLang as "id" | "en"})).unwrap();
      navigate("/success-create-account");
      toast.success("berhasil membuat akun");

      storeClient({
        email: registerForm.email,
        password: registerForm.password
      })
    } catch (error) {
      //@ts-ignore
      const errorValue = JSON.parse(error.message)
      toast.error(Array.isArray(errorValue.response.message) ? toListElement(errorValue.response.message) : errorValue.response.message);
      // toast.error((error as Error).message)
    }
  }

  return {
    registerForm, 
    handleFormChange, 
    handleOnSubmit,
    handleDropdownInstitutionChange,
    handleDropdownPhoneCodeChange,
    setReferralCode,
    handleDropdownCityChange,
    handleDropdownProvinceChange
  }
}