import { Task } from "@entities/task"
import { Virus } from "@entities/virus"
import { OutlineButton } from "@shared/components/form";
import { isValidHttpUrl } from "@shared/utils";
import { useTranslation } from "react-i18next";



interface IndexingSuccessProps {
  task: Task
  virus: Virus;
}

export function IndexingSuccess({virus,}: IndexingSuccessProps){

  const { t } = useTranslation();

  return(
    <div className="h-full flex flex-col px-6 py-2 gap-5">
    <div className="grow">
      <h1 className="text-2xl font-bold mb-3">Potential Ransomware Detected!</h1>
      <p className="mb-2">
        {t("entities.task.stage.indexing.success.message_up")}
      </p>
      <p className="mb-2">
        {/* Virus Ransomware yang menyerang PC anda adalah varian virus terbaru dan kami telah berhasil memetakannya. 
        Analisis AI kami membaca bahwa terdapat kemungkinan 80% virus ransomware ini tergolong pada varian Makop, 
        Lockbit, Dharma, crysis, Snatch, maupun Phobos. Terdapat probabilitas 20% tergolong virus ransomware varian STOP/DJVU. 
        Sayangnya, universal decryptor kami belum dapat decrypt otomatis dikarenakan ransomare ini dibekali enkripsi dengan 
        ID online yang unik untuk setiap korban. Sehingga untuk decryption memerlukan analisis manual dan anda 
        dapat melanjutkan proses decryption/recovery dengan garansi 100% pengembalian. */}
        {virus.virusDesc}
      </p>
      <p>
        {/* Teknisi kami juga dapat memberikan penanganan virus agar tidak menyebar ke jaringan anda dan megenkripsi lebih banyak 
        data, segera hubungi customer help dan teknisi kami pada menu bantuan di bawah. */}
        {t("entities.task.stage.indexing.success.message_down")}
      </p>
    </div>
    <div className="flex flex-row-reverse">
      <a href={ isValidHttpUrl(virus?.urlBlog) ? virus.urlBlog : "https://fixransomware.com" } target="_blank" rel="">
        <OutlineButton>
          {t("entities.task.stage.indexing.success.learn_more")}
        </OutlineButton>
      </a>
    </div>
  </div>
  )
}
