import { User } from "@entities/user";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTaskOneByClientService } from "@services/www.fixransomware.com";
import { AxiosError } from "axios";






export const getCurrentTask = createAsyncThunk(
  "currentTask/getCurrent",
  async (_, {getState})=>{
    try {
      const {currentUser} = getState() as {currentUser: {currentUser : User}}
  
      if(!currentUser) return;
      const result = await getTaskOneByClientService({
        userId: currentUser.currentUser.id,
        sortBy: "createdAt"
      })
  
      return result.data;
      
    } catch (error){
      const {currentUser} = getState() as {currentUser: {currentUser : User}}

      // --- err, axios --
      if(error instanceof AxiosError){
        throw new Error((error.response?.data.message ?? error.message) + " for user : " + currentUser.currentUser.fullname);
      }

      throw new Error(`Ada masalah ketika mengambil data task untuk client : ${currentUser.currentUser.id}`)
    }
  }
)