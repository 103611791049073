import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "@entities/user";
import { anonymousDonationMakeService, donationMakeService } from "@services/www.fixransomware.com";
import { AnonymousDonationMakePayload } from "./type";






export const anonymousMakeDonation = createAsyncThunk(
  "donation/anonymous",
  async (payload: AnonymousDonationMakePayload) => {
    const result = await anonymousDonationMakeService(payload);

    return result
  }
)