import { createAsyncThunk } from "@reduxjs/toolkit";
import { GeneralLoginError, LoginByEmailPayload } from "./types";
import { setRequestToken, signInService } from "@services/www.fixransomware.com";
import jwtDecode from "jwt-decode";
import { setToken } from "@services/localStorage";
import { AxiosError, isAxiosError } from "axios";
import { User } from "@entities/user";
import { JwtTokenDecoded } from "@shared/types/jwtToken";
import { axiosHaveResponseError, extractFixransomwareDataFromAxiosError, generateGenericErrorAxios, isFixransomwareData } from "@shared/utils";
import { captureException } from "@sentry/react";




export const loginByEmailAuth = createAsyncThunk(
  "currentUser/LoginByEmail",
  async (payload: LoginByEmailPayload, { rejectWithValue }) => {
    try {
      const result = (await signInService(payload)).data;
      console.log(jwtDecode<JwtTokenDecoded>(result.token))
      setToken(result.token);
      setRequestToken(result.token);
      
      
      // return result.user;
      return jwtDecode<{user: User}>(result.token).user;

    } catch (error) {
      console.log(error);

      console.log(isAxiosError(error));

      // check if ther error is axios error
      if(isAxiosError(error)){

        // check if there are an response keys
        const response = axiosHaveResponseError<GeneralLoginError>(error);

        // throw generic error
        if(!response){ 
          captureException(error);
          throw generateGenericErrorAxios(error);
        }

        // check if the response is fixransomware data
        if(!isFixransomwareData(response.data)) throw generateGenericErrorAxios(error); 

        // handle fixransomware response error here
        return rejectWithValue(extractFixransomwareDataFromAxiosError(error));
      }
      throw error;
    }
  }
)