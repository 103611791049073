import { AttackedDevice, Task, TaskExtraType } from "@entities/task";
import { requestor } from "./requestor";
import { FixransomwareResponse } from "@shared/types/http";
import { Buffer } from "buffer";
import { Stringifier } from "postcss";



type TaskResponse = FixransomwareResponse<Task>
type TaskListResponse = FixransomwareResponse<Task[]>

type TaskCreatePayload = {
  userId: string;
  file? : File;
  virusVarian : string;
};
export async function taskCreateService(payload: TaskCreatePayload | FormData){
  const result = (await requestor.post<TaskResponse>("/task", payload));
  return result.data;
}





type TaskStaticAndHackerNotePayload = {
  id: string;
  sampleFile : File[];
  hackerNote: File[];
  extra?: {
    attackedDevice ?: {
      deviceType ?: keyof AttackedDevice,
      value?: AttackedDevice[keyof AttackedDevice]
    },
    dataEstimation ?: number // in gb
    affectedDevice ?: number
    topologyFile ?: File // id where file stored
  }; 
};
export async function taskSampleAndHackerNoteService(payload: TaskStaticAndHackerNotePayload){
  const formStaticAnalyst = new FormData();

  formStaticAnalyst.append("id", payload.id);

  payload.hackerNote.forEach(file => {
    formStaticAnalyst.append("hackerNote", file);
  })

  payload.sampleFile.forEach(file => {
    formStaticAnalyst.append("sampleFile", file);
  })
  console.log(payload.extra)

  if(payload.extra?.topologyFile)
    formStaticAnalyst.append("topology", payload.extra.topologyFile);

  if(payload.extra?.attackedDevice?.deviceType)
    formStaticAnalyst.append("attackedDevice[deviceType]", payload.extra?.attackedDevice?.deviceType);

  if(payload.extra?.attackedDevice?.value)
    formStaticAnalyst.append("attackedDevice[value]", payload.extra?.attackedDevice?.value);

  if(payload.extra?.affectedDevice)
    formStaticAnalyst.append("affectedDevice", String(payload.extra?.affectedDevice));

  if(payload.extra?.dataEstimation)
    formStaticAnalyst.append("dataEstimation", String(payload.extra?.dataEstimation));

  const result = (await requestor.post<TaskResponse>(`task/${payload.id}/sample-process`, formStaticAnalyst, {headers : {'Content-Type': 'multipart/form-data'}}));
  return result.data;
}

export type TaskStaticAndHackerNoteCreatePayload = {
  virus: string;
  user_id: string;
  sampleFile : File[];
  hackerNote: File[];
  extra?: {
    attackedDevice ?: {
      deviceType ?: keyof AttackedDevice,
      value?: AttackedDevice[keyof AttackedDevice]
    },
    dataEstimation ?: number // in gb
    affectedDevice ?: number
    topologyFile ?: File // id where file stored
  }; 
};
/** create new task, but in sample and hacker note status */
export async function taskSampleAndHackerNoteServiceCreateNew(payload: TaskStaticAndHackerNoteCreatePayload){
  const formStaticAnalyst = new FormData();

  formStaticAnalyst.append("virus", payload.virus);

  formStaticAnalyst.append("user_id", payload.user_id);

  payload.hackerNote.forEach(file => {
    formStaticAnalyst.append("hackerNote", file);
  })

  payload.sampleFile.forEach(file => {
    formStaticAnalyst.append("sampleFile", file);
  })
  console.log(payload.extra)

  if(payload.extra?.topologyFile)
    formStaticAnalyst.append("topology", payload.extra.topologyFile);

  if(payload.extra?.attackedDevice?.deviceType)
    formStaticAnalyst.append("attackedDevice[deviceType]", payload.extra?.attackedDevice?.deviceType);

  if(payload.extra?.attackedDevice?.value)
    formStaticAnalyst.append("attackedDevice[value]", payload.extra?.attackedDevice?.value);

  if(payload.extra?.affectedDevice)
    formStaticAnalyst.append("affectedDevice", String(payload.extra?.affectedDevice));

  if(payload.extra?.dataEstimation)
    formStaticAnalyst.append("dataEstimation", String(payload.extra?.dataEstimation));

  const result = await requestor
    .post<TaskResponse>(`task/sample-process`, formStaticAnalyst, {headers : {'Content-Type': 'multipart/form-data'}});
  return result.data;
}



type AnalistPaymentPayload = {
  id: string;
  analystPaymentMethod: string;
  analystProof: File;
  // currency: "IDR" | "USD";
}
export async function taskAnalystPaymentService(payload: AnalistPaymentPayload){
  const form = new FormData();
  form.append("analystPaymentMethod", payload.analystPaymentMethod);
  form.append("analystProof", payload.analystProof);
  const result = (await requestor.post<TaskResponse>(`task/${payload.id}/analyst-payment`, form));

  return result.data;
}

type AnalistPreviewPaymentPayload = {
  id: string;
  analystPreviewPaymentMethod: string;
  analystPreviewProof: File;
  // currency: "IDR" | "USD";
}
export async function taskAnalystPreviewPaymentService(payload: AnalistPreviewPaymentPayload){
  const form = new FormData();
  form.append("analystPreviewPaymentMethod", payload.analystPreviewPaymentMethod);
  form.append("analystPreviewProof", payload.analystPreviewProof);
  const result = (await requestor.post<TaskResponse>(`task/${payload.id}/analyst-preview-payment`, form));

  return result.data;
}


export async function taskGetAnalystPreview(taskId: string){
  const result = await requestor.get("task/" + taskId + "/analyst-preview", {responseType: "arraybuffer"});
  const data = await result.data;
  return `data:${result.headers["content-type"]};base64,${new Buffer(data, "binary").toString("base64")}`;
}
interface TaskDecryptPaymentPayload {
  id: string;
  decryptPaymentMethod : string;
  decryptProof : File;
  // decryptCurrency: "IDR" | "USD";
}
export async function taskDecryptPaymentService(payload: TaskDecryptPaymentPayload){
  const form = new FormData();
  form.append("decryptPaymentMethod", payload.decryptPaymentMethod);
  form.append("decryptProof", payload.decryptProof);
  const result = (await requestor.post<TaskResponse>(`task/${payload.id}/decrypt-payment`, form));
  return result.data;
}


export interface GetTaskListByUserPayload {
  userId : string;
}
export async function getTaskListByClient(){
  const result = await requestor.get<TaskListResponse>("task/me?orderBy=updatedAt&order=desc");
  return result.data;
}

interface GetTaskByIdPayload {
  id: string;
}
export async function getTaskByIdService(payload: GetTaskByIdPayload){
  const result = await requestor.get<TaskResponse>("task/" + payload.id);
  return result.data;
}

interface GetTaskOneByClientPayload {
  userId: string;
  sortBy: "createdAt" | "updatedAt"
}
export async function getTaskOneByClientService(payload: GetTaskOneByClientPayload){
  const result = await requestor.get<TaskResponse>("task/me/one?orderBy=" + payload.sortBy + "&order=desc");

  return result.data;
}