import { createAsyncThunk } from "@reduxjs/toolkit";
import { forgotPasswordService} from "@services/www.fixransomware.com";
import { AxiosError } from "axios";





export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (payload: {email: string} )=> {
    try {
      const result = await forgotPasswordService(payload);
      return result;
    } catch (error) {
      console.log(error);
      if(error instanceof AxiosError){
        // handle axios error here
        throw error.response?.data;
      }
      throw error;
    }
  }
)