import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";



type GoToRegisterLinkProps = {
  className : string;
}

export function GoToRegisterLink(props: GoToRegisterLinkProps){
  const { t } = useTranslation();

  return (
    <p className={clsx(props.className)} >
      {t("features.auth.register.navigation.notice")}
      <Link to={"/register"} className="font-semibold ml-1 hover:underline" >
        {t("features.auth.register.navigation.link_name")}
      </Link>
    </p>
  )
}