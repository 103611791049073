import { Task } from "@entities/task";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProcessNoteAndSample, ProcessNoteAndSampleNew } from "./type";
import { TaskStaticAndHackerNoteCreatePayload, taskSampleAndHackerNoteService, taskSampleAndHackerNoteServiceCreateNew } from "@services/www.fixransomware.com";
import { isAxiosError } from "axios";
import { axiosHaveResponseError, extractFixransomwareDataFromAxiosError, generateGenericErrorAxios, handleAxiosError, isFixransomwareData } from "@shared/utils";
import { User } from "@entities/user";



export const processNoteAndSample = createAsyncThunk(
  "currentTask/processNoteAndSample",
  async (payload: ProcessNoteAndSample, {getState, rejectWithValue} ) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const {currentTask} = getState() as {currentTask :{task: Task}};

      if(!currentTask.task.id) throw new Error("task belum terinisialisasi");
  
      const formStaticAnalyst = {
        id: currentTask.task.id,
        sampleFile : payload.sampleFile,
        hackerNote: payload.hackerNote,
        extra: payload.extra
      }
      console.log(payload.extra)
      const result = await taskSampleAndHackerNoteService(formStaticAnalyst);
      
      return result.data;
    } catch (error) {


      if(isAxiosError(error)){

        // check if there are an response keys
        const response = axiosHaveResponseError(error);

        // throw generic error
        if(!response) throw generateGenericErrorAxios(error); 

        // check if the response is fixransomware data
        if(!isFixransomwareData(response.data)) throw generateGenericErrorAxios(error); 

        // handle fixransomware response error here
        return rejectWithValue(extractFixransomwareDataFromAxiosError(error));
      }

      throw error;
    }
  }
)

export const processNoteAndSampleNew = createAsyncThunk(
  "currentTask/processNoteAndSampleNew",
  async (payload: ProcessNoteAndSampleNew, {getState, rejectWithValue} ) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const {currentUser} = getState() as {currentUser: {currentUser : User}};
  
      const formStaticAnalyst : TaskStaticAndHackerNoteCreatePayload = {
        user_id: currentUser.currentUser.id,
        virus: payload.virus,
        sampleFile : payload.sampleFile,
        hackerNote: payload.hackerNote,
        extra: payload.extra
      }
      console.log(payload.extra)
      const result = await taskSampleAndHackerNoteServiceCreateNew(formStaticAnalyst);
      
      return result.data;
    } catch (error) {


      if(isAxiosError(error)){

        // check if there are an response keys
        const response = axiosHaveResponseError(error);

        // throw generic error
        if(!response) throw generateGenericErrorAxios(error); 

        // check if the response is fixransomware data
        if(!isFixransomwareData(response.data)) throw generateGenericErrorAxios(error); 

        // handle fixransomware response error here
        return rejectWithValue(extractFixransomwareDataFromAxiosError(error));
      }

      throw error;
    }
  }
)