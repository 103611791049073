import { ComponentPropsWithoutRef, ReactNode, useState } from "react";
import QuestionmarkTooltipIcon from "@assets/icon/question-circle.svg?react"
import clsx from "clsx";


interface QuestionmarkTooltipProps extends ComponentPropsWithoutRef<"div">{
  children: ReactNode;
}

export function QuestionmarkTooltip({children, className} : QuestionmarkTooltipProps){
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div 
      className={clsx(className, "relative")}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <QuestionmarkTooltipIcon 
        className="hover:text-primary" 
        width={20} 
        height={20} 
      />

      <div style={{
        display: (isVisible ? "block" : "none"), 
        position: "absolute", 
        top: "100%", 
        left: "-100%", 
        zIndex: 10
        }}
      >
        <div className="bg-secondary-dark/70 backdrop-blur-md p-2 rounded">
          {children}
        </div>
      </div>
    </div>
  )
}