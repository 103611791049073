import { ScanSlice } from "@entities/scan";
import { currentTaskSlice } from "@entities/task";
import { currentUserSlice } from "@entities/user/model/slice";
import { currentVirusSlice } from "@entities/virus";
import { configureStore } from "@reduxjs/toolkit";


const store = configureStore({
  reducer: {
    currentVirus: currentVirusSlice.reducer,
    currentTask : currentTaskSlice.reducer,
    currentUser: currentUserSlice.reducer,
    scan: ScanSlice.reducer
  }
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;