import Lottie from "lottie-react";
import jsonEmail from "@assets/animation/email.json";
import { Link } from "react-router-dom";
import { Timer } from "@shared/components/timer";
import { useState } from "react";
import { getStoredClient } from "@services/sessionStorage";
import { resendVerificationService } from "@services/www.fixransomware.com";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


function generate5SecondWait(): Date{
  return new Date((new Date()).getTime() + 1 * 1000 * 6 )
}

export function SuccessCreateAccountPage(){
  const {t} = useTranslation();
  const [wait, setWait] = useState<Date>(generate5SecondWait());

  async function ResendVerification(){
    try {
      if(wait > new Date()) throw new Error(t("pages.success_create_account.resend_verification.wait"));
      const value = getStoredClient();
      if(!value) throw new Error(t("pages.success_create_account.resend_verification.no_client_found"));
      resendVerificationService(value);
      toast.success(t("pages.success_create_account.resend_verification.success_send"));
      setWait(generate5SecondWait());
    } catch (error) {
      
      toast.error((error as Error).message);
    }
  }


  return (
    <div className="bg-secondary-dark h-screen items-center justify-center flex">
      <div className="">
        <Lottie
          style={{ height: 270 }}
          animationData={jsonEmail}
          loop
        />


        <h3 className="text-center text-2xl text-white">
          {t("pages.success_create_account.header")}
        </h3>
        <p className="text-white text-center mb-3">
          {t("pages.success_create_account.section1")}
          <br />
          {t("pages.success_create_account.section2")}
          <br />
          {t("pages.success_create_account.section3")}
        </p>

        <div className="">
          {/* satu menit */}
          <Timer deadline={wait} />
        </div>

        <div className="flex justify-center items-center gap-3 mt-3">
          <Link to={"/login"} className="bg-warning text-gray-900 py-1 px-5 rounded">
          {t("pages.success_create_account.login")}
          </Link>
          <button onClick={ResendVerification} className="bg-warning text-gray-900 py-1 px-5 rounded">
            {t("pages.success_create_account.resend_verification_button")}
          </button>
        </div>
      </div>
    </div>
  )
}