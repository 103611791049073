import { ElectronProcessService, electronRequestor } from ".";

const scanElectronService = new ElectronProcessService("scanAction",electronRequestor);

const scan = scanElectronService.getInvokeService("scanStart");
export async function scanService(directory: string, extension: string){
  if(!scan){
    console.error("file tidak ditemukan");
    throw new Error("scan function tidak tersedia");
  }

  const result = await scan(directory, extension);

  if(!result){
    console.error("terdapat masalah dalam melakukan scanning");
    return result;
  }

  return result;
}



const onScanFoundFile = scanElectronService.getOnService("scanFoundPath");
export function onScanFoundFileService(callback: (args: string) => void){

  if(!onScanFoundFile) {
    console.log("tidak berjalan di electron");
    return;
  }
  if(!callback) return;
  onScanFoundFile(callback);

}