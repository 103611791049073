import { Task } from "@entities/task";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AnalystPaymentArgs } from "./types";
import { taskAnalystPaymentService } from "@services/www.fixransomware.com";
import { isAxiosError } from "axios";
import { axiosHaveResponseError, extractFixransomwareDataFromAxiosError, generateGenericErrorAxios, isFixransomwareData } from "@shared/utils";
import { ErrorValidation } from "@features/auth/login-by-email/model/types";
import { captureException } from "@sentry/react";


export const paymentAnalystTask = createAsyncThunk(
  "currentTask/PaymentAnalystTask",
  async (payload : AnalystPaymentArgs, {getState, rejectWithValue}) => {
    try {
      const {currentTask} = getState() as {currentTask : {task: Task}};
  
      if(!currentTask.task.id) return;
      const result = await taskAnalystPaymentService({
        analystPaymentMethod: payload.analystPaymentMethod,
        id: currentTask.task.id,
        analystProof: payload.analystProof
      })
  
      return result.data;
    } catch (error) {
      captureException(error);

      // check if ther error is axios error
      if(isAxiosError(error)){

        // check if there are an response keys
        const response = axiosHaveResponseError<ErrorValidation>(error);

        // throw generic error
        if(!response){ 
          throw generateGenericErrorAxios(error);
        }

        // check if the response is fixransomware data
        if(!isFixransomwareData(response.data)) throw generateGenericErrorAxios(error); 

        // handle fixransomware response error here
        return rejectWithValue(extractFixransomwareDataFromAxiosError(error));
      }
      throw error;
    }
  }
)