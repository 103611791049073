import { createAsyncThunk } from "@reduxjs/toolkit";
import { virusGetAllRecentService, virusGetAllService } from "@services/www.fixransomware.com";






export const getAllVirus = createAsyncThunk(
  "virus-api/getAllVirus",
  async () => {
    const result = await virusGetAllService(); 
    return result.data;
  }
)