import {
  type PayloadAction,
  createSlice,
} from '@reduxjs/toolkit'
import { FulfilledAction, PendingAction, RejectedAction, Virus } from '.'




type CurrentVirusSliceState = {
  loading: boolean;
  error: any;
  virus : Virus
};

const initialState : CurrentVirusSliceState = {
  loading: false,
  error: "",
  virus: {
    id: "",
    urlBlog: "",
    virusDesc: "",
    virusName : ""
  }
}


// const dummyVirus : Virus = {
//   id: "howessss",
//   urlBlog: "https://github.com/zidan-p",
//   virusName: "holobis",
//   virusDesc: "ini adladh virus yang berbahaya, intinya jauh-jauh dari sumber dari virus serta kunjungi dokter bila nampak gejalanya"
// }

// const dummyVirusOnlyName = {
//   virusname : "virusHowes"
// }

// export const checkOrCreateVirusByName = createAsyncThunk(
//   "currentVirus/checkOrCreateVirusByName",
//   (virusName : string, ) => {
//     return new Promise<Virus>((resolve, reject) => {
//       setTimeout(() => { resolve(dummyVirus) }, 1000)
//     })
//   }
// )

export const currentVirusSlice = createSlice({
  name: "currentVirus",
  initialState,
  reducers: {
    updateOneProps: (state, action : PayloadAction<{props: keyof CurrentVirusSliceState, value: string}>) => {
      state[action.payload.props] = action.payload.value;
    },
    updateName: (state, action : PayloadAction<string>) => {
      state.virus.virusName = action.payload;
    },
    resetVirus: () => initialState
  },

  // # TODO: refactor later, for now it's doesn't to work with so many api
  extraReducers : builder => {
    builder.addMatcher(
      (action): action is RejectedAction => action.type.endsWith("/rejected") && action.type.startsWith("currentVirus"),
      (state, action) => {
        state.loading = false;
        state.error = action.error;
      }
    );
    builder.addMatcher(
      (action): action is PendingAction => action.type.endsWith("/pending") && action.type.startsWith("currentVirus"),
      (state) => {
        state.loading = true;
      }
    )
    builder.addMatcher(
      (action): action is FulfilledAction => action.type.endsWith("/fulfilled") && action.type.startsWith("currentVirus"),
      (state, action) => {
        state.loading = false;
        state.virus = action.payload
      }
    )
  },
})

export const selectIfOnlyVirusNameThatPresent = (state: RootState) => {
  if(!state.currentVirus.virus.virusName)
    return false;
  if(state.currentVirus.virus.id)
    return false;
  
  return true;
}

export const selectCurrentVirus = (state : RootState) => {
  return state.currentVirus.virus;
}


export const {updateOneProps, updateName, resetVirus} = currentVirusSlice.actions;