import { CheckOrCreateVirusIfEmpty } from "@features/virus/check-or-create-if-empty";


interface VirusViewProps {
  moveToIndexingView: () => any;
}

export function VirusView(props: VirusViewProps){

  return <CheckOrCreateVirusIfEmpty onCheckComplete={()=>props.moveToIndexingView()} />
}