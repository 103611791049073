import clsx from "clsx";
import { ComponentPropsWithoutRef, ReactElement } from "react";

// import Eclipse from "@assets/icon/eclipse.svg?react";
import { BulletIndicator } from "@shared/components/indicator";



interface CardWithDateProps extends ComponentPropsWithoutRef<"div"> {
  date: Date;
  variant?: "warning" | "danger" | "success" | "primary" ;
  title?: string;
  children: ReactElement | string | number ;
}



export function CardWithDate({date, title = "", variant = "success", children = "", ...props} : CardWithDateProps){
  return (
    <div 
      className={clsx(
        "rounded bg-secondary-dark p-2 shadow flex flex-col",
        props.className
      )}
    >
      <div className="flex justify-between ">
        <div className="inline-flex gap-2 items-center">
          {/* <Eclipse width={15} height={15} className="text-danger" /> */}
          <BulletIndicator variant={variant} />
          <p className="font-bold">{title}</p>
        </div>
        <p className="text-gray-400">{date.toLocaleString()}</p>
      </div>
      <section className="h-full">
        {children}
      </section>
    </div>
  )
}