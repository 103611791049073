import Carousel from ".";
import { ContentCarousel, ContentCarouselData } from "./ContentCarousel";


interface SmoothCarouselProps {
  data : ContentCarouselData[]
}


export function SmoothCarousel({data} : SmoothCarouselProps){

  return(
    <Carousel
      autoPlay={true} 
      loop={true}
    >
      {data.map((item, i) => (
        <ContentCarousel data={item} key={i} />
      ))}
    </Carousel>
  )
}