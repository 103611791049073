import { SolidButton } from "@shared/components/form/button/SolidButton";
import { useState } from "react";
import { PaymentDecryptModal } from "./PaymentDecryptModal";
import { FileWithPath } from "react-dropzone";
import { PaymentDecryptSelectModal } from "./PaymentDecryptSelectFileModal";
import { useAppDispatch, useAppSelector } from "@shared/model/hooks";
import { toast } from "react-toastify";
import { paymetDecryptTask } from "../model";
import { PaymentDecryptTransferData } from "../model";
import { selectTask } from "@entities/task";
import { useTranslation } from "react-i18next";



export function PaymentDecryptButton(){
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [selectedProofFile, setSelectedProofFile] = useState<FileWithPath[]>([]);
  const [transferData, setTransferData] = useState<PaymentDecryptTransferData>({
    paymentMethod: "BNI",
    // paymentCurrency: "IDR"
  });

  const { t } = useTranslation();

  const task = useAppSelector(selectTask);

  const dispatch = useAppDispatch();

  // tempat dispatch serta hit API
  // jangan lupa transfer data juga di gunakan disini
  async function handleSendProof(){
    console.log("seharusny sudah di dispatch")
    try {
      if(selectedProofFile.length === 0){
        toast.warn(t("features.task.decrypt_payment.payment_decrypt_button.should_choose_file"));
        // toast.warn("harap pilih file terlebih dahulu");
        return;
      }
      setIsModalOpen(false)

      /* const result = */ await dispatch(paymetDecryptTask({
        decryptPaymentMethod: transferData.paymentMethod,
        decryptProof: selectedProofFile[0]
      })).unwrap()

    } catch (error) {
      console.log(error);
      toast.error(t("features.task.decrypt_payment.payment_decrypt_button.error_when_send_file"));
      // toast.error("Ada masalah dalam mengirim file");
    }
  }

  return(
    <>
      <SolidButton 
        className="py-2"
        variant="warning"
        onClick={() => setIsModalOpen(true)}
      >
        Bayar Sekarang
      </SolidButton>
      <PaymentDecryptModal
        task={task}
        setTransferData={setTransferData}
        isOpen={isModalOpen}
        modalAccess={setIsModalOpen}
        getFileModalOpen={() => setIsSelectModalOpen(true)}
      />
      <PaymentDecryptSelectModal 
        filesValue={selectedProofFile}
        setFilesValue={setSelectedProofFile}

        isOpen={isSelectModalOpen}
        modalAccess={setIsSelectModalOpen}
        getSendFileModalOpen={() => handleSendProof()}
      />
    </>
  )
}