import { AxiosError, AxiosResponse } from "axios";
import * as _ from "lodash-es";









/**
 * parse axios error so the redux can consume it
 */
export function handleAxiosError<T extends {data ?: any}>(error : AxiosError<T>){
  let data;
  if(error.response?.data.data){
    data = _.cloneDeep(error.response?.data.data);
  } else{
    // data = _.cloneDeep(error.response)
    data = {message : error.message}
  }
  console.log(data);
  return data;
}


/**
 * check whatever the error have response properties
 * @param error 
 * @returns 
 */
export function axiosHaveResponseError<T>(error: AxiosError<T>): false | AxiosResponse<T>{
  if(!error) return false;

  if(error.response) return error.response;

  return false;
}


export function extractAxiosResponseErrorData<T>(error: AxiosError<T>): false | AxiosResponse<T>["data"]{
  if(!error) return false;

  if(error.response) return error.response.data;

  return false;
}


export function generateGenericErrorAxios(error: AxiosError) {
  return {
    message : error.message,
    statusCode : error.code,
    error: error.name
  }
}