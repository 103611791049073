import { motion } from "framer-motion";
import { ReactNode } from "react";





interface ProcessWrapperProps {
  children: ReactNode;
}

const processvariant = {
  initial:{
    opacity: 0
  },
  animate:{
    opacity: 1,
    transition: {
      delay: 0.5
    }
  },
  exit:{
    opacity: 0
  }
}

export function ProcessWrapper(props: ProcessWrapperProps){
  return(
    <motion.div 
      variants={processvariant}
      initial="initial"
      animate="animate"
      exit="exit"
      className="h-full"
    >
      {props.children}
    </motion.div>
  )
}