import { DialogWithHeader } from "@shared/components/dialog";
import { TextInputDark } from "@shared/components/form";
import { Virus, updateName } from "@entities/virus";
import { useEffect, useMemo, useState } from "react";
import { filterVirus } from "../../utils/virusUtil";
import { VirusSection } from "./VirusSection";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@shared/model/hooks";
import { toast } from "react-toastify";
import extensionGif from "@assets/img/exampleFile.mp4";
import { getAllVirus } from "../../model";
import { useTranslation } from "react-i18next";

interface SelectVirusModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (virusName: string) => any;
}

export function SelectVirusModal(props: SelectVirusModalProps){
  const [virusInput, setVirusInput] = useState<string>("");
  const [selectedVirus, setSelectedVirus] = useState<string | null>(null); // virus id
  const [dataVirus, setDataVirus] = useState<Virus[]>([]);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const filteredVirus = useMemo(() => filterVirus(virusInput, dataVirus), [virusInput, dataVirus]);

  useEffect(()=>{ fetchVirusList(); },[])

  async function fetchVirusList(){
    try {
      const result = await dispatch(getAllVirus()).unwrap();
      setDataVirus(result);
    } catch (error) {
      console.log(error);
      toast.error(t("features.virus.select_virus.message.failed"));
    }
  }

  function onChangeInput(e: React.ChangeEvent<HTMLInputElement>){
    setSelectedVirus(null);
    setVirusInput(e.target.value);
  }

  // when virus button is clicked, the input will automatically have that virus name value
  function selectVirus(id: string){
    setSelectedVirus(id);
    setVirusInput(filteredVirus.find(vir => vir.id === id)?.virusName ?? "");
  }

  function handleSubmit (){
    const virusName = virusInput;
    if(!virusName) {
      toast.error("nama virus belum diinputkan")
      return;
    }

    props.onSubmit(virusName);
  }

  return (

    <DialogWithHeader
      isOpen={props.isOpen}
      modalAccess={props.setIsOpen}
      title="Pilih Varian Virus"
      className="bg-secondary-base basis-1/3"
    >
      <div className="px-10 py-4">
        {/* <img src={imageSelect} alt="" className="mx-auto" /> */}
        <img src={extensionGif} alt="" className="mx-auto mb-5" />
        <p className="text-center mb-6">
          {t("features.virus.select_virus.modal_select_virus.select_message")}
        </p>
        {/* # TODO: i dont know why, but this input label wont focus to input when clicked.*/}
        {/* in another input component it doesn't have this behaviour.*/}
        {/* altough, this component just copy paste code from that component that work */}
        <TextInputDark 
          onChange={onChangeInput} 
          value={virusInput}
          className="bg-black mb-5" 
          placeholder={t("features.virus.select_virus.modal_select_virus.input_virus_placeholder")} 
          name="dummySelect"  
        />
        <VirusSection 
          selectedVirus={selectedVirus}
          virusList={filteredVirus}
          setSelectedVirus={selectVirus}
          className="mb-10"
        />
        <div className="flex justify-end">
          <SolidButton onClick={handleSubmit} variant="success" className="">
            {t("features.virus.select_virus.modal_select_virus.submit_button")}
          </SolidButton>
        </div>
      </div>
    </DialogWithHeader>
  )
}