import { ReactElement, useState } from "react"
import { TabProps } from "./Tab"
import clsx from "clsx";




export interface TabsProps {
  children: ReactElement<TabProps>[]
}

export function Tabs(props: TabsProps){
  const [activetab, setActivetab] = useState<number>(0);

  function handleOnClickTab(index: number){
    setActivetab(index);
  }

  return (
    <div className="">
      <ul className="inline-flex gap-2 p-1 rounded bg-secondary-dark/40 mb-2">
        {props.children.map((tab, index)=> (
          <button
            key={index}
            className={clsx(
              "px-3 py-0.5 rounded",
              activetab === index && "bg-secondary-base"
            )}
            onClick={() => handleOnClickTab(index)}
          >
            {tab.props.label}
          </button>
        ))}
      </ul>
      <div className="">
          {props.children[activetab]}
      </div>
    </div>
  )
}