import { isKeysPresent } from "@shared/utils";
import { Task } from ".";

export const requiredKeysIndexing = [
  "id", 
  "virusVarian"
] as const;

export const requiredKeysSampleAndHacker = [
  ...requiredKeysIndexing, 
  "hackerNote", 
  "sampleFile"
] as const;

export const requiredKeysAnalystPaymentInit = [
  ...requiredKeysSampleAndHacker, 
  "analystNominal", 
  "analystDiscount",
  "analystPaymentDeadline"
] as const;

export const requiredKeysAnalystPaymentAction = [
  ...requiredKeysAnalystPaymentInit,
  "analystPaymentMethod",
  "analystProof",
  "analystStatus"
] as const;


export const requiredKeysAnalystPaymentConfirm = [
  ...requiredKeysAnalystPaymentAction
] as const;

export const requiredKeysAnalystResult = [ 
  ...requiredKeysAnalystPaymentConfirm,
  "downloadAnalysis"
] as const;


export const requiredKeysDecryptPaymentInit = [
  ...requiredKeysAnalystResult,
  "decryptNominal",
  "decryptDiscount",
  "decryptPaymentDeadline"
] as const;

export const requiredKeysDecryptPaymentAction = [
  ...requiredKeysDecryptPaymentInit,
  "decryptPaymentMethod",
  "decryptProof",
  "decryptPaymentStatus"
]

export const requiredKeysDecryptPaymentConfirm = [
  ...requiredKeysDecryptPaymentAction
] as const;


export const requiredKeysDecryptEstimation = [
  ...requiredKeysDecryptPaymentConfirm,
  // "estimatedDecrypt"
] as const;


export const requiredKeysDecryptResult = [
  ...requiredKeysDecryptEstimation,
  "decryptFile"
]


type StageObject = {
  name: string;
  description: string, 
  requiredKeys: readonly string[],
  selector : (task : Task) => any
}

/**
 * this task stage already ordered in process order
 */
export const TASK_STAGE = {
  empty: {
    name: "EMPTY",
    description: "Empty",
    requiredKeys : [] as readonly string[],
    selector: (task) => {
      return Object.keys(task).length === 0
    }
  },
  indexing: {
    name: "INDEXING",
    description : "indexing file",
    requiredKeys: requiredKeysIndexing,
    selector : task => {
      return isKeysPresent(requiredKeysIndexing, task);
    }
  },
  sampleAndHacker: {
    name: "UPLOADED_HACKER_NOTE_AND_SAMPLE_FILE",
    description: "Infected Sample and Hacker Note",
    requiredKeys: requiredKeysSampleAndHacker,
    selector: task => {

      const requiredKeyPresent = isKeysPresent(requiredKeysSampleAndHacker, task);

      const hackerNoteAndSamplePresent = (Boolean(task.sampleFile?.length));
      return hackerNoteAndSamplePresent && requiredKeyPresent;
    }
  },
  analystPaymentInit : {
    name: "ANALYST_PAYMENT_INIT",
    description : "Initial Payment Analyst",
    requiredKeys: requiredKeysAnalystPaymentInit,
    selector: task => {
      return isKeysPresent(requiredKeysAnalystPaymentInit, task);
    }
  },
  analystPaymetAction : {
    name: "ANALSYT_PAYMENT_ACTION",
    description : "Pay Analyst Payment",
    requiredKeys: requiredKeysAnalystPaymentAction,
    selector: task => {
      return isKeysPresent(requiredKeysAnalystPaymentAction, task);
    }
  },
  analystPaymentConfirm : {
    name: "ANALYST_PAYMENT_CONFIRM",
    description : "Conformed Analsyt Payment",
    requiredKeys: requiredKeysAnalystPaymentConfirm,
    selector: task => {
      const keyPresent = isKeysPresent(requiredKeysAnalystPaymentConfirm, task);

      return (keyPresent && task.analystStatus === "Success");
    }
  },
  analystResult : {
    name: "ANALYST_RESULT",
    description : "Result of Analyst",
    requiredKeys: requiredKeysAnalystResult,
    selector: task => {
      const keyPresent = isKeysPresent(requiredKeysAnalystResult, task);

      return (keyPresent && task.analystStatus === "Success");
    }
  },
  decryptPaymentInit : {
    name: "DECRYPT_PAYMENT_INIT",
    description : "Initial Decrypt Payment",
    requiredKeys: requiredKeysDecryptPaymentInit,
    selector: task => {
      return isKeysPresent(requiredKeysDecryptPaymentInit, task);
    }
  },
  decryptPaymentAction : {
    name: "DECRYPT_PAYMENT_ACTION",
    description : "Pay Decrypt Payment",
    requiredKeys: requiredKeysDecryptPaymentAction,
    selector: task => {
      return isKeysPresent(requiredKeysDecryptPaymentAction, task);
    }
  },
  decryptPaymentConfirm : {
    name: "DECRYPT_PAYMENT_CONFIRM",
    description : "Confirmed Decrypt Payment",
    requiredKeys: requiredKeysDecryptPaymentConfirm,
    selector: task => {
      const keyPresent = isKeysPresent(requiredKeysDecryptPaymentConfirm, task);

      return (keyPresent && task.decryptPaymentStatus === "Success");
    }
  },
  decryptEstimation : {
    name: "DECRYPT_ESTIMATION",
    description : "Estimation of Decrypt Process",
    requiredKeys: requiredKeysDecryptEstimation,
    selector: task => {
      const keyPresent = isKeysPresent(requiredKeysDecryptEstimation, task);

      if(!(task.estimatedDecrypt || task.estimatedDecryptFinish)) {
        return false;
      }
      return (keyPresent && task.decryptPaymentStatus === "Success");
    }
  },
  decryptResult : {
    name: "DECRYPT_RESULT",
    description : "Result of Decrypttion",
    requiredKeys: requiredKeysDecryptResult,
    selector: task => {
      return isKeysPresent(requiredKeysDecryptResult, task);
    }
  }
} as const satisfies Record<string, StageObject>;
