import { Virus } from "@entities/virus";




/**
 * 
 * @param inputString string for virus name
 * @param virus available virus list
 * @returns filtered result
 */
export function filterVirus(inputString: string, virus: Virus[]){
  return virus
    .map(vir =>{  
      return {virusName: vir.virusName.toLowerCase(), id : vir.id}
    })
    .filter(virusName => virusName.virusName.includes(inputString))
    .slice(0, 5); // only get first 5 elements
}



export function selectVirusById(idString: string, virus: Virus[]){
  const result = virus.find(vir => vir.id === idString);
  if(!result) return null;

  return result;
}