import { 
  Task, 
  dummyAnalystPaymentAction, 
  dummyAnalystPaymentConfirm, 
  dummyAnalystPaymentInit, 
  dummyAnalystResult, 
  dummyDecrypFinish, 
  dummyDecryptEstimation, 
  dummyDecryptPaymentAction, 
  dummyDecryptPaymentConfirm, 
  dummyDecryptPaymentInit, 
  dummyIndexingTask, 
  dummySampleAndHackerTask 
} from "@entities/task";
import { createAsyncThunk } from "@reduxjs/toolkit";



export const emptyAction = createAsyncThunk(
  "currentTask/empty",
  () => {
    return new Promise<Task>((resolve)=>{
      setTimeout(()=>resolve({}), 2000)
    })
  }
)

export const dummyIndexingTaskAction = createAsyncThunk(
  "currentTask/dummyIndexingTask",
  () => {
    console.log("seharusnya beruabh ke indexing")
    return new Promise<Task>((resolve)=>{
      setTimeout(()=>resolve(dummyIndexingTask), 2000)
    })
  }
)


export const dummySampleAndHackerAction = createAsyncThunk(
  "currentTask/dummySampleAndHacker",
  () => {
    return new Promise<Task>((resolve)=>{
      setTimeout(()=>resolve(dummySampleAndHackerTask), 2000)
    })
  }
)


export const dummyAnalystPaymentInitAction = createAsyncThunk(
  "currentTask/dummyAnalystPaymentInit",
  () => {
    return new Promise<Task>((resolve)=>{
      setTimeout(()=>resolve(dummyAnalystPaymentInit), 2000)
    })
  }
)


export const dummyAnalystPaymentActionAction = createAsyncThunk(
  "currentTask/dummyAnalystPaymentAction",
  () => {
    return new Promise<Task>((resolve)=>{
      setTimeout(()=>resolve(dummyAnalystPaymentAction), 2000)
    })
  }
)


export const dummyAnalystPaymentConfirmAction = createAsyncThunk(
  "currentTask/dummyAnalystPaymentConfirm",
  () => {
    return new Promise<Task>((resolve)=>{
      setTimeout(()=>resolve(dummyAnalystPaymentConfirm), 2000)
    })
  }
)

export const dummyAnalystResultAction = createAsyncThunk(
  "currentTask/dummyAnalystPaymentResult",
  () => {
    return new Promise<Task>((resolve)=>{
      setTimeout(()=>resolve(dummyAnalystResult), 2000)
    })
  }
)


export const dummyDecryptPaymentInitAction = createAsyncThunk(
  "currentTask/dummyDecryptPaymentInit",
  () => {
    return new Promise<Task>((resolve)=>{
      setTimeout(()=>resolve(dummyDecryptPaymentInit), 2000)
    })
  }
)


export const dummyDecryptPaymentActionAction = createAsyncThunk(
  "currentTask/dummyDecryptPaymentAction",
  () => {
    return new Promise<Task>((resolve)=>{
      setTimeout(()=>resolve(dummyDecryptPaymentAction), 2000)
    })
  }
)


export const dummyDecryptPaymentConfirmAction = createAsyncThunk(
  "currentTask/dummyDecryptPaymentConfirm",
  () => {
    return new Promise<Task>((resolve)=>{
      setTimeout(()=>resolve(dummyDecryptPaymentConfirm), 2000)
    })
  }
)


export const dummyDecryptEstimationAction = createAsyncThunk(
  "currentTask/dummyDecryptEstimation",
  () => {
    return new Promise<Task>((resolve)=>{
      setTimeout(()=>resolve(dummyDecryptEstimation), 2000)
    })
  }
)


export const dummyDecrypFinishAction = createAsyncThunk(
  "currentTask/dummyDecrypFinish",
  () => {
    return new Promise<Task>((resolve)=>{
      setTimeout(()=>resolve(dummyDecrypFinish), 2000)
    })
  }
)

export const DUMMY_ACTION_LIST = {
  emptyAction,
  dummyIndexingTaskAction, 
  dummySampleAndHackerAction,
  dummyAnalystPaymentInitAction, 
  dummyAnalystPaymentActionAction, 
  dummyAnalystPaymentConfirmAction, 
  dummyAnalystResultAction, 
  dummyDecryptPaymentInitAction, 
  dummyDecryptPaymentActionAction, 
  dummyDecryptPaymentConfirmAction, 
  dummyDecryptEstimationAction, 
  dummyDecrypFinishAction, 
} as const
