import { Task } from "@entities/task";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { DecryptPaymentArgs } from ".";
import { taskDecryptPaymentService } from "@services/www.fixransomware.com";






export const paymetDecryptTask = createAsyncThunk(
  "currentTask/paymentDecryptTask",
  async (payload: DecryptPaymentArgs, {getState})=>{
    const {currentTask} = getState() as {currentTask : {task: Task}};

    if(!currentTask.task.id) return;
    const result = await taskDecryptPaymentService({
      id: currentTask.task.id,
      // decryptCurrency: payload.decryptCurrency,
      decryptPaymentMethod: payload.decryptPaymentMethod,
      decryptProof: payload.decryptProof
    })

    return result.data;
  }
)