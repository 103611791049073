import Eclipse from "@assets/icon/eclipse.svg?react";
import clsx from "clsx";


interface BulletIndicatorProps{
  variant?: "warning" | "danger" | "success" | "primary" | "custom" | "base";
  className?: string;
}



export function BulletIndicator({variant = "base", className = ""} : BulletIndicatorProps){
  return (
    <Eclipse 
      width={15} 
      height={15} 
      className={clsx(
        className,
        variant === "warning" && "text-warning",
        variant === "danger" && "text-danger",
        variant === "success" && "text-success-dark fill-white",
        variant === "primary" && "text-primary",
        variant === "custom" && "", // let classname handle
        variant === "base" && "text-gray-600"
      )} 
    />
  )
}