import { MakeDonationWrapper } from "@features/donation/make-donation";
import { DialogWithHeader } from "@shared/components/dialog";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { CompanyPhotoCarousel } from "@widgets/company-photo-carousel";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DonationBenefitModal } from "./DonationBenefitModal";



export function Donation(){
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {t} = useTranslation();

  return (
    <div className="p-7 flex h-full items-center">
      <div className="basis-1/2 xl:px-20">

        {/* title and description */}
        <div className="mb-10">
          <h1 className="text-2xl mb-2 font-bold">
            {t("pages.donation.main_title")}
          </h1>
          <p className="mb-2">
            {t("pages.donation.p_section_1")}
          </p>
          <p className="mb-2">
            {t("pages.donation.p_section_2")}
          </p>
        </div>

        <div className="flex gap-4">

          {/* donate button, it will show upload file input */}
          <MakeDonationWrapper>
            <SolidButton variant="warning">
              {t("pages.donation.donate_button")}
            </SolidButton>
          </MakeDonationWrapper>

          {/* show donation benefit */}
          <SolidButton
            variant="success"
            onClick={()=> setIsModalOpen(true)}
          >
            {t("pages.donation.open_modal_button")}
          </SolidButton>
        </div>
      </div>
      <div className="basis-1/2 h-full">
        <CompanyPhotoCarousel />
      </div>
      <DonationBenefitModal isOpen={isModalOpen} modalAccess={setIsModalOpen} />
    </div>
  )
}