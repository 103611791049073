import { UploadIndexFile } from "@features/virus/select-virus";
import { SelectNoteAndSample } from "@features/task/select-note-and-sample";
import { useAppSelector } from "@shared/model/hooks";
import { selectCurrentStage } from "@entities/task";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";






export function FreshCreateTask(){

  const activeStage = useAppSelector(selectCurrentStage);
  const navigate = useNavigate();
  const {t} = useTranslation();

  useEffect(()=>{
    if(!["INDEXING", "EMPTY"].includes(activeStage)) {
      navigate("/scan");
    }
  },[activeStage])

  return (
  <div className="flex justify-center gap-9 xl:gap-28 items-center h-full xl:h-[80%]">
    <div className="flex flex-col">
      <UploadIndexFile isDisable={activeStage !== "EMPTY"}  />
    </div>
    <div className="">
      <div className="mb-9">
        <h1 className="text-4xl font-bold">Decrypt</h1>
        <p className="text-gray-500">
          {t("widgets.fresh_create_task.instruction")}
        </p>
      </div>
      <SelectNoteAndSample />
    </div>
  </div>
  )
}