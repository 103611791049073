
import Lottie from "lottie-react";
import congrats from "@assets/animation/congrate.json";
import { Task } from "@entities/task";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";


interface AnalystResultProps {
  task : Task;
  analystPreviewSlot: ReactNode;
}

export function AnalystResult(props: AnalystResultProps){

  const { t } = useTranslation();

  return(
    <div className="">
      <Lottie style={{ height: 90 }} animationData={congrats} loop/>
      <p>
        “{t("entities.task.stage.analyst.analyst_result.message")}”
      </p>
      <div className="bg-secondary-base rounded-lg p-3 mt-5 flex justify-between items-center">
        <h3 className="text-white font-semibold">Analisis Result</h3>
        <a
          className="bg-primary hover:bg-primary/80 active:bg-primary/70 py-3 px-12 rounded transition" 
          href={props.task.downloadAnalysis}
          target="_blank" 
          rel="noopener noreferrer"
        >
          {t("entities.task.stage.analyst.analyst_result.button_notice")}
        </a>
      </div>
      {props.analystPreviewSlot}
    </div>
  )
}