import { ReactNode, useEffect, useState } from "react";
import { MakeDonationModal } from "./MakeDonationModal";
import { FileWithPath } from "react-dropzone";
import { MakeDonationSelectModal } from "./MakeDonationSelectFileModal";
import { useAppDispatch } from "@shared/model/hooks";
import { makeDonation } from "../model/action";
import { captureException } from "@sentry/react";
import { toast } from "react-toastify";



interface MakeDonationModalProps {
  children: ReactNode;
}

export type TranferDataType = {
  paymentMethod : "BNI" | "QRIS";
  nominal : string;
} 

export function MakeDonationWrapper({children} : MakeDonationModalProps){
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProofFile, setSelectedProofFile] = useState<FileWithPath[]>([]);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [transferData, setTransferData] = useState<TranferDataType>({
    paymentMethod: "BNI",
    nominal: "0"
  });

  const dispatch = useAppDispatch();

  // disini tempat API dengan server
  async function handleSendProof(){
    try {

      if(!selectedProofFile[0]) {
        toast.error("Please provide the payment proof");
        return;
      }
      // return;
      await dispatch(makeDonation({
        donationProof: selectedProofFile[0],
        nominal: transferData.nominal,
        paymentMethod: transferData.paymentMethod
      })).unwrap();

      toast.success("Sukses mengirim donasi, silahkan tunggu konfirmasi dari admin");
      setIsModalOpen(false);
      setIsSelectModalOpen(false);
    } catch (error) {
      captureException(error);
      console.log(error);
      toast.error((error as any)?.message ?? "Ada masalah dengan server");
    }
  }

  // MAGIC: this useEffect will prevent bug selectedProofFile empty.
  useEffect(() => {
    console.log("selected proof file...");
    console.log(selectedProofFile);
  }, [selectedProofFile])

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>
        {children}
      </div>
      <MakeDonationModal
        getFileModalOpen={() => setIsSelectModalOpen(true)}
        setTransferData={setTransferData}
        isOpen={isModalOpen}
        modalAccess={setIsModalOpen}
      />
      <MakeDonationSelectModal 
        filesValue={selectedProofFile}
        setFilesValue={setSelectedProofFile}
        isOpen={isSelectModalOpen}
        modalAccess={setIsSelectModalOpen}

        // # when click upload file in modal
        getSendFileModalOpen={() => handleSendProof()}
      />
    </>
  )
}