import { DialogWithHeader } from "@shared/components/dialog";
import { DefaultDropzone } from "@shared/components/form";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { ComponentPropsWithoutRef } from "react";
import { FileWithPath } from "react-dropzone";
import { 
  LIMIT_HACKER_NOTE_FILE_COUNT, 
  LIMIT_HACKER_NOTE_FILE_COUNT_PREMIUM, 
  LIMIT_HACKER_NOTE_FILE_SIZE, 
  LIMIT_HACKER_NOTE_FILE_SIZE_PREMIUM, 
} from "../../constant";
import { Tab, Tabs } from "@shared/components/tab";
import { TextInputToFile } from "../components/TextInputToFile";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@shared/model/hooks";
import { isUserPremium } from "@entities/user/model/selector";



interface UploadHackerNoteFileModalProps extends Omit<ComponentPropsWithoutRef<typeof DialogWithHeader>, "title">{
  filesValue: FileWithPath[];
  setFilesValue: React.Dispatch<React.SetStateAction<FileWithPath[]>>;
}


export function UploadHackerNoteFileModal({filesValue, setFilesValue, isOpen, modalAccess, /* ...props */}: UploadHackerNoteFileModalProps){

  const {t} = useTranslation();
  const isCurrentUserPremium = useAppSelector(isUserPremium);

  return (
    <DialogWithHeader
      isOpen={isOpen}
      modalAccess={modalAccess}
      title={t("features.task.select_note_and_sample.hacker_note_modal.title")}
      className="bg-secondary-base basis-1/3 max-h-[80%] overflow-auto"
    >
      <div className="p-2">
        <Tabs>
          <Tab label="Unggah File" key={1} >
            <DefaultDropzone
              filesValue={filesValue}
              setFilesValue={setFilesValue}
              whenDragText={t( isCurrentUserPremium 
                ? "features.task.select_note_and_sample.hacker_note_modal.when_drag_text_premium" 
                : "features.task.select_note_and_sample.hacker_note_modal.when_drag_text"
              )}
              whenIdleText={t( isCurrentUserPremium 
                ? "features.task.select_note_and_sample.hacker_note_modal.when_idle_text_premium" 
                : "features.task.select_note_and_sample.hacker_note_modal.when_idle_text"
              )}
              maxCount={ isCurrentUserPremium ? LIMIT_HACKER_NOTE_FILE_COUNT_PREMIUM : LIMIT_HACKER_NOTE_FILE_COUNT }
              maxSize={ isCurrentUserPremium ? LIMIT_HACKER_NOTE_FILE_SIZE_PREMIUM : LIMIT_HACKER_NOTE_FILE_SIZE }
            />
            <SolidButton onClick={()=> modalAccess(false)} variant="success" className="mt-3 block ms-auto">
              Submit
            </SolidButton>
          </Tab>
          <Tab
            label="Berbagi ID & Pass Anydesk"
            key={2}
          >
            <TextInputToFile 
              name="hacker-note"
              placeholder={t("features.task.select_note_and_sample.hacker_note_modal.insert_url")}
              onFileGenerated={(file) => setFilesValue([file])}
              onSubmit={() => modalAccess(false)}
              value="Contoh: ID: 12312 123123. Password: Basmala1@1!. Pastikan server/PC Anda menyala hingga 1 Hari kerja kedepan"
            />
          </Tab>
        </Tabs>
      </div>
    </DialogWithHeader>
  )
}