import Lottie from "lottie-react"
import loadingHourGlass from "@assets/animation/loading-hour-glass.json"
import { useTranslation } from "react-i18next"




export function SampleAndHackerNote(){

  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col px-6 pb-6">
      <Lottie animationData={loadingHourGlass} style={{ height: 120 }} />
      <p className="grow">
        {t("entities.task.stage.sample_and_hacker_note.uploaded.notice")}
        {/* Terima kasih banyak atas kepercayaan anda dalam mengandalkan layanan kami untuk menganalisis sample file. Saat ini data anda sedang diproses pada VPS AWS yang nanti diakses oleh 17 teknisi kami secara aman.
        Proses analisis server Anda melalui VPS membutuhkan waktu hingga 7 hari kerja. Namun, kami mengutamakan urgensi data anda, sehingga kami akan berusaha semaksimal mungkin untuk mempercepat prosesnya dan melakukan proses lembur jika diperlukan. Setelahnya, kami akan segera memberikan laporan analisis yang terperinci mengenai biaya decryption, timeline, dan kesehatan file yang terinfeksi ransomware. */}
      </p>
      <p className="text-sm text-gray-400 mt-5">
        {t("entities.task.stage.sample_and_hacker_note.uploaded.note")}
        {/* Catatan: Persentase proses mungkin terlihat stuck, namun tim kami sedang berupaya keras memproses file infeksi anda. Mohon menunggu dan biarkan proses ini di background atau mematikan PC/Server Anda. Anda akan mendapatkan notifikasi via email dan Whatsapp jika telah usai. */}
      </p>
    </div>
  )
}