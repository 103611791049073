
export function calculateGrandTotal(nominal: string, discountPercentage: string){
  const nominalNumber = Number(nominal);
  const discountPercentageNumber = Number(discountPercentage);

  const reducedValue = nominalNumber * discountPercentageNumber / 100;

  const result = nominalNumber - reducedValue;

  return result;
}